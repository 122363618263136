import React from 'react'
import Box from "../../../layout/Box";
import Card from "givapp-design/src/components/Card";
import Flex from "givapp-design/src/components/Flex";
import styled from "@emotion/styled";
import Loader from "react-loader-spinner";

const Fixed = styled(Flex)`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
`


const LoadingCard = (props) => (<Fixed
  flexDirection={'column'}
  justifyContent={'center'}
  alignItems={'center'}
  alignContent={'center'}
  {...props}
  width={[1]}
>
  <Box>
    <Card>
      <Flex flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            alignContent={'center'}>
        <Loader
          type="TailSpin"
          color="#00BFFF"
          height={100}
          width={100}
          timeout={3000} //3 secs
        />
      </Flex>
    </Card>
  </Box>
</Fixed>)

export default LoadingCard
