import React from 'react'
import { useTheme } from 'emotion-theming'

export const IconWarning = ({ color, large, size }) => {
  const theme = useTheme()
  const iconColor = color || theme.color.black3

  return large ?
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="9.66667" stroke={iconColor} strokeWidth="2"/>
      <path d="M17.037 20.7408C17.037 21.0964 16.8 21.3334 16.4444 21.3334H15.2592C14.9037 21.3334 14.6667 21.0964 14.6667 20.7408V19.5556C14.6667 19.2001 14.9037 18.963 15.2592 18.963H16.4444C16.8 18.963 17.037 19.2001 17.037 19.5556V20.7408Z" fill={iconColor} />
      <path d="M17.037 15.4074L16.6815 17.3037C16.6815 17.5408 16.4444 17.7778 16.0889 17.7778H15.6148C15.2592 17.7778 15.0222 17.5408 15.0222 17.3037L14.6667 15.4074V11.2593C14.6667 10.9037 14.9037 10.6667 15.2592 10.6667H16.4444C16.8 10.6667 17.037 10.9037 17.037 11.2593V15.4074Z" fill={iconColor} />
    </svg> :
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="8.25" stroke={iconColor} strokeWidth="1.5"/>
      <path d="M12.875 15.9997C12.875 16.2997 12.675 16.4997 12.375 16.4997H11.375C11.075 16.4997 10.875 16.2997 10.875 15.9997V14.9997C10.875 14.6997 11.075 14.4997 11.375 14.4997H12.375C12.675 14.4997 12.875 14.6997 12.875 14.9997V15.9997Z" fill={iconColor} />
      <path d="M12.875 11.5L12.575 13.1C12.575 13.3 12.375 13.5 12.075 13.5H11.675C11.375 13.5 11.175 13.3 11.175 13.1L10.875 11.5V8C10.875 7.7 11.075 7.5 11.375 7.5H12.375C12.675 7.5 12.875 7.7 12.875 8V11.5Z" fill={iconColor} />
    </svg>
}

IconWarning.defaultProps = {
  size: 24
}
