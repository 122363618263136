import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import { rem } from '../../lib/tools'
import * as border from '../../tokens/border'
import * as space from '../../tokens/space'
import * as typography from '../../tokens/typography'

const StyledTd = styled.td`
  width: ${props => props.width ? rem(props.width) : 'auto'};
  padding-top: ${props =>
    props.noBorder ? 0 :
    props.small ? `calc(${space.s2} - ${rem(2)})` :
    space.s3
  };
  padding-right: ${space.s2};
  padding-bottom: ${props => props.small ? `calc(${space.s2} - ${rem(1)})` : space.s3};
  font-family: ${typography.fontFamily};
  font-size: ${props => props.meta ? typography.fontSizeMeta : typography.fontSizeTableData};
  font-style: ${props => props.center ? 'italic' : 'normal'};
  font-weight: ${props =>
    props.bold ? typography.fontWeightBold :
    props.medium ? typography.fontWeightMedium :
    typography.fontWeightRegular
  };
  line-height: ${props => props.meta ?
    typography.lineHeightMultiplierMeta :
    typography.lineHeightMultiplierTableData
  };
  text-align: ${props =>
    props.center ? 'center' :
    props.right ? 'right' :
    'left'
  };
  vertical-align: ${props =>
    props.bottom ? 'bottom' :
    props.top ? 'top' :
    'middle'
  };
  color: ${props =>
    props.textColor ? props.textColor :
    props.black ? props.theme.color.black4 :
    props.blue ? props.theme.color.classicBlue4 :
    props.gray ? props.theme.color.black1 :
    props.theme.color.black3
  };
  border-top-width: ${props => props.noBorder ? 0 : border.borderWidth};
  border-top-style: ${border.borderStyleDefault};
  border-top-color: ${props => props.soft ? props.theme.color.gray1 : props.theme.color.gray2};

  &:last-of-type {
    padding-right: 0;
  }
`

const TableData = ({
  black,
  blue,
  bold,
  bottom,
  center,
  children,
  gray,
  italic,
  medium,
  meta,
  noBorder,
  small,
  soft,
  textColor,
  top,
  width,
  ...rest
}) => (
  <StyledTd
    {...{
      black,
      blue,
      bold,
      bottom,
      center,
      children,
      gray,
      italic,
      medium,
      meta,
      noBorder,
      small,
      soft,
      textColor,
      top,
      width
    }}
    {...rest}
  >
    {children}
  </StyledTd>
)

TableData.propTypes = {
  /** Sets the text color to be darker than the normal black.
      Used for accenting important data in the table. */
  black: PropTypes.bool,
  /** Sets the text color to be blue. Also used for an accent. */
  blue: PropTypes.bool,
  /** Set the font-weight to bold. */
  bold: PropTypes.bool,
  /** Align the table data content to the bottom of the cell.
      (Default style is the middle) */
  bottom: PropTypes.bool,
  /** Center the text (or icon, etc.) in the table cell. */
  center: PropTypes.bool,
  /** Set the text color to be Light / Black 1. Used to
      deemphasize data that is not as important. */
  gray: PropTypes.bool,
  /** Style the text italic. */
  italic: PropTypes.bool,
  /** Set the font-weight to medium. */
  medium: PropTypes.bool,
  /** Shortcut keyword to match the text to Meta style */
  meta: PropTypes.bool,
  /** Remove top border. */
  noBorder: PropTypes.bool,
  /** Align text to right. */
  right: PropTypes.bool,
  /** Reduces padding to create slimmer table. This prop is
      not necessary if the `small` prop is passed to the
      `Table` component. */
  small: PropTypes.bool,
  /** Changes border color to lightGray. This prop is
      not necessary if the `small` prop is passed to the
      `Table` component. */
  soft: PropTypes.bool,
  /** Set a specific color for the text. This will override
      the text color for any of the other keywords. */
  textColor: PropTypes.string,
  /** Align the table data content to the top of the cell. */
  top: PropTypes.bool,
  /** Set a custom width. Must be a unitless pixel value,
      which is then converted to rem. Default width value
      of cell is `auto` */
  width: PropTypes.number
}

TableData.defaultProps = {
  black: false,
  blue: false,
  bold: false,
  bottom: false,
  center: false,
  gray: false,
  italic: false,
  medium: false,
  meta: false,
  noBorder: false,
  right: false,
  small: false,
  soft: false,
  top: false
}

export default TableData
