import React from 'react'
import styled from 'styled-components'

import { frameBase } from '../../lib/constants'
import { rem } from '../../lib/tools'
import { breakpoint } from '../../designSystem'

const StyledFrame = styled.div`
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  position: relative;

  @media (min-width: ${rem(breakpoint.md)}) {
    max-width: ${rem(frameBase)};
  }
`

const Frame = ({ children }) => (
  <StyledFrame>
    {children}
  </StyledFrame>
)

export default Frame
