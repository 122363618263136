import React, { cloneElement } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const StyledTable = styled.table`
  width: 100%;
  table-layout: ${props => props.fixed ? 'fixed' : 'auto'};
  /* equivalent to cellpadding="0" & cellspacing="0" */
  border-collapse: collapse;
`

const forceArray = (data) => Array.isArray(data) ? data : [data]

const Table = ({ children, fixed, small, soft }) => {
  // set up empty variables
  let newChildren, newTrs, newTds

  /*
    If small keyword is present, map through children and
    pass down small prop to TableData components if present.
  */
  if (small || soft) {
    // if single child, place in empty array
    const arrayChildren = !!children && forceArray(children)
    !!arrayChildren && arrayChildren.map(children => {
      /*
        The series of conditionals below checks to see if
        <tbody> present, then maps through <TableRow>s if present,
        then if present the <TableData>s and adds the small prop
        to the <TableData>
      */

      /*
        TODO: Maybe replace hasOwnProperty('type') &&
              type.hasOwnProperty('displayName') with
              React.isValidElement?
      */
      // td = children.props.children[trIndex].props.children[tdIndex]
      // check for tbody
      if (!!children && children.hasOwnProperty('type') && children.type === 'tbody') {
        // if children, map through them
        if (children.props.children) {
          // if just a single prop, stick it in an empty array
          const arrayTrChildren = forceArray(children.props.children)

          newTds = arrayTrChildren.map((tr, trIndex) => {
            // check to make sure they are <TableRow> components
            if (
              tr.hasOwnProperty('type') &&
              tr.type.hasOwnProperty('displayName') &&
              tr.type.displayName === 'TableRow'
            ) {
              // if just a single prop, stick it in an empty array
              const arrayTdChildren = !!tr.props.children && forceArray(tr.props.children)

              return !!arrayTdChildren && arrayTdChildren.map((td, tdIndex) =>
                // check to make sure they are <TableData> components
                (
                  td.hasOwnProperty('type') &&
                  td.type.hasOwnProperty('displayName') &&
                  td.type.displayName === 'TableData'
                ) && cloneElement(td, {
                  key: `td-${trIndex}-${tdIndex}`,
                  small: !!small,
                  soft: !!soft
                })
              )
            }
          }) // end newTds

          /*
            If new <TableData> components were cloned walk in reverse
            and rebuild children in newChildren. Assume that everything
            is valid based on above.
          */
          if (newTds) {
            newTrs = arrayTrChildren.map((tr, trIndex) => (
              cloneElement(tr, { key: `tr-${trIndex}` }, newTds[trIndex])
            ))

            newChildren = cloneElement(children, {}, newTrs)
          }
        }
      }
    })
  }

  return <StyledTable {...{ fixed }}>{newChildren || children}</StyledTable>
}

Table.propTypes = {
  /** Sets the column widths to be equal no matter what
      the content (`table-layout: fixed`). */
  fixed: PropTypes.bool,
  /** If present, the small prop will be passed down to
      all child `TableData` components. */
  small: PropTypes.bool,
  /** If present, the soft prop will be passed down to
      all child `TableData` components. */
  soft: PropTypes.bool
}

Table.defaultProps = {
  fixed: false,
  small: false,
  soft: false
}

export default Table
