import React from 'react'
import styled from 'styled-components'

import {rem, alpha} from '../../../lib/tools'
import { type, color } from '../../../designSystem'

const Pair = styled.div`
  padding:0px 0px 0px 10px;
  display:flex;
  flex-direction: row;
  height:40px;
  justify-content:left;
  align-items: center;
`

const StyledCheckbox = styled.input`
  
     -webkit-appearance: none;
     -moz-appearance: none;
     appearance: none;
     display: block;
     position: relative;
     background-color: ${color.lighterGray};
     color: ${color.darkGray};
     height: 30px;
     width: 30px;
     border: 0;
     cursor: pointer;     
     margin-right: 7px;
     outline: none;
     
  &:checked::before
  {
     position: absolute;
     font: 13px/1 'Open Sans', sans-serif;
     left: 11px;
     top: 7px;
     content: '\\02143';
     transform: rotate(40deg);
  }

  &:hover
  {
     background-color: ${color.gray};
     
  }

  &:checked
  {
     background-color: ${color.lightGray};
  }

  &:active, &:focus {
    background-color: ${color.lightGray};
    box-shadow: inset 0 0 ${rem(4)} 0 ${color.black};
    outline: none;
  }

  &::-webkit-placeholder,
  &::placeholder {
    font-style: ${type.style.italic};
    font-weight: ${type.weight.normal};
    color: ${alpha(color.white)(0.67)};
  }

  &:disabled, &:disabled.focus {
    margin: 0;
    font-style: ${type.style.italic};
    cursor: not-allowed;
    color: ${alpha(color.white)(0.5)};
    border: 0;
  }
`

const CheckboxLabel = styled.label`
  display: block;
  font-family: ${type.family.default};
  font-size: ${rem(type.size.s1)};
  font-weight: ${type.weight.normal};
  letter-spacing: ${rem(1)};
  text-transform: uppercase;
  color: ${alpha(color.gray)(1.0)};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: pointer;
`

const Checkbox = ({inputFocused, placeholder, ...rest}) => (
    <Pair>
        <StyledCheckbox
            isActive={!!inputFocused ? true : false}
            placeholder={!!inputFocused ? '' : placeholder}
            {...rest}
            type="checkbox"
        />
        <CheckboxLabel htmlFor={rest.id}>{placeholder}</CheckboxLabel>
    </Pair>
)

export default Checkbox
