import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import { rem } from '../../lib/tools'
import { fontFamily, fontSizeTableHead, fontWeightMedium, lineHeightMultiplierTableHead } from '../../tokens/typography'
import * as space from '../../tokens/space'
import * as border from '../../tokens/border'

const StyledTh = styled.th`
  width: ${props => props.width ? rem(props.width) : 'auto'};
  padding-top: ${space.s3};
  padding-right: ${space.s2};
  padding-bottom: ${space.s3};
  font-family: ${fontFamily};
  font-size: ${fontSizeTableHead};
  font-style: normal;
  font-weight: ${fontWeightMedium};
  line-height: ${lineHeightMultiplierTableHead};
  letter-spacing: ${rem(1)};
  text-align: ${props =>
    props.center ? 'center' :
    props.right ? 'right' :
    'left'
  };
  vertical-align: ${props =>
    props.bottom ? 'bottom' :
    props.top ? 'top' :
    'middle'
  };
  text-transform: uppercase;
  color: ${props => props.textColor ? props.textColor : props.theme.color.black1};
  border-top: ${border.borderWidth} ${border.borderStyleDefault} ${({ theme }) => theme.color.gray2};
`

const TableHead = ({
  bottom,
  center,
  children,
  right,
  textColor,
  top,
  width,
  ...rest
}) => (
  <StyledTh
    {...{
      bottom,
      center,
      right,
      textColor,
      top,
      width
    }}
    {...rest}
  >
    {children}
  </StyledTh>
)

TableHead.propTypes = {
  /** Align the table data content to the bottom of the cell.
      (Default style is the middle) */
  bottom: PropTypes.bool,
  /** Center text in table cell. */
  center: PropTypes.bool,
  /** Align text right in table cell. */
  right: PropTypes.bool,
  /** Pass a custom text color. Should be used sparingly, if at all… */
  textColor: PropTypes.string,
  /** Align the table data content to the top of the cell. */
  top: PropTypes.bool,
  /** Set a custom width. Must be a unitless pixel value,
      which is then converted to rem. Default width value
      of cell is `auto` */
  width: PropTypes.number
}

TableHead.defaultProps = {
  bottom: false,
  center: false,
  right: false,
  top: false
}

export default TableHead
