import React from 'react'
import styled from 'styled-components'
import {signOut} from '../../../lib/auth'

import Frame from '../../layout/Frame'
import Flex from '../../layout/Flex'
import Box from '../../layout/Box'

import Logo from './Logo'
import Navigation from './Navigation'

import {rem, alpha} from '../../../lib/tools'
import {type, color} from '../../../designSystem'

const StyledHeader = styled.header`
  display: block;
`

const NavUserLinks = styled(Flex)`
  z-index: 1;
`

const LogOutLink = styled.a`
  display: block;
  margin-bottom: ${rem(-4)};
  padding-bottom: ${rem(4)};
  font-size: ${rem(type.size.sm)};
  font-weight: ${type.weight.bold};
  letter-spacing: ${rem(1)};
  text-transform: uppercase;
  color: ${alpha(color.navy)(0.4)};
  border-bottom: ${rem(2)} solid transparent;

  &:visited {
    color: ${alpha(color.navy)(0.4)};
  }
  &:hover, &:focus {
    color: ${color.pink};
    border-bottom-color: ${alpha(color.pink)(0.75)};
  }
`

const NavUser = ({user, pageId, onSignOut}) => (
  <NavUserLinks>
    <Navigation user={user} pageId={pageId}/>
  </NavUserLinks>
)

const Header = ({user, pageId, onSignOut}) => {

  console.log('Header', user);

  const handleSignOut = async e => {
    e.preventDefault()

    await signOut()

    onSignOut()
  }

  return (
    <StyledHeader>
      <Frame>
        <Flex
          pt={[3, 4]}
          pb={[2, 3]}
          alignItems={'center'}
          justifyContent="space-between"
          flexDirection={'row'}
        >
          <Box>
            <Logo/>
          </Box>

          {(!!user && !user.get('isAnonymous')) &&
          <Box mt={1}>
            <LogOutLink href="#logout" onClick={handleSignOut}>Log Out</LogOutLink>
          </Box>
          }
        </Flex>

        <Box mt={3}>
          {(!!user && !user.get('isAnonymous')) && (user.get('organization') || user.get('isAdmin') ) && <NavUser {...{user, pageId}} onSignOut={handleSignOut}/>}
        </Box>
      </Frame>
    </StyledHeader>
  )
}

export default Header
