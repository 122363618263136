import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { space } from 'styled-system'

import { remOrReturn } from '../../lib/tools'
import * as border from '../../tokens/border'
import * as spaceTokens from '../../tokens/space'
import * as typography from '../../tokens/typography'

const StyledText = styled.p`
  ${space}

  ${props => !!props.block && `
    display: block;
  `}

  font-family: ${props =>
    props.fontFamily ? props.fontFamily :
    typography.fontFamily
  };
  font-size: ${props =>
    props.fontSize ? remOrReturn(props.fontSize) :
    props.meta ? typography.fontSizeMeta :
    props.small ? typography.fontSizeTextSmall :
    props.large ? typography.fontSizeTextLarge :
    props.subtitle ? typography.fontSizeSubtitle :
    props.title1 ? typography.fontSizeTitle1 :
    props.title2 ? typography.fontSizeTitle2 :
    props.marketingTitle ? typography.fontSizeMarketingTitle :
    typography.fontSizeText
  };
  font-style: ${props =>
    props.fontStyle ? props.fontStyle :
    props.italic ? 'italic' :
    'normal'
  };
  font-weight: ${props =>
    props.fontWeight ? props.fontWeight :
    props.regular ? typography.fontWeightRegular :
    props.medium ? typography.fontWeightMedium :
    props.bold ? typography.fontWeightBold :
    props.subtitle ? typography.fontWeightBold :
    (props.title1 || props.title2) ? typography.fontWeightMedium :
    typography.fontWeightRegular
  };
  letter-spacing: ${props => remOrReturn(props.letterSpacing)};
  line-height: ${props =>
    props.lineHeight ? props.lineHeight :
    props.meta ? typography.lineHeightMultiplierMeta :
    props.small ? typography.lineHeightMultiplierTextSmall :
    props.large ? typography.lineHeightMultiplierTextLarge :
    props.subtitle ? typography.lineHeightMultiplierSubtitle :
    props.title1 ? typography.lineHeightMultiplierTitle1 :
    props.title2 ? typography.lineHeightMultiplierTitle2 :
    props.marketingTitle ? typography.lineHeightMultiplierMarketingTitle :
    typography.lineHeightMultiplierText
  };
  text-align: ${props =>
    props.center ? 'center' :
    props.right ? 'right' :
    'left'
  };
  text-transform: ${props => props.textTransform};
  color: ${props =>
    props.textColor ? props.textColor :
    props.gray ? props.theme.color.black1 :
    props.blue ? props.theme.color.classicBlue4 :
    props.berry ? props.theme.color.berry :
    props.black ? props.theme.color.black4 :
    (props.subtitle || props.title1 || props.title2 || props.marketingTitle) ? props.theme.color.black4 :
    props.theme.color.black3
  };
  
  ${props => !!props.outline && `
    border-radius: ${border.borderRadiusLg};
    border-width: ${border.borderWidthThick};
    border-style: ${border.borderStyleDefault};
    border-color: ${props => props.theme.color.classicBlue3};
    padding: ${spaceTokens.s3} ${spaceTokens.s5};
    &:hover, &:focus {
      color: ${props.theme.color.white};
      border-color: ${props.theme.color.classicBlue4};
      background-color: ${props.theme.color.classicBlue4};
    }
  `};
  /* Link styling */
  a {
    color: ${props => props.hoverOnly ? 'inherit' : props.theme.color.classicBlue4};
    text-decoration: none;
    border-bottom-width: ${props => props.noUnderline ? 0 : border.borderWidth};
    border-bottom-style: ${border.borderStyleDefault};
    border-bottom-color: ${props => props.hoverOnly ? 'transparent' : props.theme.color.gray2};

    &:visited {
      color: ${({ theme }) => theme.color.classicBlue4};
    }
    &:hover, &:focus {
      color: ${({ theme }) => theme.color.classicBlue5};
      border-color: ${({ theme }) => theme.color.gray3};
    }
  }
`

const Text = ({
  as,
  berry,
  black,
  block,
  blue,
  bold,
  children,
  fontFamily,
  fontSize,
  fontStyle,
  fontWeight,
  gray,
  hoverOnly,
  italic,
  letterSpacing,
  lineHeight,
  large,
  marketingTitle,
  medium,
  meta,
  noUnderline,
  small,
  space,
  subtitle,
  textColor,
  textTransform,
  title1,
  title2,
  ...rest
}) => {
  const Tag = StyledText.withComponent(as)

  return (
    <Tag {...{
      berry,
      black,
      block,
      blue,
      bold,
      fontFamily,
      fontSize,
      fontStyle,
      fontWeight,
      gray,
      hoverOnly,
      italic,
      letterSpacing,
      lineHeight,
      marketingTitle,
      medium,
      meta,
      noUnderline,
      small,
      space,
      subtitle,
      textColor,
      textTransform,
      title1,
      title2,
      ...rest
    }}>
      {children}
    </Tag>
  )
}

Text.propTypes = {
  /** Sets the rendered HTML element. Can be any valid
      HTML tag. The default text style (not related to
      any tag) is Text from the GivApp Design System. */
  as: PropTypes.string,
  /** Sets text color to Light / Classic Blue 4. */
  berry: PropTypes.bool,
  /** Sets text color to a darker black (Light / Black 4) than
      the default (Light / Black 3). Note that the subtitle,
      title1, title2, and marketingTitle keywords automatically
      set this darker black. */
  black: PropTypes.bool,
  /** Set display: block. This is for when you need an inline
      element (e.g. `span`) to have block-level styling. */
  block: PropTypes.bool,
  /** Sets text color to Light / Black 1. */
  blue: PropTypes.bool,
  /** Keyword to enable `font-weight: 700` */
  bold: PropTypes.bool,
  /** Center text. */
  center: PropTypes.bool,
  /** Set specific font-family value. Overrides any font family
      set by any of the other style keywords. */
  fontFamily: PropTypes.string,
  /** Set specific font-size value. Overrides any font size
      set by any of the other style keywords. */
  fontSize: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  /** Set specific font-style value. Overrides any font style
      set by any of the other style keywords. */
  fontStyle: PropTypes.string,
  /** Set specific font-weight value. Overrides any font weight
      set by any of the other style keywords. */
  fontWeight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  /** Sets text color to Light / Black 1. */
  gray: PropTypes.bool,
  /** Keyword to enable link styling on :hover only. */
  hoverOnly: PropTypes.bool,
  /** Keyword to enable `font-style: italic` */
  italic: PropTypes.bool,
  /** Sets letter-spacing style. */
  letterSpacing: PropTypes.number,
  /** Set specific line-height value. Overrides any line height
      set by any of the other style keywords. */
  lineHeight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  /** Keyword to enable styles to match the Marketing Title
      style in the GivApp Design System. */
  marketingTitle: PropTypes.bool,
  /** Keyword to enable `font-weight: 500` */
  medium: PropTypes.bool,
  /** Keyword to enable font-size that matches Meta style
      in the GivApp Design System. */
  meta: PropTypes.bool,
  /** Keyword to disable the bottom border on links. */
  noUnderline: PropTypes.bool,
  /** Right-align text. */
  right: PropTypes.bool,
  /** Keyword to enable font-size that matches Text Small
      style in the GivApp Design System. */
  small: PropTypes.bool,
  /** Keyword to enable styles to match the Subtitle style
      in the GivApp Design System. */
  subtitle: PropTypes.bool,
  /** Set text-transform style. */
  textTransform: PropTypes.string,
  /** Set specific text color value. Overrides any text color
      set by any of the other style keywords. */
  textColor: PropTypes.string,
  /** Keyword to enable styles to match the Title 1 style
      in the GivApp Design System. */
  title1: PropTypes.bool,
  /** Keyword to enable styles to Match the Title 2 style
      in the GivApp Design System. */
  title2: PropTypes.bool,
}

Text.defaultProps = {
  as: 'p',
  berry: false,
  black: false,
  block: false,
  blue: false,
  bold: false,
  center: false,
  gray: false,
  hoverOnly: false,
  italic: false,
  letterSpacing: 0,
  marketingTitle: false,
  medium: false,
  meta: false,
  noUnderline: false,
  right: false,
  small: false,
  subtitle: false,
  textTransform: 'none',
  title1: false,
  title2: false,
  button: false
}

export default Text
