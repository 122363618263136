import React from 'react'
import { useTheme } from 'emotion-theming'

export const IconStar = ({ color, large, size }) => {
  const theme = useTheme()
  const iconColor = color || theme.color.black3

  return large ?
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M26.9703 14L20.837 18.4L23.1037 25.6C23.237 26.2667 22.837 26.8 22.3037 26.8C22.1703 26.8 21.9037 26.8 21.7703 26.6667L15.637 22.2667L9.50366 26.6667C9.37033 26.8 9.10366 26.8 8.97033 26.8C8.437 26.8 7.90366 26.2667 8.17033 25.6L10.437 18.4L4.70366 14C4.037 13.4667 4.30366 12.4 5.237 12.4H12.7037L14.9703 5.19999C15.1037 4.79999 15.5037 4.66666 15.9037 4.66666C16.3037 4.66666 16.5703 4.79999 16.7037 5.19999L18.9703 12.4H26.437C27.3703 12.4 27.637 13.4667 26.9703 14Z" fill={iconColor} />
    </svg> :
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.2277 10.5L15.6277 13.8L17.3277 19.2C17.4277 19.7 17.1277 20.1 16.7277 20.1C16.6277 20.1 16.4277 20.1 16.3277 20L11.7277 16.7L7.12775 20C7.02775 20.1 6.82775 20.1 6.72775 20.1C6.32775 20.1 5.92775 19.7 6.12775 19.2L7.82775 13.8L3.52775 10.5C3.02775 10.1 3.22775 9.3 3.92775 9.3H9.52775L11.2277 3.9C11.3277 3.6 11.6277 3.5 11.9277 3.5C12.2277 3.5 12.4277 3.6 12.5277 3.9L14.2277 9.3H19.8277C20.5277 9.3 20.7277 10.1 20.2277 10.5Z" fill={iconColor} />
    </svg>
}

IconStar.defaultProps = {
  size: 24
}
