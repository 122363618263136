import React from 'react'
import { useTheme } from 'emotion-theming'

export const IconPositive = ({ color, large, size }) => {
  const theme = useTheme()
  const iconColor = color || theme.color.black3

  return large ?
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="9.66667" stroke={iconColor} strokeWidth="2"/>
      <path d="M21.5946 12.8286L15.0408 20.228C14.7589 20.5099 14.2656 20.5804 13.9837 20.228L10.7421 16.8454C10.6716 16.7045 10.6716 16.4931 10.7421 16.3521L11.3058 15.6474C11.3763 15.5065 11.5877 15.5065 11.7287 15.5769L14.2656 16.8454L20.3261 11.6306C20.5376 11.5601 20.749 11.5601 20.8899 11.6306L21.5946 12.3353C21.7356 12.4762 21.7356 12.6876 21.5946 12.8286Z" fill={iconColor} />
    </svg> :
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="8.25" stroke={iconColor} strokeWidth="1.5"/>
      <path d="M16.72 9.32369L11.1903 15.567C10.9524 15.8048 10.5362 15.8643 10.2984 15.567L7.56321 12.7129C7.50375 12.594 7.50375 12.4156 7.56321 12.2967L8.03889 11.7021C8.09835 11.5832 8.27673 11.5832 8.39565 11.6426L10.5362 12.7129L15.6498 8.31287C15.8281 8.25342 16.0065 8.25342 16.1254 8.31287L16.72 8.90747C16.839 9.02639 16.839 9.20477 16.72 9.32369Z" fill={iconColor} />
    </svg>
}
IconPositive.defaultProps = {
  size: 24
}
