import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { useTheme } from 'emotion-theming'

import Box from '../Box'
import Flex from '../Flex'
import Text from '../Text'

import { rem } from '../../lib/tools'
import { basicLinkStyle } from '../../styles/basicLink'
import * as border from '../../tokens/border'
import * as typography from '../../tokens/typography'

const StyledCard = styled(Box)`
  width: 100%;
  border-width: ${border.borderWidth};
  border-style: ${border.borderStyleDefault};
  border-color: ${props =>
    props.blue ? props.theme.color.classicBlue2 :
    props.theme.color.gray2
  };
  background-color: ${props =>
    props.blue ? props.theme.color.classicBlue1 :
    props.theme.color.white
  };
  border-radius: ${border.borderRadiusSm};

  ${props => !props.blue && `
    box-shadow: 0 0 ${rem(2)} 0 ${props.theme.color.gray2Transparent};
  `}
`

const StyledBox = styled(Box)`
  border-top: ${border.borderWidth} ${border.borderStyleDefault} ${({ theme }) => theme.color.gray1};
`

const StyledLink = styled(Link)`
  ${({ theme }) => basicLinkStyle(theme)}

  font-size: ${typography.fontSizeTextSmall};
  font-weight: ${typography.fontWeightMedium};
  line-height: ${typography.lineHeightMultiplierTextSmall};
`
const StyledA = styled.a`
  ${({ theme }) => basicLinkStyle(theme)}

  font-size: ${typography.fontSizeTextSmall};
  font-weight: ${typography.fontWeightMedium};
  line-height: ${typography.lineHeightMultiplierTextSmall};
`

const Card = ({
  blue,
  children,
  control,
  header,
  icon,
  moreLink,
  moreLinkLabel,
  noRouterMoreLink,
  noTitleSpace,
  small,
  subTitle,
  title,
  titleColor,
  toggle
}) => {
  const theme = useTheme()

  return (
    <StyledCard {...{ blue }} px={5} pt={small ? 4 : 5} pb={small ? 5 : 6}>
      {!!header && header}
      {!!(title || toggle || icon) &&
        <Flex mt={control ? -1 : 0} justifyContent="space-between" alignItems="center">
          {!!title && <Text title2 black textColor={titleColor || ''}>{title}</Text>}
          {!!control && <Box ml={'auto'}>{control}</Box>}
          {!!icon && <Box my={-2} mr={-3}>{icon}</Box>}
        </Flex>
      }
      {!!subTitle && <Text mt={1} medium textColor={theme.color.black1}>{subTitle}</Text>}

      <Box mt={noTitleSpace ? 0 : (title || toggle) ? 7 : 0}>
        {children}
      </Box>

      {!!moreLink &&
        <StyledBox pt={3}>
          {noRouterMoreLink ?
            <StyledA href={moreLink}>{moreLinkLabel}</StyledA> :
            <StyledLink to={moreLink}>{moreLinkLabel}</StyledLink>
          }
        </StyledBox>
      }
    </StyledCard>
  )
}

Card.propTypes = {
  /** Keyword to activate a blue card. */
  blue: PropTypes.bool,
  /** Add a custom header to the card. */
  header: PropTypes.node,
  /** Add an icon to the upper right of card. */
  icon: PropTypes.node,
  /** URL for the "More" link in the card footer.
      If left blank, no link will appear. */
  moreLink: PropTypes.node,
  /** Replace the "More" link with a custom label */
  moreLinkLabel: PropTypes.string,
  /** If passed, the "More" link will render with
      a regular `<a>` tag rather than a `Link`
      from 'react-router-dom'. */
  noRouterMoreLink: PropTypes.bool,
  /** Disable the large space between the
      title/toggle and content. */
  noTitleSpace: PropTypes.bool,
  /** Slightly reduces inner padding of card to
      account for smaller size. */
  small: PropTypes.bool,
  /** Placed below the title. */
  subTitle: PropTypes.string,
  /** Main card title. */
  title: PropTypes.string,
  /** Set a custom color for the title. */
  titleColor: PropTypes.string,
  /** Pass a toggle to the upper right of the card. */
  toggle: PropTypes.node,
}

Card.defaultProps = {
  blue: false,
  moreLinkLabel: 'More',
  noRouterMoreLink: false,
  noTitleSpace: false,
  small: false
}

export default Card
