import React from 'react'
import { Route, Redirect } from 'react-router-dom'

const AdminRoute = ({ component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (rest.user.get('isAdmin') === true)
        ? React.createElement(component, props)
        : <Redirect
            to={{
              pathname: '/'
            }}
          />
      }
    />
  )
}

export default AdminRoute
