import React from "react";
import Parse from "parse";

import Flex from "givapp-design/src/components/Flex";
import {fetchOrganizationArticles} from "../ManagerApi";
import {ManagerComponent} from "../ManagerComponent";
import Box from '../../../layout/Box'

import {
  IconStar,
  IconNote,
  IconInfo,
  IconLike,
  IconLikeFill,
  IconOut,
  IconUp,
  IconXThin,
  IconX,
  IconOneTime,
  IconCheck,
  IconNegative,
  IconPositive,
  IconWarning,
  IconEdit,
  UIMessage,
  Toggle,
  theme
} from "givapp-design";
import MainHeading from '../../../style/text/MainHeading'
import * as _ from "lodash";
import Text from "givapp-design/src/components/Text";
import Table from "givapp-design/src/components/Table";
import TableRow from "givapp-design/src/components/TableRow";
import {TableHead} from "givapp-design";
import TableData from "givapp-design/src/components/TableData";
import {Link} from "react-router-dom";
import {rem} from "givapp-design/src/lib/tools";
import MessageCard from "../components/MessageCard";
import LoadingCard from "../components/LoadingCard";
import styled from "@emotion/styled";
import {ThemedContent} from "../components/ThemedContent";
import Button from "givapp-design/src/components/Button";
import Organization from "../Organization";
import {get, result} from "lodash";
import moment from "moment";
import {color, typography, border} from "givapp-design";
import * as spaceTokens from "givapp-design/src/tokens/space";
import {SwitchTransition, CSSTransition, Transition, TransitionGroup} from "react-transition-group"

const LinkButton = styled(Link)`
  display: block;
  background-color: ${({theme}) => theme.color.classicBlue3};
  color: ${({theme}) => theme.color.white} !important;
  font-family: ${typography.fontFamily};
  font-size: ${typography.fontSizeButtonLabel};
  font-weight: 600;
  padding: ${spaceTokens.s3} ${spaceTokens.s5};
  border-radius: ${border.borderRadiusMd};

  &:hover, &:focus {
    background-color: ${({theme}) => theme.color.classicBlue4}
  }
`

export class Bridge extends ManagerComponent {
  constructor() {
    super();

    this.prev = this.prev.bind(this)
    this.next = this.next.bind(this)
  }

  componentDidMount() {
    super.componentDidMount();
    const {setPageId} = this.props
    setPageId('bridge')


    this.setState(_.extend({
      isAdmin: this.props.user.get('isAdmin'),
      articles: {},
      deleted: false,
      updated: false,
      error: false,
      saving: false,
      loading: true,
      page: 0,
      size: 10,
      total: 0
    }, this.state), () => {
      this.refreshArticles();
    });
  }

  prev() {
    if (this.state.page > 0) {
      this.state.page = this.state.page - 1;
      this.refreshArticles();
    }
  }

  next() {
    if ((this.state.page + 1 * this.state.size) < this.state.total) {
      this.state.page = this.state.page + 1;
      this.refreshArticles();
    }
  }

  handleArticle(action, id) {
    this.setState({saving: true})
    let Article = new Parse.Object.extend('Article')

    new Parse.Query(Article).get(id).then((article) => {
      if (action === 'delete') {
        article.destroy().then((object) => {
          this.setState({
            deleted: object.get('title') ?? 'ID: ' + object.id
          })
          this.refreshArticles()
        }, (error) => {
          this.setState({error: error.message})
        })
      }

      if (action === 'publish') {
        if (article.get('published')) {
          article.unset('published')
        } else {
          article.set('published', new Date())
        }
        article.save().then((object) => {
          this.setState({
            updated: {title: 'Published:', message: object.get('title') ?? 'ID: ' + object.id}
          })
          this.refreshArticles()
        }, (error) => {
          this.setState({error: error.message})
        })
      }

      if (action === 'feature') {
        if (article.get('featured')) {
          article.unset('featured')
        } else {
          article.set('featured', true)
        }
        article.save().then((object) => {
          console.log(object)
          this.setState({
            updated: {
              title: object.get('featured') ? 'Featured:' : 'Un-Featured',
              message: object.get('title') ?? 'ID: ' + object.id
            }
          })
          this.refreshArticles()
        }, (error) => {
          this.setState({error: error.message})
        })
      }

    })

  }

  refreshArticles = async () => {
    console.log(this)



    Parse.Cloud.run('getOrganizationArticles', {organizationId: this.props.organization.id}).then(result => {
      this.state.articles = result
      this.state.total = result.length
      this.state.loading = false
      this.state.saving = false
      this.setState(this.state)
    }, error => {
      console.error(error);
    });

    /*
    await new Parse.Query(Organization).get(this.props.organization.id).then(async (org) => {
      let query = new Parse.Query(Article)
      query.equalTo("organization", org.toPointer()).descending('featured').addDescending('createdAt')
      let articles = []
      query.find().then(async (results) => {
        console.log(results)
        articles = results.map((row) => {
          return {
            id: row.id,
            featured: row.get('featured'),
            title: row.get('title'),
            message: row.get('message'),
            createdAt: moment(row.get('createdAt')).format('LLL'),
            published: row.get('published') ?? false,
          }
        })
        this.state.articles = articles
        this.state.total = articles.length
        this.state.loading = false
        this.state.saving = false
        this.setState(this.state)
      })


    })
*/

  }

  render() {
    const {
      isAdmin,
      organization,
      subaccounts,
      articles,
      total,
      loading,
      page,
      size,
      updated,
      error,
      deleted,
      saving
    } = this.state;
    let {selectedTheme} = this.props;

    return (
      <React.Fragment>
        {!!loading == true &&
          <Flex flexDirection={'column'} width={[1]} height={['100%']} justifyContent={'center'}>
            <LoadingCard>Loading...</LoadingCard>
          </Flex>
        }
        {!loading &&
          <ThemedContent p={rem(10)} theme={selectedTheme}>
            <Flex justifyContent={'space-between'} my={rem(10)}>
              <MainHeading>{organization.name} Articles</MainHeading>
              <Text>
                <LinkButton to={'articles/new'}>Add Article</LinkButton>
              </Text>
            </Flex>
            {!!!loading && !articles.length &&
              <Flex flexDirection={'column'} width={[1]} height={['100%']} justifyContent={'center'}>
                <MessageCard>
                  <Text>No Articles yet.</Text>
                  <Text>
                    <LinkButton to={'articles/new'}>Add an Article </LinkButton>
                  </Text>
                </MessageCard>
              </Flex>
            }
            <Box minHeight={50} mb={'1rem'}>
              {updated &&
                <CSSTransition in={updated} classNames={{
                  enterActive: 'fade-appear',
                  exitActive: 'fade-exit'
                }} timeout={900}>
                  <UIMessage type="positive" title={updated.title}>
                    <Flex width={'100%'}>
                      <Text>{updated.message}</Text>
                    </Flex>
                  </UIMessage>
                </CSSTransition>
              }
              {error &&
                <UIMessage type="negative" title="Error:">
                  <Text>{error}</Text>
                </UIMessage>
              }
              {deleted &&
                <UIMessage type="warning" title="Deleted:">
                  <Text>{deleted}</Text>
                </UIMessage>
              }
            </Box>

            {articles.length > 0 &&
              <Table fixed>
                <thead>

                <TableRow>
                  {isAdmin &&
                    <TableHead width={100}>
                      Featured
                    </TableHead>
                  }
                  <TableHead>
                    Title
                  </TableHead>
                  <TableHead>
                    Modified
                  </TableHead>
                  <TableHead width={150}>Actions</TableHead>
                </TableRow>
                </thead>
                <tbody>
                {articles.map(article => (
                  <TableRow key={article.id}>
                    {isAdmin &&
                      <TableData>
                        <Toggle isSwitchActive={article.featured}
                                onClick={(e) => this.handleArticle('feature', article.id)}/>
                      </TableData>
                    }
                    <TableData>
                      <Link to={'/articles/' + article.id}>{article.title}
                      </Link>
                    </TableData>
                    <TableData>{article.createdAt}</TableData>
                    <TableData>
                      <Button small danger onClick={(e) => this.handleArticle('delete', article.id)} title='Delete'
                              disabled={saving}>
                        Delete
                      </Button>

                    </TableData>

                  </TableRow>

                ))}

                </tbody>
              </Table>
            }
            <Box mt={rem(10)}>
              <Text>
                <Link to={'articles/new'}>Add Article</Link>
              </Text>
            </Box>
          </ThemedContent>
        }
      </React.Fragment>
    )
  }
}

export default Bridge
