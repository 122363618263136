import styled from 'styled-components'

import { rem } from '../../../lib/tools'
import { type, color, border } from '../../../designSystem'

const SubHeading = styled.h2`
  display: block;
  font-family: ${type.family.default};
  font-size: ${rem(type.size.s1)};
  font-style: ${type.style.normal};
  font-weight: ${type.weight.normal};
  line-height: ${type.lineHeight.body};
  color: ${color.gray};

  a {
    margin-bottom: ${rem(-2)};
    padding-bottom: ${rem(2)};
    text-decoration: none;
    color: ${color.quiteGray};
    border-bottom: ${rem(border.widthThick)} solid ${color.lighterGray};

    &:visited {
      color: ${color.gray};
      border-bottom-color: ${color.lighterGray};
    }
    &:hover, &:focus {
      color: ${color.black};
      border-bottom-color: ${color.lightGray};
    }
  }
`

export default SubHeading
