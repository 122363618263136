// Max width (in pixels) for the site
export const frameBase = 1080

// Max character count for push notifications
export const maxCharCount = 256

export default {
  frameBase,
  maxCharCount
}
