import React from 'react'
import Parse from 'parse'
import styled from 'styled-components'
import shortid from 'shortid'

import Flex from '../../layout/Flex'
import Box from '../../layout/Box'

import Hr from '../../style/elements/Hr'
import Label from '../../style/elements/Label'
import Select from '../../style/elements/Select'
import Button from '../../style/elements/Button'

/* Get react-select element */
//import SelectDonorAdmin from '../../parts/SelectDonorAdmin'

import MainHeading from '../../style/text/MainHeading'
import Text from '../../style/text/Text'

import {rem} from '../../../lib/tools'
import {color} from '../../../designSystem'

const GenerateBox = styled(Box)`
  max-width: ${rem(600)};
`

const Standout = styled.strong`
  color: ${props => !!props.textColor ? props.textColor : 'inherit'};
`

/*
  Context:
  https://basecamp.com/1770116/projects/15454390/messages/80141017
*/

export class Statements extends React.Component {
  constructor() {
    super()

    this.state = {
      availableMonths: [],
      monthForMonthlyDonationsToAllOrganizations: '',
      // monthForMonthlyDonationsByDonors: '',
      organizationForOrganizationFinancialStatement: '',
      individualDonorStatementUserId: '',
      organizations: [],

    }

    this.getValues = this.getValues.bind(this)
    this.setDonorForAction = this.setDonorForAction.bind(this)
  }

  handleChange = (key, e) => (
    e => this.setState({[key]: e.target.value})
  )

  /*
    Not thrilled with the this._isMounted setup
    See: https://github.com/jaredpalmer/formik/issues/772#issuecomment-406080224
  */
  getValues = () => {
    // Get all Organizations for select dropdown
    Parse.Cloud.run('fetchAllOrganizations', {}).then((result) => {
      if (this._isMounted === true) this.setState({organizations: result})
    })

    // Get available months for select dropdown
    Parse.Cloud.run('getAvailableMonths', {startDate: new Date.today().set({year: 2018, month: 7})}).then((result) => {
      if (this._isMounted === true) {
        this.setState({availableMonths: result})
      }
    })
  }

  setDonorForAction = (value) => {
    this.setState({
      individualDonorStatementUserId: value.value
    })
  }


  componentDidMount() {
    this._isMounted = true

    // Set active page state
    const {setPageId} = this.props
    setPageId('admin-statements')

    // Prepopluate select boxes with Organizations and months
    this.getValues()
  }

  componentWillUnmount() {
    this._isMounted = false
  }


  generateMonthlyDonationsToAllOrganizations(event) {
    event.preventDefault()

    const {monthForMonthlyDonationsToAllOrganizations} = this.state

    Parse.Cloud.run('monthlyDonationsToAllOrganizations', {
      monthForMonthlyDonations: monthForMonthlyDonationsToAllOrganizations
    }).then((result) => {
      this.props.history.push('/admin-statements/generated/monthly-donations-all-organizations', {
        month: monthForMonthlyDonationsToAllOrganizations,
        donations: result
      })
    })
  }

  // SAME DATA AS generateMonthlyDonationsToAllOrganizations
  // generateMonthlyDonationsByDonors(event) {
  //   event.preventDefault()
  //
  //   const { monthForMonthlyDonationsByDonors } = this.state
  //
  //   Parse.Cloud.run('monthlyDonationsToAllOrganizations', {
  //     monthForMonthlyDonations: monthForMonthlyDonationsByDonors
  //   }).then((result) => {
  //     this.props.history.push('/admin-statements/generated/monthly-donations-by-donors', {
  //       month: monthForMonthlyDonationsByDonors,
  //       donations: result
  //     })
  //   })
  // }


  generateOrganizationFinancialStatement(event) {
    event.preventDefault()

    const {organizationForOrganizationFinancialStatement} = this.state

    Parse.Cloud.run('organizationFinancialStatement', {
      organizationId: organizationForOrganizationFinancialStatement
    }).then((result) => {
      this.props.history.push('/admin-statements/generated/organization-financial-statement', {
        organizationName: result.organizationName,
        organizationDonations: result.organizationDonations
      })
    })
  }


  generateIndividualDonorStatementForAdmin(event) {
    event.preventDefault()

    const {individualDonorStatementUserId} = this.state

    Parse.Cloud.run('individualDonorStatementForAdmin', {
      donorUserId: individualDonorStatementUserId
    }).then((result) => {
      this.props.history.push('/admin-statements/generated/individual-donor-for-admin', {
        donorUsername: result.donorUsername,
        donorName: result.donorName,
        donorDonations: result.donorDonations
      })
    })
  }


  render() {
    const pageTitle = 'Financial Statements'

    const {
      availableMonths,
      monthForMonthlyDonationsToAllOrganizations,
      organizationForOrganizationFinancialStatement,
      organizations
    } = this.state

    const listMonths = availableMonths.length ?
      availableMonths.map((month) => {
        const labelYear = parseInt(month.substring(0, 4), 10)
        const labelMonth = parseInt(month.substring(4, 6), 10)

        return (
          <option key={shortid.generate()} value={month}>{new Date.today().set({
            year: labelYear,
            month: labelMonth
          }).toString('MMMM yyyy')}</option>
        )
      }) :
      <option value={false}>No available months</option>

    return (
      <Box>
        <MainHeading>{pageTitle}</MainHeading>

        <Box mt={3}>
          <Hr/>
        </Box>

        <GenerateBox mt={5}>
          <form onSubmit={(e) => this.generateMonthlyDonationsToAllOrganizations(e)} method="post">
            <Label><Standout textColor={color.navy}>Monthly Summary</Standout></Label>

            <Flex mt={1} alignItems={'center'}>
              <Select
                value={monthForMonthlyDonationsToAllOrganizations}
                onChange={this.handleChange('monthForMonthlyDonationsToAllOrganizations')}
              >
                <option value={false} key={shortid.generate()}>Select Month</option>
                {listMonths}
              </Select>

              <Box ml={1}>
                <Button htmlButton>Generate</Button>
              </Box>
            </Flex>
            <Box mt={1}>
              <Text minor><em>Monthly summary statement for all Organizations &amp; Donors.</em></Text>
            </Box>
          </form>
        </GenerateBox>

        {/* SAME DATA AS generateMonthlyDonationsToAllOrganizations
        <GenerateBox mt={5}>
          <form onSubmit={(e) => this.generateMonthlyDonationsByDonors(e)} method="post">
            <Label><Standout textColor={color.navy}>All Donors</Standout></Label>

            <Flex mt={1} alignItems={'center'}>
              <Select
                value={monthForMonthlyDonationsByDonors}
                onChange={this.handleChange('monthForMonthlyDonationsByDonors')}
              >
                <option value={false} key={shortid.generate()}>Select Month</option>
                {listMonths}
              </Select>

              <Box ml={1}>
                <Button htmlButton>Generate</Button>
              </Box>
            </Flex>
            <Box mt={1}>
              <Text minor><em>Monthly summary statement for all donors.</em></Text>
            </Box>
          </form>
        </GenerateBox>
        */}

        <GenerateBox mt={5}>
          <form onSubmit={(e) => this.generateOrganizationFinancialStatement(e)} method="post">
            <Label><Standout textColor={color.navy}>Individual Organization</Standout></Label>

            <Flex mt={1} alignItems={'center'}>
              <Select
                value={organizationForOrganizationFinancialStatement}
                onChange={this.handleChange('organizationForOrganizationFinancialStatement')}
              >
                <option value={false} key={shortid.generate()}>Select Organization</option>
                {organizations.map((organization) => (
                  <option value={organization.id} key={shortid.generate()}>{organization.get('name')}</option>
                ))}
              </Select>

              <Box ml={1}>
                <Button htmlButton>Generate</Button>
              </Box>
            </Flex>
            <Box mt={1}>
              <Text minor><em>Full financial statement for a specific Organization.</em></Text>
            </Box>
          </form>
        </GenerateBox>


        <GenerateBox mt={5}>
          <form onSubmit={(e) => this.generateIndividualDonorStatementForAdmin(e)} method="post">
            <Label><Standout textColor={color.pink}>Individual Donor</Standout></Label>

            <Flex mt={1} alignItems={'center'}>

              <Box ml={1}>
                <Button htmlButton>Generate</Button>
              </Box>
            </Flex>
            <Box mt={1}>
              <Text minor><em>Full financial statement for a specific Donor.</em></Text>
            </Box>
          </form>
        </GenerateBox>

      </Box>
    )
  }
}

export default Statements
