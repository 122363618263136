import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import Async from 'react-select/async'
// import Creatable from 'react-select/creatable'
import {useTheme} from 'emotion-theming'
import {Global, css} from '@emotion/core'

import CustomClearIndicator from './custom/ClearIndicator'
import CustomDropdownIndicator from './custom/DropdownIndicator'

import {rem} from '../../lib/tools'
import * as border from '../../tokens/border'
import * as space from '../../tokens/space'
import * as typography from '../../tokens/typography'

const setControlBorderColor = (state, error, theme) => {
  const {isDisabled, isFocused, isSelected} = state

  let borderColor = theme.color.gray3

  if (error) {
    borderColor = theme.color.negative3
  } else {
    if (isDisabled) borderColor = theme.color.gray1
    if (isFocused) borderColor = theme.color.classicBlue3
    if (isSelected) borderColor = theme.color.classicBlue3
  }

  return borderColor
}

const setControlBackgroundColor = (state, error, theme) => {
  const {isDisabled} = state

  let backgroundColor = theme.color.white

  if (isDisabled) backgroundColor = theme.color.gray1

  return backgroundColor
}

const setSingleValueTextColor = (state, error, theme) => {
  const {isDisabled, isFocused, isSelected} = state

  let textColor = theme.color.black3

  if (error && !(isFocused || isSelected)) {
    textColor = theme.color.negative4
  } else {
    if (isDisabled) textColor = theme.color.black1
  }

  return textColor
}

const setOptionBackgroundColor = (state, theme) => {
  const {isFocused, isSelected} = state

  let backgroundColor = 'transparent'

  if (isFocused) backgroundColor = theme.color.classicBlue1
  if (isSelected) backgroundColor = theme.color.classicBlue3

  return backgroundColor
}

const setOptionTextColor = (state, error, theme) => {
  const {isDisabled, isFocused, isSelected} = state

  let textColor = theme.color.black3

  if (isDisabled) textColor = theme.color.black1
  if (isFocused) textColor = theme.color.black4
  if (isSelected) textColor = theme.color.white

  return textColor
}

export const selectStyles = ({ error, large, small, theme }) => ({
  // container for clear icon
  clearIndicator: (provided, state) => ({
    ...provided,
    paddingTop: rem(2),
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0
  }),
  // outside "box" that contains the select entity
  container: (provided, state) => ({
    ...provided
  }),
  // main select entity
  control: (provided, state) => ({
    // Copied from ...provided
    boxSizing: 'border-box',
    display: 'flex',
    position: 'relative',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'default',
    outline: '0 !important',

    // custom styles
    height:
      small ? space.s6 :
      large ? space.s8 :
      space.s7
    ,
    margin: 0,
    paddingRight: 0,
    // the rem(2) adjustment is to account for how react-select handles the placeholder layout
    paddingLeft:
      small ? `calc(${space.s2} - ${rem(2)})` :
      large ? `calc(${space.s4} - ${rem(2)})` :
      `calc(${space.s3} - ${rem(2)})`
    ,
    // cursor: state.isDisabled ? 'default' : 'pointer',
    borderWidth: border.borderWidth,
    borderStyle: border.borderStyleDefault,
    borderColor: setControlBorderColor(state, error, theme),
    borderRadius: small ? border.borderRadiusSm : border.borderRadiusMd,
    boxShadow: 'none',
    backgroundColor: setControlBackgroundColor(state, error, theme)
  }),
  // container for dropdown arrow
  dropdownIndicator: (provided, state) => ({
    ...provided,
    paddingTop: 0,
    paddingRight: small ? rem(6) : space.s2,
    paddingBottom: 0,
    paddingLeft: small ? rem(6) : space.s2,
  }),
  group: (provided, state) => ({
    ...provided
  }),
  groupHeading: (provided, state) => ({
    ...provided
  }),
  // container for dropdown and clear indicator and separator
  indicatorsContainer: (provided, state) => ({
    ...provided
  }),
  // vertical line between value and rightside icon
  indicatorSeparator: (provided, state) => ({
    display: 'none'
  }),
  //
  input: (provided, state) => ({
    ...provided,
    fontSize:
      small ? typography.fontSizeTextSmall :
      large ? typography.fontSizeTextLarge :
      typography.fontSizeText
    ,
    fontStyle: 'normal',
    fontWeight: typography.fontWeightRegular,
  }),
  // container for loading icon
  loadingIndicator: (provided, state) => ({
    ...provided
  }),
  // loading message text style
  loadingMessage: (provided, state) => ({
    ...provided,
    fontFamily: typography.fontFamily,
    fontSize:
      small ? typography.fontSizeTextSmall :
      large ? typography.fontSizeTextLarge :
      typography.fontSizeText
    ,
    fontStyle: 'normal',
    fontWeight: typography.fontWeightRegular,
    lineHeight: small ? typography.lineHeightMultiplierTextSmall : typography.lineHeightMultiplierText,
    textAlign: 'left',
    color: theme.color.black1,
  }),
  // dropdown panel that contains the menu list
  menu: (provided, state) => ({
    ...provided,
    boxSizing: 'border-box',
    width: '100%',
    margin: 0,
    borderWidth: border.borderWidth,
    borderStyle: border.borderStyleDefault,
    borderColor: error ? theme.color.negative3 : theme.color.classicBlue3,
    borderTop: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: small ? border.borderRadiusSm : border.borderRadiusMd,
    borderBottomLeftRadius: small ? border.borderRadiusSm : border.borderRadiusMd,
    backgroundColor: theme.color.white,
    boxShadow: 'none',
    transform: small ?
      `translateY(calc(${border.borderRadiusSm} * -1))` :
      `translateY(calc(${border.borderRadiusMd} * -1))`,
    zIndex: 5,
  }),
  // contains list of options in dropdown panel
  menuList: (provided, state) => ({
    ...provided,
    // custom scrollbar
    /*
      scrollbar-width & scrollbar-color
      only supported by Firefox at the moment
      https://caniuse.com/#search=scrollbar-width
    */
    scrollbarWidth: 'thin',
    scrollbarColor: `${theme.color.gray3} transparent`,
    '&::-webkit-scrollbar': {
      width: rem(11)
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent'
    },
    '&::-webkit-scrollbar-thumb': {
      borderWidth: rem(3),
      borderStyle: 'solid',
      borderColor: theme.color.white,
      backgroundColor: theme.color.gray3,
      borderRadius: rem(6)
    }
  }),
  menuPortal: (provided, state) => ({
    ...provided
  }),
  // container box for multiValueLabel and multiValueRemove
  multiValue: (provided, state) => ({
    ...provided
  }),
  // label style for multi-value
  multiValueLabel: (provided, state) => ({
    ...provided
  }),
  // container for multi-value icon
  multiValueRemove: (provided, state) => ({
    ...provided
  }),
  // no options text style
  noOptionsMessage: (provided, state) => ({
    ...provided,
    fontFamily: typography.fontFamily,
    fontSize:
      small ? typography.fontSizeTextSmall :
      large ? typography.fontSizeTextLarge :
      typography.fontSizeText
    ,
    fontStyle: 'normal',
    fontWeight: typography.fontWeightRegular,
    lineHeight: small ? typography.lineHeightMultiplierTextSmall : typography.lineHeightMultiplierText,
    textAlign: 'left',
    color: theme.color.black1
  }),
  // individual options in menu list
  option: (provided, state) => ({
    marginTop: 0,
    marginRight: space.s1,
    marginLeft: space.s1,
    paddingTop: small ? space.s1 : space.s2,
    paddingRight: small ? space.s2 : space.s3,
    paddingBottom: small ? space.s1 : space.s2,
    paddingLeft: small ? space.s2 : space.s3,
    fontFamily: typography.fontFamily,
    fontSize:
      small ? typography.fontSizeTextSmall :
      large ? typography.fontSizeTextLarge :
      typography.fontSizeText
    ,
    fontStyle: state.isDisabled ? 'italic' : 'normal',
    fontWeight: typography.fontWeightRegular,
    lineHeight: small ? typography.lineHeightMultiplierTextSmall : typography.lineHeightMultiplierText,
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    color: setOptionTextColor(state, error, theme),
    backgroundColor: setOptionBackgroundColor(state, theme),
    borderRadius: small ? border.borderRadiusSm : border.borderRadiusMd
  }),
  // placeholder text style
  placeholder: (provided, state) => ({
    ...provided,
    marginTop: rem(1),
    marginRight: 0,
    marginLeft: rem(2),
    fontFamily: typography.fontFamily,
    fontSize:
      small ? typography.fontSizeTextSmall :
      large ? typography.fontSizeTextLarge :
      typography.fontSizeText
    ,
    fontStyle: state.isDisabled ? 'italic' : 'normal',
    fontWeight: typography.fontWeightRegular,
    color: error ? theme.color.negative3 : theme.color.gray5,
  }),
  // main value text style
  singleValue: (provided, state) => ({
    fontFamily: typography.fontFamily,
    fontSize:
      small ? typography.fontSizeTextSmall :
      large ? typography.fontSizeTextLarge :
      typography.fontSizeText
    ,
    fontStyle: state.isDisabled ? 'italic' : 'normal',
    fontWeight: typography.fontWeightMedium,
    lineHeight: small ? typography.lineHeightMultiplierTextSmall : typography.lineHeightMultiplierText,
    color: setSingleValueTextColor(state, error, theme),

    // control text overflow (keep selected option on one line)
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }),
  // inner space in select that surrounds value
  valueContainer: (provided, state) => ({
    ...provided,
    margin: 0,
    padding: 0,
    paddingTop: rem(1),

    // control text overflow (keep selected option on one line)
    flexWrap: 'nowrap'
  })
})

const SelectWrapper = ({
  clearable,
  creatable,
  disabled,
  error,
  isAsync,
  large,
  onChange,
  options,
  placeholder,
  search,
  small,
  ...rest
}) => {
  // const SelectComponent = creatable ? Creatable : Select
  const SelectComponent = isAsync ? Async : Select

  // pass contextual colors to style object
  const theme = useTheme()

  /*
    Note:
    react-select’s style API does not allow one to
    pass font-family styles to the input so we target
    the input with a class. Opting for a global
    style insert rather than an extra <div> around
    every select item.

    And since we’re already here, we add the custom
    scrollbar CSS as well.
  */

  return (
    <React.Fragment>
      <Global
        styles={css`
          .givapp-select input {
            font-family: ${typography.fontFamily};
          }
        `}
      />

      <SelectComponent
        className="givapp-select"
        {...{ error, large, onChange, options, placeholder, search, small, ...rest }}
        isDisabled={!!disabled}
        isClearable={!!clearable}
        components={{
          ClearIndicator: CustomClearIndicator,
          DropdownIndicator: CustomDropdownIndicator
        }}
        styles={selectStyles({ error, large, small, theme })}
      />
    </React.Fragment>
  )
}

SelectWrapper.propTypes = {
  /** Allows selected option to be cleared, displays an `x` icon. */
  clearable: PropTypes.bool,
  /** Sets style to reflect error state */
  error: PropTypes.bool,
  /** Switches Select to Async version */
  isAsync: PropTypes.bool,
  /** Sets large style for Select component. */
  large: PropTypes.bool,
  /** Built-in function that executes on selection.
   e.g. `onChange={selected => logSelection(selected)}`
   (View console to see example returned data.) */
  onChange: PropTypes.func,
  /** Array of objects used to populate select options.
   See source code for example structure. */
  options: PropTypes.array,
  /** Sets placeholder text. */
  placeholder: PropTypes.string,
  /** Enables search mode which switches the normal dropdown arrow
   to a search icon. The ability to enter text and filter the options
   based on that text input is available by default. This just
   switches the icon. */
  search: PropTypes.bool,
  /** Sets small style for Select component. */
  small: PropTypes.bool
}

SelectWrapper.defaultProps = {
  clearable: false,
  error: false,
  isAsync: false,
  large: false,
  placeholder: 'Select',
  search: false,
  small: false
}

export default SelectWrapper
