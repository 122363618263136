import styled from 'styled-components'

import { rem } from '../../../lib/tools'
import { type, color, dimension, border } from '../../../designSystem'

const TableTh = styled.th`
  padding: ${rem(dimension.d0)};
  font-size: ${rem(type.size.sm)};
  font-weight: ${type.weight.bold};
  line-height: ${type.lineHeight.meta};
  letter-spacing: ${rem(1)};
  text-transform: uppercase;
  text-align: center;
  color: ${color.darkGray};
  border-right: ${rem(border.widthThick)} ${border.style} ${color.white};
  background-color: ${color.lightestGray};
`

export default TableTh
