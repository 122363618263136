import React from 'react'
import { useTheme } from 'emotion-theming'

export const IconOut = ({ color, large, size }) => {
  const theme = useTheme()
  const iconColor = color || theme.color.black3

  return large ?
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.9802 16.1167L25.3108 18.45V21.8333C25.3108 23.8167 23.7959 25.3333 21.815 25.3333H10.1624C8.1815 25.3333 6.66667 23.8167 6.66667 21.8333V10.1667C6.66667 8.18334 8.1815 6.66667 10.1624 6.66667H13.5417L15.8722 9.00001H10.1624C9.46328 9.00001 8.99718 9.58334 8.99718 10.1667V21.8333C8.99718 22.5333 9.46328 23 10.1624 23H21.815C22.5141 23 22.9802 22.5333 22.9802 21.8333V16.1167ZM24.6116 6.66667L18.0862 7.71667C17.6201 7.83334 17.387 8.41667 17.7366 8.76667L19.4845 10.5167L13.4251 16.4667C12.959 16.9333 12.959 17.6333 13.4251 18.1L13.8913 18.5667C14.3574 19.0333 15.0565 19.0333 15.5226 18.5667L21.5819 12.5L23.3298 14.25C23.6794 14.6 24.262 14.3667 24.262 13.9L25.3108 7.36667C25.4273 6.90001 25.0777 6.66667 24.6116 6.66667Z" fill={iconColor} />
    </svg> :
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.2352 12.0875L18.9831 13.8375V16.375C18.9831 17.8625 17.8469 19 16.3612 19H7.62182C6.13612 19 5 17.8625 5 16.375V7.625C5 6.1375 6.13612 5 7.62182 5H10.1563L11.9041 6.75H7.62182C7.09746 6.75 6.74788 7.1875 6.74788 7.625V16.375C6.74788 16.9 7.09746 17.25 7.62182 17.25H16.3612C16.8856 17.25 17.2352 16.9 17.2352 16.375V12.0875ZM18.4587 5L13.5646 5.7875C13.215 5.875 13.0403 6.3125 13.3024 6.575L14.6134 7.8875L10.0689 12.35C9.71928 12.7 9.71928 13.225 10.0689 13.575L10.4184 13.925C10.768 14.275 11.2924 14.275 11.642 13.925L16.1865 9.375L17.4974 10.6875C17.7595 10.95 18.1965 10.775 18.1965 10.425L18.9831 5.525C19.0705 5.175 18.8083 5 18.4587 5Z" fill={iconColor} />
    </svg>
}

IconOut.defaultProps = {
  size: 24
}
