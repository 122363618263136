import React from 'react'
import styled from '@emotion/styled'

import { rem } from '../../../lib/tools'
import * as breakpoints from '../../../tokens/breakpoints'

const StyledSvg = styled.svg`
  width: ${rem(40)};
  cursor: ${props => props.isDisabled ? 'default' : 'pointer'};
  fill: ${props => props.isDisabled ? props.theme.color.gray5 : props.theme.color.classicBlue3};

  &:hover, &:focus, &:active {
    fill: ${props => props.isDisabled ? props.theme.color.gray5 : props.theme.color.classicBlue4};
  }

  @media (min-width: ${rem(breakpoints.breakpointSm)}) {
    width: ${rem(48)};
  }
`

// width prop has no affect at the moment
const ButtonSpecial = ({ isDisabled, onClick, type }) => (
  (type === 'decrease') ?
    <StyledSvg {...{ isDisabled, onClick }} width="48" height="48" viewBox="0 0 48 48" fill="#2C8DFF" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M2.5 24C2.5 35.8741 12.1259 45.5 24 45.5C35.8741 45.5 45.5 35.8741 45.5 24C45.5 12.1259 35.8741 2.5 24 2.5C12.1259 2.5 2.5 12.1259 2.5 24ZM24 -1.97737e-09C10.7452 -8.85298e-10 8.75822e-10 10.7452 1.9562e-09 24C3.03658e-09 37.2548 10.7452 48 24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 -3.06944e-09 24 -1.97737e-09Z" />
      <path d="M16.8955 25.8202L31.1515 25.8202L31.1515 23.2642L16.8955 23.2642L16.8955 25.8202Z" />
    </StyledSvg> :
    <StyledSvg {...{ isDisabled, onClick }} width="48" height="48" viewBox="0 0 48 48" fill="#2C8DFF" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M2.5 24C2.5 35.8741 12.1259 45.5 24 45.5C35.8741 45.5 45.5 35.8741 45.5 24C45.5 12.1259 35.8741 2.5 24 2.5C12.1259 2.5 2.5 12.1259 2.5 24ZM24 -1.97737e-09C10.7452 -8.85298e-10 8.75822e-10 10.7452 1.9562e-09 24C3.03658e-09 37.2548 10.7452 48 24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 -3.06944e-09 24 -1.97737e-09Z" />
      <path d="M32.2259 23.264L25.3499 23.264L25.3499 16.712L22.6499 16.712L22.6499 23.264L15.7739 23.264L15.7739 25.82L22.6499 25.82L22.6499 32.408L25.3499 32.408L25.3499 25.82L32.2259 25.82L32.2259 23.264Z" />
    </StyledSvg>
)

export default ButtonSpecial
