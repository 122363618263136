import React from 'react'
import { useTheme } from 'emotion-theming'

export const IconUp = ({ color, large, size }) => {
  const theme = useTheme()
  const iconColor = color || theme.color.black3

  return large ?
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.72551 19.5055C8.66101 19.5915 8.64668 19.7133 8.69685 19.828C8.74701 19.9355 8.84735 20 8.96919 20L23.0308 20C23.1527 20 23.253 19.9355 23.3032 19.828C23.3533 19.7133 23.339 19.5915 23.2745 19.5055L16.2365 10.9481C16.1864 10.8621 16.086 10.8262 16 10.8262C15.914 10.8262 15.828 10.8621 15.7635 10.9481L8.72551 19.5055Z" fill={iconColor} />
    </svg> :
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.54413 14.6291C6.49575 14.6936 6.485 14.785 6.52263 14.871C6.56025 14.9516 6.63551 15 6.72688 15L17.2731 15C17.3645 15 17.4397 14.9516 17.4774 14.871C17.515 14.785 17.5043 14.6936 17.4559 14.6291L12.1774 8.21107C12.1398 8.14656 12.0645 8.11969 12 8.11969C11.9355 8.11969 11.871 8.14656 11.8226 8.21107L6.54413 14.6291Z" fill={iconColor} />
    </svg>
}

IconUp.defaultProps = {
  size: 24
}
