import React from 'react'
import styled from 'styled-components'

import { rem, alpha } from '../../../lib/tools'
import { type, color, border } from '../../../designSystem'

const StyledLabel = styled.label`
  display: block;
  margin-left: ${rem(2)};
  font-family: ${type.family.default};
  font-size: ${props => !!props.minor ? rem(type.size.s0) : rem(type.size.s1)};
  font-weight: ${type.weight.normal};
  line-height: ${type.lineHeight.meta};
  color: ${props => !!props.textColor ? props.textColor : props.minor ? color.gray : color.black};

  a {
    margin-bottom: ${rem(2)};
    padding-bottom: ${rem(2)};
    text-decoration: none;
    color: ${props => !!props.textColor ? props.textColor : props.minor ? color.gray : color.black};
    border-bottom-width: ${rem(border.widthThick)};
    border-bottom-style: ${border.style};
    border-bottom-color: ${props =>
      !!props.textColor ?
        alpha(props.textColor)(0.1) :
        props.minor ?
          alpha(color.gray)(0.25) : alpha(color.black)(0.1)
    };

    &:visited {
      color: ${props => !!props.textColor ? props.textColor : props.minor ? color.gray : color.black};
    }
    &:hover, &:focus {
      color: ${props => !!props.textColor ? props.textColor : props.minor ? color.quiteGray : color.blackTrue};
      border-bottom-color: ${props =>
        !!props.textColor ?
          alpha(props.textColor)(0.2) :
          props.minor ?
            alpha(color.gray)(0.35) : alpha(color.black)(0.2)
      };
    }
  }

  span {
    margin-left: ${rem(4)};
    font-family: ${type.family.default};
    font-size: ${rem(type.size.sm)};
    font-weight: ${type.weight.normal};
    letter-spacing: normal;
    text-transform: none;
    color: ${color.gray};
  }

  strong {
    font-size: ${props => !!props.minor ? rem(type.size.sm) : rem(type.size.s0)};
    font-weight: ${type.weight.bold};
    letter-spacing: ${rem(1)};
    text-transform: uppercase;
  }
`

const Label = ({ children, textColor, minor, ...rest }) => (
  <StyledLabel {...{minor, textColor}} {...rest}>
    {children}
  </StyledLabel>
)

export default Label
