import React from 'react'
import styled from '@emotion/styled'

import {
  alignSelf,
  background,
  height,
  justifySelf,
  maxHeight,
  minHeight,
  maxWidth,
  minWidth,
  order,
  space,
  width
} from 'styled-system'

import * as borderTokens from '../../tokens/border'

const StyledBox = styled.div`
  box-sizing: border-box;
  display: block;
  ${props => !!props.relative && 'position: relative;'}
  ${props => !!props.noShrink && 'flex-shrink: 0;'}
  ${alignSelf}
  ${background}
  ${height}
  ${justifySelf}
  ${maxHeight}
  ${minHeight}
  ${maxWidth}
  ${minWidth}
  ${order}
  ${space}
  ${width}

  ${props => !!props.border && `
    border: ${borderTokens.borderWidth} ${borderTokens.borderStyleDefault} ${props.theme.color.gray2};
  `}
  ${props => !!props.borderTop && `
    border-top: ${borderTokens.borderWidth} ${borderTokens.borderStyleDefault} ${props.theme.color.gray2};
  `}
  ${props => !!props.borderRight && `
    border-right: ${borderTokens.borderWidth} ${borderTokens.borderStyleDefault} ${props.theme.color.gray2};
  `}
  ${props => !!props.borderBottom && `
    border-bottom: ${borderTokens.borderWidth} ${borderTokens.borderStyleDefault} ${props.theme.color.gray2};
  `}
  ${props => !!props.borderLeft && `
    border-left: ${borderTokens.borderWidth} ${borderTokens.borderStyleDefault} ${props.theme.color.gray2};
  `}

  ${props => !!props.borderSoft && `
    border-color: ${props.theme.color.gray1};
  `}
`

const Box = ({
  border,
  borderBottom,
  borderLeft,
  borderRight,
  borderSoft,
  borderTop,
  className,
  children,
  alignSelf,
  background,
  height,
  justifySelf,
  maxHeight,
  minHeight,
  maxWidth,
  minWidth,
  noShrink,
  order,
  relative,
  space,
  width,
  ...rest
}) => (
  <StyledBox {...{
    border,
    borderBottom,
    borderLeft,
    borderRight,
    borderSoft,
    borderTop,
    className,
    alignSelf,
    background,
    height,
    justifySelf,
    maxHeight,
    minHeight,
    maxWidth,
    minWidth,
    noShrink,
    order,
    relative,
    space,
    width,
    ...rest
  }}>
    {children}
  </StyledBox>
)

export default Box
