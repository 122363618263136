import React from "react";

export class ManagerComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true
    };
    //this.state = {
    //  loading: true
    //};
  }

  componentDidMount() {
    console.log('ManagerComponent:componentDidMount', this.state);
    let initialState = {
      organization: this.props.organization,
      subaccounts: this.props.subaccounts,
      loading: true
    };

    console.log('ManagerComponent:mount', initialState);

    // return new Promise((resolve, reject) => {
    this.setState(initialState, () => {
      //  resolve();
    });
    //});
  }

  componentWillUnmount() {
    console.log('ManagerComponent:componentWillUnmount', this.state);

  }
}
