import React from 'react'
import {Redirect} from 'react-router-dom'
import styled from 'styled-components'

//import Flex from '../layout/Flex'
//import Box from '../layout/Box'

//import MainHeading from '../style/text/MainHeading'
//import SubHeading from '../style/text/SubHeading'
//import UIMessage from '../style/text/UIMessage'
//import Input from '../style/elements/Input'
//import Button from '../style/elements/Button'

//import {rem} from '../../lib/tools'
import {rem} from "givapp-design/src/lib/tools";
import Parse from "parse";
import {Box, Button, Flex, Text, UIMessage} from "givapp-design";

import Input from "givapp-design/src/components/Input";
import { minWidth } from 'styled-system/dist/styles';

// import { updateUserPassword } from '../../lib/account'

/*
  From Parse's template page:
  document.getElementById('form').setAttribute('action', base + '/apps/' + id + '/request_password_reset');

  Local dev:
  http://localhost:3001/parse/apps/GivApp/request_password_reset
*/

//const passwordResetFormActionAttr = 'https://givapp-parse-server.herokuapp.com/parse/apps/A1808486-44D2-42C2-AC64-B7510F1DE398/request_password_reset'

export class NewPassword extends React.Component {
  state = {
    new_password: '',
    confirm_new_password: '',
    valid: false,
    password_updated: false,
    error: null
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    let nextState = {...this.state, [name]: value}

    this.setState(nextState)
  }

  handlePassiveChange = key => {
    return e => this.state[key] = e.target.value;
  }


  validate = state => {
    const {new_password, confirm_new_password} = state
    if (!new_password.length) return 'New password is required'
    if (new_password !== confirm_new_password) return 'New password fields should match'
    return false
  }

  handleSubmit = async e => {
    let nextState = {...this.state}
    nextState.error = this.validate(nextState)
    nextState.valid = !nextState.error

    if (!!nextState.valid) {
      // POST the form
    } else {
      e.preventDefault()
      this.setState(nextState)
    }
  }

  componentDidMount() {
    Parse.Cloud.run('config').then((result) => {
      debugger;
      //const values = queryString.parse(this.props.location.search);
      //const {id} = values;
      this.setState({config: result});
    });


  }

  render() {
    const {user} = this.props
    if (user) return <Redirect to="/account"/>

    const {error} = this.state
    const {token, username, id} = this.props.queryParams


    const {config} = this.state

    if (config) {
      //let passwordResetFormActionAttr = 'https://givapp-parse-server.herokuapp.com/parse/apps/A1808486-44D2-42C2-AC64-B7510F1DE398/request_password_reset'
      let passwordResetFormActionAttr = `${config.SERVER_URL}/apps/${id}/request_password_reset`;
      console.log(passwordResetFormActionAttr);

      return (
        <Box mt={3} width={1/3} minWidth={320} maxWidth={600} border={1} borderRadius={2} p={"1rem"}>
          <Text title1>Reset Password</Text>

          <Box mt={2}>
            <Text minor>Enter a new password below</Text>
          </Box>

          <Box mt={2} mb={3}>
            {error && <UIMessage type="error">{error}</UIMessage>}
          </Box>

            <Box mt={3}>
              <form method="post" onSubmit={this.handleSubmit} action={passwordResetFormActionAttr}>
                <Box mt={rem(2)}>
                  <Input
                    id="new-password"
                    name="new_password"
                    autoComplete="new-password"
                    type="password"
                    password
                    placeholder="New password"
                    onChange={this.handlePassiveChange('new_password')}
                    required
                    top
                  />
                </Box>
                <Box mt={rem(2)}>
                  <Input
                    id="new-password-again"
                    name="confirm_new_password"
                    autoComplete="new-password"
                    type="password"
                    placeholder="New password again"
                    onChange={this.handlePassiveChange('confirm_new_password')}
                    required
                    bottom
                  />
                </Box>

                {/* Hidden inputs */}
                <input name="username" id="username" type="hidden" value={username}/>
                <input name="token" id="token" type="hidden" value={token}/>

                <Flex mt={1}>
                  <Button htmlButton type="submit">Update Password</Button>
                </Flex>
              </form>
            </Box>

        </Box>
      )
    } else {
      return (
        <Box>
          <Text title1>Reset Password</Text>
        </Box>
      )
    }
  }
}

export default NewPassword
