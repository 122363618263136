import React from 'react'
import Parse from 'parse'
import styled from 'styled-components'
import shortid from 'shortid'
import NumberFormat from 'react-number-format'

import Flex from '../../layout/Flex'
import Box from '../../layout/Box'

import MainHeading from '../../style/text/MainHeading'
import DataLabel from '../../style/text/DataLabel'
import Hr from '../../style/elements/Hr'
import Label from '../../style/elements/Label'
import Select from '../../style/elements/Select'
import Button from '../../style/elements/Button'

import { rem } from '../../../lib/tools'
import { type, color } from '../../../designSystem'

const LargeNumber = styled.h3`
  display: block;
  font-size: ${rem(type.size.s4)};
  font-weight: ${type.weight.bold};
  color: ${props => !!props.textColor ? props.textColor : color.blue};
`

const RegularNumber = styled.h4`
  display: block;
  font-size: ${rem(type.size.s3)};
  font-weight: ${type.weight.bold};
  color: ${props => !!props.textColor ? props.textColor : color.darkGray};
`

const GenerateBox = styled(Box)`
  max-width: ${rem(600)};
`

const Standout = styled.strong`
  color: ${props => !!props.textColor ? props.textColor : 'inherit' };
`

export class Activity extends React.Component {
  constructor() {
    super()

    this.state = {
      totalDonated: 0,
      totalDonors: 0,
      lastThirtyDaysDonated: 0,
      newMonthlyDonors: 0,
      totalOrganizations: 0,
      newMonthlyOrganizations: 0,
      // totalNotifications: 0,
      organizations: [],
      organizationActivity: false,
      organizationId: '',
      organizationName: '',
      organizationDisplayName: '',
      organizationNewMonthlyDonors: 0,
      organizationTotalDonors: 0,
      organizationLastThirtyDaysDonated: 0,
      organizationTotalDonated: 0
    }

    this.getValues = this.getValues.bind(this)
    this.getOrganizationValues = this.getOrganizationValues.bind(this)
    this.activateOrganizationValueGetter = this.activateOrganizationValueGetter.bind(this)
  }

  handleChange = (key, e) => (
    e => this.setState({
      [key[0]]: e.target.value,
      [key[1]]: e.target.options[e.target.selectedIndex].text
    })
  )

  activateOrganizationValueGetter(event) {
    event.preventDefault()

    // Organization Id
    const { organizationId } = this.state

    // Basically if "Select Organization" not selected
    if (!!organizationId && (organizationId !== 'false')) {
      this.getOrganizationValues(organizationId)
    }
  }

  getValues = () => {
    // Get Organizations for Viewing Organization Stats
    Parse.Cloud.run('fetchAllOrganizations', {}).then((result) => {
      if (this._isMounted === true) this.setState({ organizations: result })
    })

    // Total money donated for all time
    Parse.Cloud.run('totalDonated', {}).then((result) => {
      if (this._isMounted === true) this.setState({ totalDonated: result })
    })

    // Total donors for all time
    Parse.Cloud.run('totalDonors', {}).then((result) => {
      if (this._isMounted === true) this.setState({ totalDonors: result })
    })

    // Last 30 days donated
    Parse.Cloud.run('lastThirtyDaysDonated', {}).then((result) => {
      if (this._isMounted === true) this.setState({ lastThirtyDaysDonated: result })
    })

    // New donors for this month
    Parse.Cloud.run('newMonthlyDonors', {}).then((result) => {
      if (this._isMounted === true) this.setState({ newMonthlyDonors: result })
    })

    // Total organizations for all time
    Parse.Cloud.run('totalOrganizations', {}).then((result) => {
      if (this._isMounted === true) this.setState({ totalOrganizations: result })
    })

    // New organizations for this month
    Parse.Cloud.run('newMonthlyOrganizations', {}).then((result) => {
      if (this._isMounted === true) this.setState({ newMonthlyOrganizations: result })
    })
  }

  // Go Get Organizational Values
  getOrganizationValues = (id) => {
    // Total money donated to Organization for all time
    Parse.Cloud.run('totalDonated', {organization: id}).then((result) => {
      if (this._isMounted === true) {
        const { organizationName } = this.state

        this.setState({
          organizationTotalDonated: result,
          organizationActivity: true,
          organizationDisplayName: organizationName
        })
      }
    })

    // Total donors of an Organization for all time
    Parse.Cloud.run('totalDonors', {organization: id}).then((result) => {
      if (this._isMounted === true) this.setState({ organizationTotalDonors: result })
    })

    // Last 30 days donated to Organization
    Parse.Cloud.run('lastThirtyDaysDonated', {organization: id}).then((result) => {
      if (this._isMounted === true) this.setState({ organizationLastThirtyDaysDonated: result })
    })

    // New donors of an Organization for this month
    Parse.Cloud.run('newMonthlyDonors', {organization: id}).then((result) => {
      if (this._isMounted === true) this.setState({ organizationNewMonthlyDonors: result })
    })
  }

  componentDidMount() {
    this._isMounted = true

    // Set active page state
    const { setPageId } = this.props
    setPageId('admin-activity')

    // Get data for current Organization (based on User)
    this.getValues()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    const pageTitle = 'Organization & Donor Activity'

    const {
      totalDonated,
      totalDonors,
      lastThirtyDaysDonated,
      newMonthlyDonors,
      totalOrganizations,
      newMonthlyOrganizations,
      // totalNotifications,
      organizations
    } = this.state

    // Organization activity
    const {
      organizationActivity,
      organizationId,
      organizationDisplayName,
      organizationNewMonthlyDonors,
      organizationTotalDonors,
      organizationLastThirtyDaysDonated,
      organizationTotalDonated
    } = this.state

    return (
      <Box>
        <MainHeading>{pageTitle}</MainHeading>

        <Box mt={3}>
          <Hr />
        </Box>

        <Flex flexDirection={['column', 'column', 'row']}>
          <Box width={[1/1, 1/1, 1/2]}>
            <Box mt={5} width={1/1}>
              <DataLabel>Total Donated</DataLabel>

              <Box mt={1}>
                <LargeNumber textColor={color.success}>
                  <NumberFormat
                    value={totalDonated}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                  />
                </LargeNumber>
              </Box>
            </Box>

            <Box mt={3} width={1/1}>
              <DataLabel>Donated over last 30 days</DataLabel>

              <Box mt={1}>
                <RegularNumber>
                  <NumberFormat
                    value={lastThirtyDaysDonated}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                  />
                </RegularNumber>
              </Box>
            </Box>

            {/*<Box mt={5} width={1/1}>
              <DataLabel>Notifications Sent</DataLabel>

              <Box mt={1}>
                <LargeNumber textColor={color.blue}>70,000</LargeNumber>
              </Box>
            </Box>*/}
          </Box>

          <Box width={[1/1, 1/1, 1/2]}>
            <Box mt={5} width={1/1}>
              <DataLabel>Total Donors</DataLabel>

              <Box mt={1}>
                <LargeNumber textColor={color.pink}>{totalDonors}</LargeNumber>
              </Box>
            </Box>
            <Box mt={3} width={1/1}>
              <DataLabel>New Donors This Month</DataLabel>

              <Box mt={1}>
                <RegularNumber>{newMonthlyDonors}</RegularNumber>
              </Box>
            </Box>

            <Box mt={5} width={1/1}>
              <DataLabel>Total Organizations</DataLabel>

              <Box mt={1}>
                <LargeNumber textColor={color.purple}>{totalOrganizations}</LargeNumber>
              </Box>
            </Box>
            <Box mt={3} width={1/1}>
              <DataLabel>New Organizations This Month</DataLabel>

              <Box mt={1}>
                <RegularNumber textColor={color.navy}>{newMonthlyOrganizations}</RegularNumber>
              </Box>
            </Box>
          </Box>
        </Flex>

        <Box mt={6}>
          <Hr />
        </Box>
        <GenerateBox mt={5}>
          <form onSubmit={(e) => this.activateOrganizationValueGetter(e)} method="post">
            <Label>View Individual <Standout textColor={color.purple}>Organization Stats</Standout></Label>

            <Flex mt={1} alignItems={'center'}>
              <Select
                value={organizationId}
                onChange={this.handleChange(['organizationId', 'organizationName'])}
              >
                <option value={false} key={shortid.generate()}>Select Organization</option>
                {organizations.map((organization) => (
                  <option value={organization.id} key={shortid.generate()}>{organization.get('name')}</option>
                ))}
              </Select>

              <Box ml={1}>
                <Button htmlButton>View</Button>
              </Box>
            </Flex>
          </form>
        </GenerateBox>


        {/* Organization Activity */}
        {!!organizationActivity &&
          <Box mt={6}>
            <MainHeading>{organizationDisplayName} Activity</MainHeading>

            <Flex flexDirection={['column', 'row']}>
              <Box width={[1/1, 1/2]}>
                <Box mt={5} width={1/1}>
                  <DataLabel>Total Donated</DataLabel>

                  <Box mt={1}>
                    <LargeNumber textColor={color.success}>
                      <NumberFormat
                        value={organizationTotalDonated}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                      />
                    </LargeNumber>
                  </Box>
                </Box>

                <Box mt={5} width={1/1}>
                  <DataLabel>Donated over last 30 days</DataLabel>

                  <Box mt={1}>
                    <RegularNumber>
                      <NumberFormat
                        value={organizationLastThirtyDaysDonated}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                      />
                    </RegularNumber>
                  </Box>
                </Box>
              </Box>

              <Box width={[1/1, 1/2]}>
                <Box mt={5} width={1/1}>
                  <DataLabel>Total Donors</DataLabel>

                  <Box mt={1}>
                    {/* 259 */}
                    <LargeNumber textColor={color.pink}>{organizationTotalDonors}</LargeNumber>
                  </Box>
                </Box>

                <Box mt={5} width={1/1}>
                  <DataLabel>New Donors This Month</DataLabel>

                  <Box mt={1}>
                    <RegularNumber textColor={color.navy}>{organizationNewMonthlyDonors}</RegularNumber>
                  </Box>
                </Box>
              </Box>
            </Flex>
          </Box>
        }


      </Box>
    )
  }
}

export default Activity
