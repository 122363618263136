import React from 'react'
import styled from 'styled-components'

import iconArrowDown from '../../../images/icn-arrow-down.svg'

import { rem } from '../../../lib/tools'
import { type, color, border, dimension } from '../../../designSystem'

const SelectWrap = styled('div')`
  width: 100%;
  position: relative;
`

const PlaceIcon = styled('span')`
  display: block;
  position: absolute;
  right: ${rem(dimension.d0 + (5 * 1))};
  top: ${rem(dimension.d1)};
  z-index: 1;
`

const IconArrowDown = styled.img`
  display: block;
  width: ${props => !!props.iconWidth ? rem(props.iconWidth) : rem(10)};
`

const StyledSelect = styled('select')`
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: ${rem(46)};
  padding-right: ${rem(dimension.d0)};
  padding-left: ${rem(dimension.d0)};
  font-family: ${type.family.default};
  font-size: ${rem(18)};
  font-weight: ${type.weight.regular};
  line-height: 1.5;
  color: ${color.black};
  border: 0;
  background-color: ${color.lightestGray};
  box-shadow: none;
  border-radius: ${rem(border.radius.sm)};
  -webkit-appearance: none;

  &:active, &:focus {
    background-color: ${color.white};
    box-shadow: inset 0 0 ${rem(2)} 0 ${color.lightGray};
    outline: none;
  }

  &::-webkit-placeholder,
  &::placeholder {
    font-weight: ${type.weight.regular};
    color: ${color.lightGray};
  }

  &:disabled {
    cursor: not-allowed;
    color: ${color.lightGray};
    box-shadow: none;
    background-color: ${color.lightestGray};
  }
  &:disabled::-webkit-placeholder,
  &:disabled::placeholder {
    font-family: ${type.family.default};
    font-style: ${type.style.italic};
    color: ${color.lightGray};
  }
`

const Select = ({ placeholder, children, ...rest }) => (
  <SelectWrap>
    <StyledSelect {...rest}>
      {/* {!!placeholder && <option selected disabled>{placeholder}</option>} */}
      {children}
    </StyledSelect>

    <PlaceIcon>
      <IconArrowDown src={iconArrowDown} iconWidth="12" />
    </PlaceIcon>
  </SelectWrap>
)

export default Select
