/*

  GIVAPP
  Design System
  ---
*/


export const breakpoint = {
  sm: 768,
  md: 960,
  lg: 1152,
  wd: 1440
}

// dim = dimension
export const dimension = {
  dm: 6,
  d0: 10,
  d1: 18,
  d2: 24,
  d3: 36,
  d4: 48,
  d5: 64,
  d6: 88
}

const fontSize = {
  sm: 14,
  s0: 16,
  s1: 20,
  s2: 26,
  s3: 36,
  s4: 48
}

const lineHeight = {
  meta: 1.25,
  summary: 1.4,
  body: 1.5,
  headline: 1.275,
  headlineTight: 1.1625,
  loose: 1.6
}

const fontFamily = {
  default: '"brandon-grotesque", -apple-system-body, -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Ubuntu", "Fira Sans", Arial, sans-serif',
  display: '"brandon-grotesque", -apple-system-body, -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Ubuntu", "Fira Sans", Arial, sans-serif',
  serif: 'Georgia, "Times New Roman", serif',
  mono: '"Roboto Mono", "Source Code Pro", "Menlo", "Consolas", "Liberation Mono", monospace'
}

const fontStyle = {
  regular: 'normal',
  normal: 'normal',
  italic: 'italic'
}

// temp fix
const fontWeight = {
  normal: 'normal',
  regular: 400,
  bold: 700
}

export const color = {
  trueBlack: '#000000',
  black: '#292a2a',
  darkGray: '#595a5a',
  gray: '#999a9a',
  lightGray: '#b9baba',
  lighterGray: '#d9dada',
  lightestGray: '#f3f5f5',
  white: '#fff',
  success: '#19b930',
  warning: '#b9a419',
  error: '#eb4c3c',
  facebook: '#3b5998',
  instagram: '#003569',
  twitter: '#1da1f2',
  blue: '#3cc5eb',
  pink: '#c42a91',
  navy: '#24346a',
  purple: '#7658bf'
}

export const border = {
  width: 1,
  widthThick: 2,
  color: color.lightestGray,
  style: 'solid',
  radius: {
    xs: 3,
    sm: 5,
    rg: 9,
    lg: 13
  }
}

export const type = {
  family: fontFamily,
  size: fontSize,
  style: fontStyle,
  weight: fontWeight,
  lineHeight: lineHeight,
}

export default {
  dimension,
  breakpoint,
  type,
  color,
  border
}
