import React from 'react'
import styled from 'styled-components'
import shortid from 'shortid'
import NumberFormat from 'react-number-format'

import Box from '../../layout/Box'
import Button from '../../style/elements/Button'

import Table from '../../style/elements/Table'
import TableCaption from '../../style/elements/TableCaption'
import TableTh from '../../style/elements/TableTh'
import TableTd from '../../style/elements/TableTd'

import LargeNumber from '../../style/text/LargeNumber'

import {rem, exportTableToCSV} from '../../../lib/tools'
import {type, color, dimension} from '../../../designSystem'
import RegularNumber from "../../style/text/RegularNumber";
import DonationType from "../../style/icons/DonationType";

const PageHeader = styled.h3`
  margin-bottom: ${rem(dimension.d1)};
  font-size: ${rem(type.size.s2)};
  font-weight: ${type.weight.bold};
  text-align: left;
  color: ${color.darkGray};
`

const Username = styled.span`
  display: block;
  font-family: ${type.family.default};
  font-size: ${rem(type.size.sm)};
  font-weight: ${type.weight.regular};
  line-height: ${type.lineHeight.meta};
  color: ${color.gray};
`

class GeneratedMonthlyDonationsToAllOrganizations extends React.Component {
  constructor() {
    super()

    this.state = {
      month: '',
      donations: []
    }
  }

  componentWillMount() {
    if (!!this.props.location.state) {
      const month = this.props.location.state.month || ''
      const donations = this.props.location.state.donations || []

      this.setState({month, donations})
    }
  }

  prepareDownloadCSV(event) {
    event.preventDefault()

    const {month} = this.state

    // Copied from render()
    const labelYear = parseInt(month.substring(0, 4), 10)
    const labelMonth = parseInt(month.substring(4, 6), 10)
    const monthDisplay = Date.today().set({year: labelYear, month: labelMonth}).toString('MMMM yyyy')

    exportTableToCSV('table-for-export', `${monthDisplay}-FinancialStatement.csv`)
  }

  render() {
    const {month, donations} = this.state

    const labelYear = parseInt(month.substring(0, 4), 10)
    const labelMonth = parseInt(month.substring(4, 6), 10)

    /*
    // Sort by Organization name
    const sortDonations = donations
    sortDonations.sort((a, b) => {
      const nameA = a.organization.toLowerCase()
      const nameB = b.organization.toLowerCase()

      if (nameA < nameB) return -1
      if (nameA > nameB) return 1
      return 0
    })
    */

    // Get total donation amount
    var totalDonations = 0;
    for (var i = 0; i < donations.length; ++i) {
      totalDonations += donations[i].amount;
    }

    var netDonations = 0;
    for (i = 0; i < donations.length; ++i) {
      if (donations[i].netAmount) {
        netDonations += donations[i].netAmount;
      }
    }

    // Sort by date for details view
    donations.sort((a, b) => (
      new Date(a.donationDate) - new Date(b.donationDate)
    ))

    // Group donations by Organization
    const groupDonationsByOrganization = donations.reduce((obj, item) => {
      obj[item.organization] = obj[item.organization] || []
      obj[item.organization].push(item.amount)
      return obj
    }, {})

    const groupDonationsNetByOrganization = donations.reduce((obj, item) => {
      obj[item.organization] = obj[item.organization] || []
      if (item.netAmount) {
        obj[item.organization].push(item.netAmount)
      }
      return obj
    }, {})

    // Group donations by Donor
    const groupDonationsByDonor = donations.reduce((obj, item) => {
      obj[item.username] = obj[item.username] || []
      // A little uncouth, but gets the job done
      obj[item.username].push({name: item.name, amount: item.amount, netAmount: item.netAmount})

      return obj
    }, {})

    return (
      <Box>
        <Box width={rem(200)}>
          <Button onClick={(e) => this.prepareDownloadCSV(e)}>Download CSV</Button>
        </Box>

        <Box mt={4}>
          <PageHeader>
            {Date.today().set({year: labelYear, month: labelMonth}).toString('MMMM yyyy')}
            &nbsp;Total Donations
          </PageHeader>

          <Box mt={1}>
            <LargeNumber textColor={color.success}>
              <NumberFormat
                value={totalDonations}
                decimalScale={2}
                fixedDecimalScale={true}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
              />
            </LargeNumber>
            <RegularNumber>
              [<NumberFormat
              value={netDonations}
              decimalScale={2}
              fixedDecimalScale={true}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />]
            </RegularNumber>
          </Box>
        </Box>

        <Box mt={6}>
          <Table>
            <TableCaption alt="true">Organization Summary</TableCaption>
            <thead>
            <tr>
              <TableTh>Organization</TableTh>
              <TableTh>Amount</TableTh>
              <TableTh>Net</TableTh>
            </tr>
            </thead>
            <tbody>
            {Object.keys(groupDonationsByOrganization).map(organization => {
              const donationsSum = groupDonationsByOrganization[organization].reduce((a, b) => a + b, 0)
              const netSum = groupDonationsNetByOrganization[organization].reduce((a, b) => a + b, 0)
              return (
                <tr key={shortid.generate()}>
                  <TableTd strong>{organization}</TableTd>
                  <TableTd strong>
                    <NumberFormat
                      value={donationsSum}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                    />
                  </TableTd>
                  <TableTd strong>
                    <NumberFormat
                      value={netSum}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                    />
                  </TableTd>
                </tr>
              )
            })}
            </tbody>
          </Table>
        </Box>

        <Box mt={6}>
          <Table>
            <TableCaption alt="true">Donor Summary</TableCaption>
            <thead>
            <tr>
              <TableTh>Name</TableTh>
              <TableTh>Amount</TableTh>
              <TableTh>Net</TableTh>
            </tr>
            </thead>
            <tbody>
            {Object.keys(groupDonationsByDonor).map(donor => {
              const donorName = groupDonationsByDonor[donor][0].name
              const donationsSum = groupDonationsByDonor[donor].reduce((total, obj) => total + obj.amount, 0)
              const netSum = groupDonationsByDonor[donor].reduce((total, obj) => total + (obj.netAmount ? obj.netAmount : 0.0), 0)

              return (
                <tr key={shortid.generate()}>
                  <TableTd strong>{donorName} <Username>{donor}</Username></TableTd>
                  <TableTd strong>
                    <NumberFormat
                      value={donationsSum}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                    />
                  </TableTd>
                  <TableTd strong>
                    <NumberFormat
                      value={netSum}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                    />
                  </TableTd>
                </tr>
              )
            })}
            </tbody>
          </Table>
        </Box>

        <Box mt={7}>
          <Table layout={'auto'}>
            <TableCaption alt="true">Donation Details</TableCaption>
            <thead>
            <tr>
              <TableTh>Date</TableTh>
              <TableTh>Organization</TableTh>
              <TableTh>Name</TableTh>
              <TableTh>Amount</TableTh>
              <TableTh>Cover Fees</TableTh>
              <TableTh>Type</TableTh>
              <TableTh>Gross</TableTh>
              <TableTh>Stripe Fee</TableTh>
              <TableTh>GivApp Fee</TableTh>
              <TableTh>Net</TableTh>
            </tr>
            </thead>
            <tbody>
            {donations.map(donation => (
              <tr key={shortid.generate()}>
                <TableTd>{donation.donationDate.toString('M/dd/yyyy')}</TableTd>
                <TableTd>{donation.organization}</TableTd>
                <TableTd>{donation.name} <Username>{donation.username}</Username></TableTd>
                <TableTd>
                  <NumberFormat
                    value={donation.amount}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                  />
                </TableTd>
                <TableTd position={'center'}>
                  {!!donation.coverTransactionFees === true &&
                  <span>&#10003;</span>
                  }
                </TableTd>
                <TableTd position={'center'}><DonationType type={donation.type}/></TableTd>
                <TableTd>
                  <NumberFormat
                    value={donation.grossAmount}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                  /></TableTd>
                <TableTd>
                  <NumberFormat
                    value={donation.stripeFee}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                  /></TableTd>
                <TableTd>
                  <NumberFormat
                    value={donation.givappFee}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                  /></TableTd>
                <TableTd>
                  <NumberFormat
                    value={donation.netAmount}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                  /></TableTd>
              </tr>
            ))}
            </tbody>
          </Table>
        </Box>


        {/* Hidden table for CSV Download */}
        <table id="table-for-export" style={{display: 'none'}}>
          <thead>
          <tr>
            <th>Donation Date</th>
            <th>Organization</th>
            <th>Name</th>
            <th>Username</th>
            <th>Amount</th>
            <th>Cover Fees</th>
            <th>Type</th>
            <th>Gross</th>
            <th>Stripe Fee</th>
            <th>GivApp Fee</th>
            <th>Net</th>
          </tr>
          </thead>

          <tbody>
          {donations.map(donation => (
            <tr key={shortid.generate()}>
              <td>{donation.donationDate.toString('M/dd/yyyy')}</td>
              <td>{donation.organization}</td>
              <td>{donation.name}</td>
              <td>{donation.username}</td>
              <td><NumberFormat
                value={donation.amount}
                decimalScale={2}
                fixedDecimalScale={true}
                displayType={'text'}
              /></td>
              <td>{donation.coverTransactionFees === true ? '✓' : ''}</td>
              <td>{donation.type}</td>
              <td>
                <NumberFormat
                  value={donation.grossAmount}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  displayType={'text'}
                /></td>
              <td>
                <NumberFormat
                  value={donation.stripeFee}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  displayType={'text'}
                />
              </td>
              <td>
                <NumberFormat
                  value={donation.givappFee}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  displayType={'text'}
                />
              </td>
              <td>
                <NumberFormat
                  value={donation.netAmount}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  displayType={'text'}
                />
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      </Box>
    )
  }
}

export default GeneratedMonthlyDonationsToAllOrganizations
