import React, {useState, useEffect} from 'react'
import { Redirect } from 'react-router-dom'
import MessageCard from './organization/components/MessageCard'
import {Box, Flex, Text} from 'givapp-design'
import Logo from '../Logo'
import { Link } from 'react-router-dom'

export function PasswordResetSuccess(props) {
    const [redirectNow, setRedirectNow] = useState(false)
    const [counter, setCounter] = React.useState(5);
        setTimeout(() => setRedirectNow(true), 5000);

         React.useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);
        return redirectNow ? (
            <Redirect to="/login" />
        ) : (
          <Flex alignItems={'center'} flexDirection={'column'}>
              <Box maxWidth={400} mb={2}>
                <Logo width={10} />
            </Box>
            <MessageCard width='50%'>
                      <Text title1>Your password has been reset!</Text>
                      <Box mt={3}>
                        <Text><p>You will be automaticlly redirected to the login page in ({counter}) seconds.</p>
                        <p>If you are not redirected, please click <Link to="/login"> here.</Link></p>
                        </Text>
                      </Box>
                      </MessageCard>
                      </Flex>
    )
}
