import React from 'react'
import {Route, Redirect} from 'react-router-dom'

const AuthenticatedRoute = ({component, ...rest}) => {
  console.log('AuthenticatedRoute', rest.path, rest.user, 'anonymous:' + (rest.user ? rest.user.get('isAnonymous') : 'unknown'));
  return (
    <Route
      {...rest}
      render={props => (rest.user && !rest.user.get('isAnonymous'))
        ? React.createElement(component, props)
        : <Redirect
          to={{
            pathname: '/login',
            state: {from: props.location}
          }}
        />
      }
    />
  )
}

export default AuthenticatedRoute
