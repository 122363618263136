import React from 'react'
import { useTheme } from 'emotion-theming'

export const IconLike = ({ color, large, size }) => {
  const theme = useTheme()
  const iconColor = color || theme.color.black3

  return large ?
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.5702 8.17061C16.2845 8.4563 15.7132 8.4563 15.5703 8.17061C11.7136 3.45682 5 7.17072 5 12.1702C5 16.5983 10.428 20.1694 14.999 25.026C15.5703 25.5974 16.5702 25.5974 16.9988 25.026C21.5697 20.1694 26.9977 16.5983 26.9977 12.1702C27.1406 7.17072 20.5698 3.45682 16.5702 8.17061ZM18.7129 19.1695C18.1415 19.7408 17.5701 20.3122 16.8559 20.8836C16.7131 21.0264 16.4274 21.1693 15.9989 21.1693C15.5703 21.1693 15.3259 21.0677 15.1418 20.8836C13.4277 19.1695 13.9289 19.6706 13.4277 19.1695C10.7137 16.4555 8.14253 14.0271 8.14253 12.1702C8.14253 10.4561 9.85664 9.17051 11.2851 9.17051C12.5706 9.17051 13.7134 10.1704 14.7133 11.0275L15.4275 11.7417C15.5703 11.8845 15.856 12.0274 16.1417 12.0274C16.4274 12.0274 16.5702 11.8845 16.7131 11.7417L17.4273 11.1703C18.4272 10.1704 19.7128 9.17051 20.9983 9.17051C22.4268 9.17051 24.1409 10.4561 24.1409 12.1702C23.998 14.0271 21.4269 16.4555 18.7129 19.1695Z" fill={iconColor} />
    </svg> :
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.4277 6.12796C12.2134 6.34222 11.7849 6.34222 11.6777 6.12796C8.78519 2.59261 3.75 5.37804 3.75 9.12765C3.75 12.4487 7.821 15.127 11.2492 18.7695C11.6777 19.198 12.4277 19.198 12.7491 18.7695C16.1773 15.127 20.2483 12.4487 20.2483 9.12765C20.3554 5.37804 15.4274 2.59261 12.4277 6.12796ZM14.0346 14.3771C13.6061 14.8056 13.1776 15.2342 12.6419 15.6627C12.5348 15.7698 12.3205 15.8769 11.9991 15.8769C11.6777 15.8769 11.4944 15.8007 11.3564 15.6627C10.0708 14.3771 10.4466 14.753 10.0708 14.3771C8.03527 12.3416 6.1069 10.5204 6.1069 9.12765C6.1069 7.84207 7.39248 6.87788 8.46379 6.87788C9.42798 6.87788 10.285 7.6278 11.035 8.27059L11.5706 8.80625C11.6777 8.91338 11.892 9.02051 12.1063 9.02051C12.3205 9.02051 12.4277 8.91338 12.5348 8.80625L13.0705 8.37772C13.8204 7.6278 14.7846 6.87788 15.7487 6.87788C16.8201 6.87788 18.1056 7.84207 18.1056 9.12765C17.9985 10.5204 16.0701 12.3416 14.0346 14.3771Z" fill={iconColor} />
    </svg>
}

IconLike.defaultProps = {
  size: 24
}
