export default `

  /* Regular */
  @font-face {
    font-family: "GivAppDMSans-Web";
    src: url("/webfonts/GivAppDMSans-Regular.woff2") format("woff2"),
         url("/webfonts/GivAppDMSans-Regular.woff") format("woff");
    font-style: normal;
    font-weight: 400;
  }

  /* Italic */
  @font-face {
    font-family: "GivAppDMSans-Web";
    src: url("/webfonts/GivAppDMSans-Italic.woff2") format("woff2"),
         url("/webfonts/GivAppDMSans-Italic.woff") format("woff");
    font-style: italic;
    font-weight: 400;
  }

  /* Medium */
  @font-face {
    font-family: "GivAppDMSans-Web";
    src: url("/webfonts/GivAppDMSans-Medium.woff2") format("woff2"),
         url("/webfonts/GivAppDMSans-Medium.woff") format("woff");
    font-style: normal;
    font-weight: 500;
  }

  /* Medium Italic */
  @font-face {
    font-family: "GivAppDMSans-Web";
    src: url("/webfonts/GivAppDMSans-MediumItalic.woff2") format("woff2"),
         url("/webfonts/GivAppDMSans-MediumItalic.woff") format("woff");
    font-style: italic;
    font-weight: 500;
  }

  /* Bold */
  @font-face {
    font-family: "GivAppDMSans-Web";
    src: url("/webfonts/GivAppDMSans-Bold.woff2") format("woff2"),
         url("/webfonts/GivAppDMSans-Bold.woff") format("woff");
    font-style: normal;
    font-weight: 700;
  }

  /* Bold Italic */
  @font-face {
    font-family: "GivAppDMSans-Web";
    src: url("/webfonts/GivAppDMSans-BoldItalic.woff2") format("woff2"),
         url("/webfonts/GivAppDMSans-BoldItalic.woff") format("woff");
    font-style: italic;
    font-weight: 700;
  }
`
