import React from 'react'
import styled from '@emotion/styled'
import { useTheme } from 'emotion-theming'

import Box from '../Box'
import { IconXThin } from '../icons'
import { rem } from '../../lib/tools'
import * as border from '../../tokens/border'
import * as spaceTokens from '../../tokens/space'

const StyledModal = styled(Box)`
  position: fixed;
  left: 50%;
  top: ${rem(64)};
  /*
    equivalent to clamp(${rem(64)}, 10vh, ${rem(128)})
    min()/max() have better browser support
  */
  top: max(${rem(64)}, min(10vh, ${rem(128)}));
  background-color: ${({ theme }) => theme.color.white};
  border-radius: ${border.borderRadiusLg};
  box-shadow:
    0 0 0 ${border.borderWidth} ${({ theme }) => theme.color.gray2Transparent},
    0 ${rem(2)} ${rem(6)} 0 ${({ theme }) => theme.color.gray2Transparent},
    0 ${rem(4)} ${rem(24)} 0 ${({ theme }) => theme.color.gray1Transparent}
  ;
  transform: translateX(-50%);

  z-index: 1001;
`

const CloseActionLink = styled.a`
  display: block;
  width: ${spaceTokens.s5};
  height: ${spaceTokens.s5};
  position: absolute;
  right: ${spaceTokens.s3};
  top: ${spaceTokens.s3};
  cursor: pointer;
  opacity: 0.68;

  &:hover, &:focus, &:active {
    opacity: 1.0;
  }
`

const Scrim = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: ${({ theme }) => theme.color.gray4Transparent};

  z-index: 1000;
`

const Modal = ({ onClose, children }) => {
  const theme = useTheme()

  return(
    <React.Fragment>
      <StyledModal width={rem(528)} p={5}>
        {!!onClose && <CloseActionLink onClick={onClose}><IconXThin color={theme.color.black3} /></CloseActionLink>}
        {children}
      </StyledModal>

      <Scrim />
    </React.Fragment>
  )
}
export default Modal
