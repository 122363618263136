import React from 'react'
import Parse from 'parse'
import Box from "../../layout/Box";
import MainHeading from "../../style/text/MainHeading";
import Hr from "../../style/elements/Hr";
import Button from "../../style/elements/Button";
import SubHeading from "../../style/text/SubHeading";
import Flex from "../../layout/Flex";
import Input from "../../style/elements/Input";
import Text from "../../style/text/Text";
import UIMessage from "../../style/text/UIMessage";
import {rem} from "../../../lib/tools";
import Checkbox from "../../style/elements/Checkbox";
import Label from "../../style/elements/Label";
import TableTh from "../../style/elements/TableTh";
import TableTd from "../../style/elements/TableTd";
import Table from "../../style/elements/Table";
import {color} from "../../../designSystem";
import shortid from "shortid";
import Select from "../../style/elements/Select";


export class Terminal extends React.Component {
  constructor() {
    super()

    this.state = {
      organizations: false,
      organizationId: null,
      token: null,
      terminal: null,
      connectionStatus: 'disconnected',
      error: false,
      amount: 2000,
      completedPayments: [],
      simulated: true,
      selectedReader: null
    }

  }

  handleChange = (key, e) => (
    e => this.setState({[key]: e.target.value})
  )

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  getValues = () => {
    // Get all Organizations for select dropdown
    if (this._isMounted === true) {
      Parse.Cloud.run('fetchAllOrganizations', {}).then((result) => {
        if (this._isMounted === true) this.setState({organizations: result})
      })
      let terminal = window['StripeTerminal'].create({
        onFetchConnectionToken: this.handleFetchConnectionToken,
        onUnexpectedReaderDisconnect: this.handleUnexpectedReaderDisconnect,
        onConnectionStatusChange: this.handleConnectionStatusChange,
        onPaymentStatusChange: this.handlePaymentStatusChange,
      });
      this.setState({
        'terminal': terminal
      });
    }

  }

  handleFetchConnectionToken = async () => {
    return Parse.Cloud.run('fetchConnectionToken', {}).then((result) => result.token['secret']);
  }

  handleUnexpectedReaderDisconnect = async () => {

    console.log('handleUnexpectedReaderDisconnect', this.state.terminal);
    this.setState({
      connectionStatus: 'disconnected',
      error: {
        message: 'Reader Disconnected!'
      },
      selectedReader: null
    })
  }

  handleConnectionStatusChange = async () => {
    console.log('handleConnectionStatusChange', this.state.terminal);
    this.setState({
      connectionStatus: this.state.terminal.connectionStatus
    });
  }

  handlePaymentStatusChange = async () => {
    console.log('handlePaymentStatusChange', this.state.terminal);
    this.setState({
      connectionStatus: this.state.terminal.connectionStatus
    });
  }

  clearError = () => {
    this.setState({
      error: null
    });
  }

// Handler for a "Connect Reader" button

  discoverReadersHandler = (e) => {
    const config = {simulated: this.state.simulated};
    this.state.terminal.discoverReaders(config).then((discoverResult) => {
      if (discoverResult.error) {
        console.log('Failed to discover: ', discoverResult.error);
        this.setState({
          error: discoverResult.error
        })
      } else if (discoverResult.discoveredReaders.length === 0) {
        console.log('No available readers.');
        this.setState({
          error: 'No available readers.'
        })
      } else {

        this.setState({
          readers: discoverResult.discoveredReaders
        });
      }
    });
  }

  connectReaderHandler = (e, reader) => {

    this.state.terminal.connectReader(reader).then((connectResult) => {
      if (connectResult.error) {
        console.log('Failed to connect: ', connectResult.error);
        this.setState({error: connectResult.error});
      } else {
        console.log('Connected to reader: ', connectResult.reader);
        this.setState({
          selectedReader: reader
        });
      }
    });
  }

  createPaymentIntent = () => {
    let amount = this.state.amount;
    if (amount < 0.50) {
      this.setState({
        error: "Amount must be greater than $0.50"
      });
      return;
    }

    Parse.Cloud.run('createPaymentIntentWithDonation', {
      email: this.state.email,
      amount: amount,
      organizationId: this.state.organizationId
    }).then((result) => {
      this.setState({
        intent: result.intent,
        donation: result.donation
      });
    }, (error) => {
      this.setState({
        error: error
      });
    });

  }

  clearPaymentIntent = () => {
    this.setState({
      intent: null
    });
  }

  checkout = (e) => {
    console.log('Checkout');
    // clientSecret is the client_secret from the PaymentIntent you created in Step 1.
    this.state.terminal.collectPaymentMethod(this.state.intent.client_secret).then((result) => {
      if (result.error) {
        // Placeholder for handling result.error
        this.setState({
          error: result.error
        })
      } else {
        // Placeholder for processing result.paymentIntent
        console.log("Payment Intent Result", result.paymentIntent);
        this.setState({
          intent: result.paymentIntent
        })
      }
    });
  }

  process = (e) => {
    this.state.terminal.processPayment(this.state.intent).then((result) => {
      if (result.error) {
        // Placeholder for handling result.error
        console.log("Error processing card.");
        this.setState({
          error: result.error
        });
        //couldn't process payment, start over?
        this.checkout(null);
      } else if (result.paymentIntent) {
        // Placeholder for notifying your backend to capture result.paymentIntent.id
        //TBD Call collect?
        this.setState({
          intent: result.paymentIntent
        })
      }
    }, (error) => {
      this.setState({
        error: error
      });
    });
  }

  capture = (e) => {
    Parse.Cloud.run('capturePaymentIntent', {
      id: this.state.intent.id
    }).then((result) => {
      //done?
      this.state.completedPayments.push(result);
      this.setState({
        intent: null,
        completedPayments: this.state.completedPayments
      });
    });
  }

  componentDidMount() {
    this._isMounted = true

    // Set active page state
    const {setPageId} = this.props
    setPageId('terminals')

    // Prepopluate select boxes with Organizations and months
    this.getValues()
  }

  componentWillUnmount() {
    this._isMounted = false

  }


  render() {
    const pageTitle = 'Terminals';
    const {
      organizations,
      organization,
      connectionStatus,
      error,
      readers,
      intent,
      completedPayments,
      simulated,
      selectedReader
    } = this.state;

    return (
      <Box>
        <MainHeading>{pageTitle}</MainHeading>

        <Box mt={3}>
          <Hr/>
        </Box>

        {organizations &&
        <Flex flexDirection={['row']} justifyContent="space-around">

          <Box width={[0.49]} mt={rem(5)}>
            {error &&
            <Box onClick={this.clearError}>
              <UIMessage type={'error'}>
                {error.message}
              </UIMessage>
            </Box>
            }
            <SubHeading>Select an organization</SubHeading>
            <Box>
              <Select
                value={organization}
                onChange={this.handleChange('organizationId')}>
                <option value={false} key={shortid.generate()}>NONE</option>
                {organizations.map((organization) => (
                  <option value={organization.id} key={organization.id}>{organization.get('name')}</option>
                ))}
              </Select>
            </Box>
            <SubHeading>{connectionStatus}</SubHeading>
            <Box mt={rem(2)}>
              <Checkbox
                id="simulated"
                name="simulated"
                type="checkbox"
                placeholder="Simulated"
                checked={simulated}
                onChange={this.handleInputChange}
              />
            </Box>

            {connectionStatus === "disconnected" &&
            <Box>
              <Button outline={true} onClick={this.discoverReadersHandler}>Discover</Button>
            </Box>
            }

            {readers && readers.length > 0 &&
            <Box>
              <Box>
                <SubHeading>Readers</SubHeading>
                {readers.map((reader, i) => (
                  <Flex key={reader.id} mb={rem(1)} padding={'2px'} flexDirection={['row']}>
                    <Box m={1} background={i % 2 === 0 ? color.lightestGray : 'white'} padding={'2px'} width={[0.75]}>
                      <Text>{reader.label}</Text>
                      <Text>{reader.ip_address}</Text>
                      <Text>
                        Device: {reader.device_type}{' '}({reader.status})
                      </Text>
                      <Text>Device ID: {reader.id}</Text>
                      <Text>Serial#: {reader.serial_number}</Text>
                    </Box>

                    <Box m={1} width={[0.25]}>
                      {connectionStatus !== 'connected' &&
                      <Button onClick={(e) => this.connectReaderHandler(e, reader)}>Connect</Button>
                      }
                      {reader === selectedReader &&
                      <Text>
                        <strong>Connected</strong>
                      </Text>
                      }
                    </Box>

                  </Flex>
                ))}
              </Box>
              {connectionStatus === "connected" &&
              <Box mt={rem(5)}>
                <Hr/>
                <Box>
                  <SubHeading>Payment Information</SubHeading>

                  <Input
                    required
                    id="amount"
                    name="amount"
                    type="amount"
                    placeholder="Amount"
                    value={this.state.amount}
                    onChange={this.handleChange('amount')}
                  />
                </Box>
                <Box mt={rem(5)}>
                  <Input
                    required
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Email"
                    value={this.state.email}
                    onChange={this.handleChange('email')}
                  />
                </Box>
                <Box mt={rem(5)}>
                  <Button onClick={(e) => this.createPaymentIntent(e)} disabled={this.state.amount < 0.50}>
                    Create Payment
                  </Button>
                </Box>
              </Box>
              }
              {intent &&
              <Flex flexDirection={['column']} mt={rem(5)}>
                <Button onClick={(e) => this.clearPaymentIntent(e)}>
                  Cancel Payment
                </Button>
                <Box mt={rem(5)}>
                  <Text>
                    <strong>Intent Status: ({intent.status})</strong>
                  </Text>
                </Box>
                {intent.status === 'requires_source' &&
                <Box>
                  <Text>Payment Intent Created, click "Checkout" and follow instructions on the Reader.</Text>
                  <Button onClick={(e) => this.checkout(e)}>Checkout</Button>
                </Box>
                }
                {intent.status === 'requires_confirmation' &&
                <Box>
                  <Text>Payment information received, click "Process" to continue.</Text>
                  <Button onClick={(e) => this.process(e)}>Process</Button>
                </Box>
                }
                {intent.status === 'requires_capture' &&
                <Box>
                  <Text>Payment information submitted, click "Capture" to complete the payment.</Text>
                  <Button onClick={(e) => this.capture(e)}>Capture</Button>
                </Box>
                }
              </Flex>
              }
            </Box>

            }
            <Box>
              <SubHeading>NOTES</SubHeading>
              <Table layout={'auto'}>
                <thead>
                <tr>
                  <TableTh>Decimal</TableTh><TableTh>Result</TableTh>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <TableTd>00</TableTd><TableTd>Payment is approved.</TableTd>
                </tr>
                <tr>
                  <TableTd>01</TableTd><TableTd>Payment is declined with a call_issuer code.</TableTd>
                </tr>
                <tr>
                  <TableTd>05</TableTd><TableTd>Payment is declined with a generic_decline code.</TableTd>
                </tr>
                <tr>
                  <TableTd>55</TableTd><TableTd>Payment is declined with an incorrect_pin code.</TableTd>
                </tr>
                <tr>
                  <TableTd>65</TableTd><TableTd>Payment is declined with a withdrawal_count_limit_exceeded
                  code.</TableTd>
                </tr>
                <tr>
                  <TableTd>75</TableTd><TableTd>Payment is declined with a pin_try_exceeded code.</TableTd>
                </tr>
                </tbody>
              </Table>
            </Box>
          </Box>
          <Box width={[0.49]} mt={rem(5)} mb={rem(5)}>
            {completedPayments.map(payment => (
              <Box key={payment.id}>
                <Table>
                  <tbody>
                  <tr>
                    <TableTd>
                      <Label>ID</Label>
                    </TableTd>
                    <TableTd>
                      {payment.id}
                    </TableTd>
                  </tr>
                  <tr>
                    <TableTd>
                      <Label>Amount</Label>
                    </TableTd>
                    <TableTd>
                      {payment.amount}
                    </TableTd>
                  </tr>
                  </tbody>
                  {payment.charges.data.map(chargeData => (
                    <tbody>
                    <tr>
                      <TableTd>
                        <Label>Auth. Code</Label>
                      </TableTd>
                      <TableTd>
                        {chargeData.authorization_code}
                      </TableTd>
                    </tr>
                    <tr>
                      <TableTd>
                        <Label>Created:</Label>
                      </TableTd>
                      <TableTd>
                        {chargeData.created}
                      </TableTd>

                    </tr>
                    <tr>
                      <TableTd>
                        <Label> Message </Label>
                      </TableTd>
                      <TableTd>
                        {chargeData.outcome.seller_message}
                      </TableTd>
                    </tr>
                    <tr>
                      <TableTd>
                        <Label>Card Brand</Label>
                      </TableTd>
                      <TableTd>
                        {chargeData.payment_method_details.card_present.brand}
                      </TableTd>
                    </tr>
                    <tr>
                      <TableTd>
                        <Label>Last 4</Label>
                      </TableTd>
                      <TableTd>
                        {chargeData.payment_method_details.card_present.last4}
                      </TableTd>
                    </tr>
                    <tr>
                      <TableTd>
                        <Label>Funding</Label>
                      </TableTd>
                      <TableTd>
                        {chargeData.payment_method_details.card_present.funding}
                      </TableTd>
                    </tr>
                    <tr>
                      <TableTd>
                        <Label>Exp. Date</Label>
                      </TableTd>
                      <TableTd>
                        {chargeData.payment_method_details.card_present.exp_month}
                        /
                        {chargeData.payment_method_details.card_present.exp_year}
                      </TableTd>
                    </tr>
                    <tr>
                      <TableTd>
                        <Label>Receipt Link</Label>
                      </TableTd>
                      <TableTd>
                        <a href={chargeData.receipt_url} target={'_blank'}>Stripe Receipt</a>
                      </TableTd>
                    </tr>
                    </tbody>
                  ))}
                </Table>
              </Box>
            ))}
          </Box>
        </Flex>
        }
      </Box>
    )
  }
}

export default Terminal
