import React from 'react'
import { useTheme } from 'emotion-theming'

export const IconCheck = ({ color, large, size }) => {
  const theme = useTheme()
  const iconColor = color || theme.color.black3

  return large ?
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M25.6297 10.2515L13.8329 23.5706C13.3255 24.078 12.4375 24.2048 11.9301 23.5706L6.09514 17.4819C5.96829 17.2282 5.96829 16.8476 6.09514 16.5939L7.10992 15.3255C7.23677 15.0718 7.61731 15.0718 7.87101 15.1986L12.4375 17.4819L23.3464 8.09514C23.727 7.96829 24.1075 7.96829 24.3612 8.09514L25.6297 9.36361C25.8834 9.61731 25.8834 9.99785 25.6297 10.2515Z" fill={iconColor} />
    </svg> :
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
     <path d="M19.2223 7.68866L10.3746 17.6779C9.9941 18.0585 9.32815 18.1536 8.9476 17.6779L4.57135 13.1114C4.47622 12.9211 4.47622 12.6357 4.57135 12.4455L5.33244 11.4941C5.42757 11.3038 5.71298 11.3038 5.90325 11.399L9.32815 13.1114L17.5098 6.07135C17.7952 5.97622 18.0806 5.97622 18.2709 6.07135L19.2223 7.02271C19.4126 7.21298 19.4126 7.49839 19.2223 7.68866Z" fill={iconColor} />
    </svg>
}

IconCheck.defaultProps = {
  size: 24
}
