import styled from 'styled-components'

import {rem} from '../../../lib/tools'
import {type, color, border, dimension} from '../../../designSystem'

const TableTd = styled.td`
  vertical-align: middle;
  padding: ${rem(dimension.d0)};
  font-size: ${rem(type.size.sm)};
  font-weight: ${props => !!props.strong ? type.weight.bold : type.weight.regular};
  line-height: ${type.lineHeight.meta};
  text-align: ${props => !!props.position ? props.position : 'left'};
  color: ${color.black};
  border-top: ${rem(border.width)} ${border.style} ${color.lighterGray};
  border-right: ${rem(border.widthThick)} ${border.style} ${color.white};
`

export default TableTd
