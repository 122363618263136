import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { rem } from '../../lib/tools'
import defaultAvatarUrl from '../../images/defaultAvatar.svg'

const StyledImage = styled.img`
  display: block;
  width: ${props => rem(props.size)};
  height: ${props => rem(props.size)};
  flex-shrink: 0;
  border: 0;
  border-radius: 50%;
`

const Avatar = ({ size, url, ...rest }) => (
  <StyledImage
    {...{ size }}
    src={url}
    width={size}
    height={size}
    {...rest}
  />
)

Avatar.propTypes = {
  /** Set the image size of the avatar. */
  size: PropTypes.number,
  /** Path to image file. Default avatar will be used if empty. */
  url: PropTypes.string
}

Avatar.defaultProps = {
  size: 40,
  url: defaultAvatarUrl
}

export default Avatar
