import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import {StyledInput} from '../Input/Input.component'
import * as space from '../../tokens/space'

const StyledTextarea = styled(StyledInput)`
  height: auto;
  padding-top: ${props => props.small ? space.s1 : space.s2};
  padding-bottom: ${props => props.small ? space.s1 : space.s2};
`

const Textarea = ({placeholder, rows, ...rest}) => {
  const RenderTextarea = StyledTextarea.withComponent('textarea')
  return (
    <RenderTextarea
      {...{placeholder, rows}}
      {...rest}
    />
  )
}

Textarea.propTypes = {
  /** Set the number of default rows for the textarea element.
   See Input for documentation on the rest of the props. */
  rows: PropTypes.number,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  placeholder: PropTypes.string,
  search: PropTypes.bool,
  small: PropTypes.bool
}

Textarea.defaultProps = {
  rows: 3,
  disabled: false,
  error: false,
  placeholder: 'Enter text',
  search: false,
  small: false
}

export default Textarea
