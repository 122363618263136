import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import {order, space} from 'styled-system'

import * as border from '../../tokens/border'
import * as breakpoints from '../../tokens/breakpoints'
import * as spaceTokens from '../../tokens/space'
import * as typography from '../../tokens/typography'
import {rem} from '../../lib/tools'

// get static icons for search inputs
import iconSearch from '../../images/icon-search-default-light-gray-4.svg'
import iconSearchActive from '../../images/icon-search-default-light-black-1.svg'
import iconSearchError from '../../images/icon-search-default-light-negative-3.svg'
import iconSearchErrorActive from '../../images/icon-search-default-light-negative-4.svg'
import iconSearchDarkMode from '../../images/icon-search-default-dark-gray-4.svg'
import iconSearchActiveDarkMode from '../../images/icon-search-default-dark-black-1.svg'
import iconSearchErrorDarkMode from '../../images/icon-search-default-dark-negative-3.svg'
import iconSearchErrorActiveDarkMode from '../../images/icon-search-default-dark-negative-4.svg'
import {Text} from "../../../index";
import Box from "../Box";
import Flex from "../Flex";

export const StyledInput = styled.input`
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: ${props =>
          props.small ? spaceTokens.s6 :
                  props.large ? spaceTokens.s8 :
                          spaceTokens.s7
  };
  padding-top: 0;
  padding-right: ${props =>

          props.isSearch ? spaceTokens.s6 :
                  props.small ? spaceTokens.s2 :
                          props.large ? spaceTokens.s4 :
                                  spaceTokens.s3
  };
  padding-bottom: 0;
  padding-left: ${props =>
          props.prefix ? spaceTokens.s1 :
                  props.small ? spaceTokens.s2 :
                          props.large ? spaceTokens.s4 :
                                  spaceTokens.s3
  };

  ${order}
  ${space}

  font-family: ${typography.fontFamily};
  font-size: ${props =>
          props.small ? typography.fontSizeTextSmall :
                  props.large ? typography.fontSizeTextLarge :
                          typography.fontSizeText
  };
  font-style: ${props => props.disabled ? 'italic' : 'normal'};
  font-weight: ${props =>
          props.bold ? typography.fontWeightBold :
                  props.error ? typography.fontWeightMedium :
                          typography.fontWeightRegular
  };
  line-height: ${props =>
          props.small ? typography.lineHeightMultiplierTextSmall :
                  props.large ? typography.lineHeightMultiplierTextLarge :
                          typography.lineHeightMultiplierText
  };
  color: ${props =>
          props.disabled ? props.theme.color.black1 :
                  props.error ? props.theme.color.negative4 :
                          props.theme.color.black3
  };
  cursor: ${props => props.disabled ? 'default' : 'text'};
  border-width: ${border.borderWidth};
  border-style: ${border.borderStyleDefault};
  border-color: ${props =>
          props.disabled ? props.theme.color.gray1 :
                  props.error ? props.theme.color.negative3 :
                          props.theme.color.gray3
  };
  background-color: ${props =>
          props.disabled ? props.theme.color.gray1 :
                  props.theme.color.white
  };
  border-radius: ${props => props.small ? border.borderRadiusSm : border.borderRadiusMd};
  box-shadow: none;
  outline: none;
  -webkit-appearance: none;

  ${props => !!props.isSearch && `
    background-image: url(${
          (props.theme.mode === 'dark') ?
                  props.error ? iconSearchErrorDarkMode : iconSearchDarkMode :
                  props.error ? iconSearchError :
                          iconSearch
  });
    background-position: right ${rem(6)} center;
    background-repeat: no-repeat;
    background-size: ${props.small ? rem(20) : spaceTokens.s5};
  `}
  ${props => !!props.fancy && `
    width: 100%;
    height: auto;
    font-size: ${rem(48)};
    font-weight: ${typography.fontWeightMedium};
    color: ${props.theme.color.black4};
    border: 0;
    text-align:center;

    @media (min-width: ${rem(breakpoints.breakpointSm)}) {
      font-size: ${rem(72)};
      max-width: ${rem(300)};
    }
  `}
  &:active, &:focus {
    border-color: ${props =>
            props.disabled ? props.theme.color.gray1 :
                    props.error ? props.theme.color.negative4 :
                            props.theme.color.classicBlue3
    };
    background-color: ${props =>
            props.disabled ? props.theme.color.gray1 :
                    props.theme.color.white
    };

    ${props => !!props.isSearch && !props.disabled && `
      background-image: url(${
            (props.theme.mode === 'dark') ?
                    props.error ? iconSearchErrorActiveDarkMode : iconSearchActiveDarkMode :
                    props.error ? iconSearchErrorActive :
                            iconSearchActive
    });
    `}
  }

  &::-webkit-placeholder,
  &::placeholder {
    font-family: ${typography.fontFamily};
    font-weight: ${typography.fontWeightRegular};
    color: ${props => props.error ? props.theme.color.negative3 : props.theme.color.gray5};
  }

  /*
    removes the up/down arrows on number inputs
    https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp
  */

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -moz-appearance: textfield;
`

const Input = ({disabled, error, fancy, order, search, small, space, ...rest}) => (
  <StyledInput
    {...{disabled, error, fancy, order, small, space}}
    isSearch={!!search}
    {...rest}
  />
)

Input.propTypes = {
  /** Make input text bold */
  bold: PropTypes.bool,
  /** Sets input style and HTML tag to disabled. */
  disabled: PropTypes.bool,
  /** Sets input style to reflect an error state. */
  error: PropTypes.bool,
  /** Sets style to be large and text-only. */
  fancy: PropTypes.bool,
  /** Sets input placeholder attribute. */
  placeholder: PropTypes.string,
  /** Places search icon on right and adjusts spacing. */
  search: PropTypes.bool,
  /** Sets small input style. */
  small: PropTypes.bool
}

Input.defaultProps = {
  bold: false,
  disabled: false,
  error: false,
  fancy: false,
  placeholder: 'Enter text',
  search: false,
  small: false
}

export default Input
