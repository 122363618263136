import React from 'react'
import {Route, Redirect} from 'react-router-dom'

const DonateRoute = ({component, ...rest}) => {
  console.log('DonateRoute', rest.user, 'anonymous:' + (rest.user ? rest.user.get('isAnonymous') : 'unknown'));
  return (
    <Route
      {...rest}
      render={props =>  React.createElement(component, props)}
    />
  )
}

export default DonateRoute
