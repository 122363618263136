import * as breakpointVals from '../tokens/breakpoints'
import * as color from '../tokens/color'
import * as spaceVals from '../tokens/space'
import { rem } from '../lib/tools'

const themeDarkColors = {
  white: color.darkWhite,
  gray1: color.darkGray1,
  gray2: color.darkGray2,
  gray3: color.darkGray3,
  gray4: color.darkGray4,
  gray5: color.darkGray5,
  black1: color.darkBlack1,
  black2: color.darkBlack2,
  black3: color.darkBlack3,
  black4: color.darkBlack4,
  black5: color.darkBlack5,
  berry: color.darkBerry,
  classicBlue1: color.darkClassicBlue1,
  classicBlue2: color.darkClassicBlue2,
  classicBlue3: color.darkClassicBlue3,
  classicBlue4: color.darkClassicBlue4,
  classicBlue5: color.darkClassicBlue5,
  positive1: color.darkPositive1,
  positive2: color.darkPositive2,
  positive3: color.darkPositive3,
  positive4: color.darkPositive4,
  positive5: color.darkPositive5,
  notification1: color.darkNotification1,
  notification2: color.darkNotification2,
  notification3: color.darkNotification3,
  notification4: color.darkNotification4,
  notification5: color.darkNotification5,
  negative1: color.darkNegative1,
  negative2: color.darkNegative2,
  negative3: color.darkNegative3,
  negative4: color.darkNegative4,
  negative5: color.darkNegative5,
  gray1Transparent: color.darkGray1Transparent,
  gray2Transparent: color.darkGray2Transparent,
  gray3Transparent: color.darkGray3Transparent,
  gray4Transparent: color.darkGray4Transparent,
  gray5Transparent: color.darkGray5Transparent,
  black1Transparent: color.darkBlack1Transparent,
  black2Transparent: color.darkBlack2Transparent,
  black3Transparent: color.darkBlack3Transparent,
  brightGold: color.darkBrightGold,
  likeRed: color.darkLikeRed
}

const themeLightColors = {
  white: color.lightWhite,
  gray1: color.lightGray1,
  gray2: color.lightGray2,
  gray3: color.lightGray3,
  gray4: color.lightGray4,
  gray5: color.lightGray5,
  black1: color.lightBlack1,
  black2: color.lightBlack2,
  black3: color.lightBlack3,
  black4: color.lightBlack4,
  black5: color.lightBlack5,
  berry: color.lightBerry,
  classicBlue1: color.lightClassicBlue1,
  classicBlue2: color.lightClassicBlue2,
  classicBlue3: color.lightClassicBlue3,
  classicBlue4: color.lightClassicBlue4,
  classicBlue5: color.lightClassicBlue5,
  positive1: color.lightPositive1,
  positive2: color.lightPositive2,
  positive3: color.lightPositive3,
  positive4: color.lightPositive4,
  positive5: color.lightPositive5,
  notification1: color.lightNotification1,
  notification2: color.lightNotification2,
  notification3: color.lightNotification3,
  notification4: color.lightNotification4,
  notification5: color.lightNotification5,
  negative1: color.lightNegative1,
  negative2: color.lightNegative2,
  negative3: color.lightNegative3,
  negative4: color.lightNegative4,
  negative5: color.lightNegative5,
  gray1Transparent: color.lightGray1Transparent,
  gray2Transparent: color.lightGray2Transparent,
  gray3Transparent: color.lightGray3Transparent,
  gray4Transparent: color.lightGray4Transparent,
  gray5Transparent: color.lightGray5Transparent,
  black1Transparent: color.lightBlack1Transparent,
  black2Transparent: color.lightBlack2Transparent,
  black3Transparent: color.lightBlack3Transparent,
  brightGold: color.lightBrightGold,
  likeRed: color.lightLikeRed
}

const space = [
  0,
  spaceVals.s1,
  spaceVals.s2,
  spaceVals.s3,
  spaceVals.s4,
  spaceVals.s5,
  spaceVals.s6,
  spaceVals.s7,
  spaceVals.s8,
  spaceVals.s9
]

const breakpoints = [
  rem(breakpointVals.breakpointSm),
  rem(breakpointVals.breakpointMd),
  rem(breakpointVals.breakpointLg),
  rem(breakpointVals.breakpointWd)
]

const theme = (mode) => ({
  breakpoints: breakpoints,
  color: (mode === 'dark') ? themeDarkColors : themeLightColors,
  mode: mode,
  space: space
})

export default theme
