import React from 'react'
import Parse from 'parse'
import {Box, Button, Text, UIMessage} from "givapp-design";
//import Button from '../style/elements/Button'
//import Box from '../layout/Box'

//import MainHeading from '../style/text/MainHeading'
//import SubHeading from '../style/text/SubHeading'

//import UIMessage from "../style/text/UIMessage";

export class VerifyPayment extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      charge: null,
      uuid: '',
      confirmed: false,
      error: null
    }

    const pathname = this.props.location.pathname;

    if (pathname.indexOf('onetime') > -1) {
      this.fetch = 'fetchOneTimeDonationWithUUID';
      this.confirm = 'confirmOneTimeDonationWithUUID';
    } else if (pathname.indexOf('recurring') > -1) {
      this.fetch = 'fetchRecurringDonationWithUUID';
      this.confirm = 'confirmRecurringDonationWithUUID';
    }
    this.fetchDonation = this.fetchDonation.bind(this)
    this.submitConfirmation = this.submitConfirmation.bind(this)
  }


  componentDidMount() {
    this._isMounted = true
    this.fetchDonation();
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  fetchDonation = () => {
    const {uuid} = this.props.match.params;


    Parse.Cloud.run(this.fetch, {uuid: uuid}).then((result) => {
      console.log(result);

      if (this._isMounted === true) {

        this.setState({
          organization: result.get('organization'),
          uuid: uuid,
          mainHeading: `Your donation to ${result.get('organization').get('name')} is pending.`
        });

      }
    }, (error) => {
      this.setState({
        error: {
          message: "Unable to load transaction, " + error.message
        }
      });
    });

  }

  submitConfirmation(event) {
    event.preventDefault()
    const {uuid} = this.props.match.params;

    Parse.Cloud.run(this.confirm, {uuid: uuid}).then(() => {

      if (this._isMounted === true) {

        this.setState({
          confirmed: true,
          mainHeading: `Your donation to ${this.state.organization.get('name')} has been confirmed.`
        });

      }
    }, (error) => {
      this.setState({
        error: {
          message: "Unabled to confirm transaction, " + error.message
        }
      });
    });
  }

  render() {
    const imgStyle = {
      width: '200px'
    };

    const {
      organization,
      confirmed,
      error,
      mainHeading
    } = this.state


    if (organization) {

      return (
        <Box>
          {!!error &&
          <Box mb={3}>
            <UIMessage type="error">Error: {error.message}</UIMessage>
          </Box>
          }
          {!!confirmed &&
          <Box mb={3}>
            <UIMessage type="success">Thanks!</UIMessage>
          </Box>

          }
          <Text title1>{mainHeading}</Text>

          <img src={organization.get('avatar')['_url']} style={imgStyle} alt={organization.get('name')}></img>

          {!confirmed &&
          <Text title2>Please click the button below to confirm</Text>
          }
          {!confirmed &&
          <form onSubmit={(e) => this.submitConfirmation(e)} method="post">
            <Button htmlButton>Confirm</Button>
          </form>
          }
        </Box>
      )
    } else {
      return (
        <Box>
          {!!error &&
          <Box mb={3}>
            <UIMessage type="error">Error: {error.message}</UIMessage>
          </Box>
          }
          {!error &&
          <Text title1>Loading...</Text>
          }
        </Box>
      )
    }
  }
}

export default VerifyPayment
