import React from 'react'
import styled from '@emotion/styled'
import Box from '../Box'

import {
  alignContent,
  alignItems,
  flexDirection,
  flexWrap,
  justifyContent
} from 'styled-system'

const FlexBox = styled(Box)`
  display: ${props => props.inline ? 'inline-flex' : 'flex'};
  ${alignContent}
  ${alignItems}
  ${flexDirection}
  ${flexWrap}
  ${justifyContent}
`

const Flex = (props) => <FlexBox {...props} />

export default Flex
