import React from 'react'
import {Route, Redirect} from 'react-router-dom'
import AccountRoutes from "../../routes/AccountRoutes";
import DonationRoutes from "./DonationRoutes";
import theme from "givapp-design/src/styles/theme";
import Flex from "givapp-design/src/components/Flex";
import styled from "@emotion/styled";


export class Donate extends React.Component {
  selectedTheme = theme('light');

  constructor() {
    super();
    this.state = {
      loading: true,
      isAdmin: false
    }
  }

  setPageId = newPageId => {
    const {pageId} = this.state

    if (pageId === newPageId) {
      return // Do nothing
    } else {
      console.log('Donate.setPageId:setState')

      return (this.setState({pageId: newPageId}))
    }
  }

  render() {

    const {error, pageId, donor, loading, isAdmin} = this.state;
    console.log('Donate', pageId);
    return (

            <DonationRoutes
              {...this.props}
              setPageId={this.setPageId}
              pageId={pageId}
              selectedTheme={this.selectedTheme}
            />)
  }
}

