import React from 'react'
import Parse from 'parse'
import Box from '../../layout/Box'

import MainHeading from '../../style/text/MainHeading'
import UIMessage from "../../style/text/UIMessage";
import {type, color} from '../../../designSystem'

import {loadStripe} from '@stripe/stripe-js';
import {
  Elements, ElementsConsumer
} from "@stripe/react-stripe-js";

import {rem} from "../../../lib/tools";
import Text from "../../style/text/Text";
import MobileCheckoutForm from "./MobileCheckoutForm";

export class MobilePaymentDetails extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      error: null,
      complete: false,
      submitted: false,
      config: null,
      mounted: false
    }
    this.onSubmit = this.onSubmit.bind(this);
    this.onError = this.onError.bind(this);
    this.onPaymentMethodCreation = this.onPaymentMethodCreation.bind(this);
  }

  handleChange = (key, e) => (
    e => this.setState({[key]: e.target.value})
  )



  componentDidMount() {
    console.log('componentDidMount')

    Parse.Cloud.run('config').then((result) => {
      this.setState({
        config: result,
        stripePromise: loadStripe(result['STRIPE_PUBLIC_KEY'])
      });
    });

    if (this.props.user)
      this.setState({
        user: this.props.user,
        mounted: true
      })
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onError(error) {
    this.setState({error: error});
  }

  /*
    async onTokenCreation(token, amount) {
      this.setState({'submitted': true});

      Parse.Cloud.run('submitUserPayment', {
        token: token,
        email: this.state.user.get('email')
      }).then((result) => {
        console.log('MobilePaymentDetails:onTokenCreation redirecting to givapp')

        //window.setTimeout(function () {
        window.location.replace('https://givapp-parse-server.herokuapp.com/back-to-givapp');
        //window.setTimeout(function () {
        //  window.location.replace('https://www.givapp.com');
        //});
        //});
      }, (error) => {
        console.log('MobilePaymentDetails:onTokenCreation error', error)

        this.setState({error: error.message});
      });


      //create new Donation
      //save Donation for user to org/subaccount
    }*/

  async onPaymentMethodCreation(paymentMethod, amount) {
    this.setState({'submitted': true});

    Parse.Cloud.run('submitUserPayment', {
      paymentMethod: paymentMethod,
      email: this.state.user.get('email')
    }).then((result) => {
      console.log('MobilePaymentDetails:onPaymentMethodCreation redirecting to givapp')

      window.location.replace('https://givapp-parse-server.herokuapp.com/back-to-givapp');

    }, (error) => {
      console.log('MobilePaymentDetails:onPaymentMethodCreation error', error)
      if (error.message) {
        this.setState({error: error});
      } else {
        this.setState({error: {message: error}});
      }
    });
  }

  async onSubmit(state) {
    console.log('MobilePaymentDetails:onSubmit')

    let promise = new Promise((resolve, reject) => {
      console.log('MobilePaymentDetails:onSubmit.Promise', this.state);
      resolve(this.state.user);
    });

    return promise;
  }


  render() {
    const {
      error,
      config,
      mounted,
      user,
      stripePromise
    } = this.state


    if (mounted && config) {
      return (

        <Box>

          {!!error &&
          <Box mb={3}>
            <UIMessage type="error">Error: {error.message}</UIMessage>
          </Box>
          }

          {!!user && !user.get('isAnonymous') &&
          <Box>
            <Box mt={rem(2)}>
              <Text>User Information</Text>
              <Text>{user.get('fullName')} - {user.get('email')}</Text>
            </Box>
          </Box>
          }

          <Elements
            stripe={stripePromise}
            {...createElementOptions()}
            fonts={[
              {'cssSrc': 'https://use.typekit.net/bln6vbi.css'}
            ]}>
            <InjectedCheckoutForm
              onSubmit={this.onSubmit}
              onError={this.onError}
              onPaymentMethodCreation={this.onPaymentMethodCreation}></InjectedCheckoutForm>
          </Elements>

        </Box>


      )
    } else {
      return (
        <Box>
          <MainHeading>Loading...</MainHeading>
        </Box>

      )
    }

  }
}

export default MobilePaymentDetails

const InjectedCheckoutForm = (props) => (
  <ElementsConsumer>
    {({stripe, elements}) => (
      <MobileCheckoutForm
        stripe={stripe}
        elements={elements}
        onSubmit={props.onSubmit}
        onError={props.onError}
        onPaymentMethodCreation={props.onPaymentMethodCreation}></MobileCheckoutForm>
    )}
  </ElementsConsumer>
);

/*STRIPE ELEMENTS*/

const createElementOptions = () => {
  return {
    style: {
      base: {
        height: rem(46),
        color: color.black,
        letterSpacing: '0.025em',
        fontFamily: type.family.default,
        '::placeholder': {
          'font-weight': type.weight.regular,
          'color': color.lightGray
        }
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };
};

