import React from 'react'
import Box from "../../../layout/Box";
import Card from "givapp-design/src/components/Card";
import Flex from "givapp-design/src/components/Flex";


const MessageCard = ({props, children}) => (<Flex
  flexDirection={'column'}
  justifyContent={'center'}
  alignItems={'center'}
  alignContent={'center'}
  {...props}
  width={[1]}
>
  <Box w={[.5]}>
    <Card>
      <Flex flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            alignContent={'center'}>
        {children}
      </Flex>
    </Card>
  </Box>
</Flex>)

export default MessageCard
