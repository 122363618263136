import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import { rem } from '../../lib/tools'
import { lightBlack3, lightWhite } from '../../tokens/color'
import * as border from '../../tokens/border'
import * as space from '../../tokens/space'
import * as typography from '../../tokens/typography'

const ControlTrack = styled.ul`
  box-sizing: border-box;
  display: inline-flex;
  height: ${space.s6};
  padding: ${rem(2)};
  cursor: pointer;
  background-color: ${({ theme }) => theme.color.gray1};
  border-radius: ${border.borderRadiusSm};
`

const ControlOption = styled.li`
  display: flex;
  align-items: center;
  padding-right: ${space.s3};
  padding-left: ${space.s3};
  font-family: ${typography.fontFamily};
  font-size: ${rem(13)};
  font-style: normal;
  font-weight: ${typography.fontWeightMedium};
  line-height: ${typography.lineHeightMultiplierMeta};
  cursor: pointer;
  color: ${props => props.active ? lightBlack3 : props.theme.color.gray5};
  background-color: ${props => props.active ? lightWhite : 'transparent'};
  border-radius: ${border.borderRadiusSm};
  ${props => !!props.active && `
    box-shadow: 0 ${rem(1)} ${rem(2)} 0 ${props.theme.color.gray2Transparent};
  `}
  ${props => !props.active && `
    &:hover, &:focus {
      color: ${props.theme.color.black2};
    }
  `}
`

const Control = ({ activeId, onClick, options }) => (
  <ControlTrack>
    {!!options && options.map(option => (
      <ControlOption
        {...{ onClick }}
        key={option.id}
        data-id={option.id}
        active={!!(activeId === option.id)}
      >
        {option.label}
      </ControlOption>
    ))}
  </ControlTrack>
)

Control.propTypes = {
  /** Id for active toggle option. */
  activeId: PropTypes.string,
  /** The onClick function is mapped to each toggle option. */
  onClick: PropTypes.func,
  /** Array of objects that represent the options to display
      in the toggle. */
  options: PropTypes.array
}

export default Control
