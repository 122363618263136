import React from 'react'
import styled from 'styled-components'

import { rem, alpha } from '../../../lib/tools'
import { dimension, type, color, border } from '../../../designSystem'

const MessageText = styled.p`
  display: block;
  padding: ${rem(dimension.d0)} ${rem(dimension.d0 + 4)};
  font-family: ${type.family.default};
  font-size: ${rem(type.size.s1)};
  font-style: ${type.style.normal};
  font-weight: ${type.weight.normal};
  line-height: ${type.lineHeight.summary};
  color: ${props =>
    !!props.type ?
      (props.type === 'success') ? color.success :
        (props.type === 'error') ? color.error :
          (props.type === 'warning') ? color.warning :
            (props.type === 'neutral') ? color.blue : color.gray
              : color.gray
  };
  background-color: ${props =>
    !!props.type ?
      (props.type === 'success') ? alpha(color.success)(0.08) :
        (props.type === 'error') ? alpha(color.error)(0.08) :
          (props.type === 'warning') ? alpha(color.warning)(0.08) :
            (props.type === 'neutral') ? alpha(color.blue)(0.08) : color.lightestGray
              : color.lightestGray
  };
  border-radius: ${rem(border.radius.rg)};

  strong {
    font-family: ${type.family.default};
    font-size: ${rem(type.size.s0)};
    font-style: ${type.style.normal};
    font-weight: ${type.weight.bold};
    letter-spacing: ${rem(1)};
    text-transform: uppercase;
  }
`

const UIMessage = ({ type, children }) => (
  <MessageText {...{type}}><span>{children}</span></MessageText>
)

export default UIMessage
