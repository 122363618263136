import React from 'react'
import { ThemeProvider } from 'emotion-theming'
import { Global, css } from '@emotion/core'
import theme from '../src/styles/theme'

class Story extends React.Component {
  render() {
    const { component, mode } = this.props

    /*
      equivalent to useTheme() in a normal component
      see Toggle.component.js for example
    */
    const thisTheme = theme(mode)

    return (
      <React.Fragment>
        <Global styles={css`
          body {
            background-color: ${(mode === 'dark') ? 'black' : 'white'};
          }
        `} />

        <ThemeProvider theme={thisTheme}>
          {component(thisTheme)}
        </ThemeProvider>
      </React.Fragment>
    )
  }
}

export default Story
