import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { space } from 'styled-system'

import * as border from '../../tokens/border'
import { lightWhite } from '../../tokens/color'
import * as spaceTokens from '../../tokens/space'
import * as typography from '../../tokens/typography'

/*
  For most cases the button will be rendered as a button element.
  Browsers have built-in mechanics (apparently) to center a button
  label vertically. However, if the button is rendered with an
  <a> tag, which happens when the `url` prop is passed, it does
  not receive that built-in help. So to compensate the display
  is changed to flex and appropriate flexbox properties are added.
*/

const StyledButton = styled.button`
  ${space}

  box-sizing: border-box;
  display: ${props =>
    props.hasUrl ?
      props.full ? 'flex' : 'inline-flex' :
    props.full ? 'block' : 'inline-block'
  };
  ${props => !!props.hasUrl && `
    align-items: center;
    justify-content: center;
  `}
  width: ${props => props.full ? '100%' : 'auto'};
  min-height: ${props =>
    props.small ? spaceTokens.s6 :
    props.large ? spaceTokens.s8 :
    spaceTokens.s7
  };
  padding-top: ${props => props.small ? spaceTokens.s2 : spaceTokens.s3};
  padding-right: ${props => props.small ? spaceTokens.s4 : spaceTokens.s5};
  padding-bottom: ${props => props.small ? spaceTokens.s2 : spaceTokens.s3};
  padding-left: ${props => props.small ? spaceTokens.s4 : spaceTokens.s5};
  font-family: ${typography.fontFamily};
  font-size: ${props =>
    props.small ? typography.fontSizeButtonLabelSmall :
    props.large ? typography.fontSizeButtonLabelLarge :
    typography.fontSizeButtonLabel
  };
  font-style: ${props => props.disabled ? 'italic' : 'normal'};
  font-weight: ${props =>
    props.disabled ? typography.fontWeightRegular :
    typography.fontWeightBold
  };
  line-height: ${props =>
    props.small ? typography.lineHeightMultiplierButtonLabelSmall :
    typography.lineHeightMultiplierButtonLabel
  };
  text-align: center;
  text-decoration: none;
  color: ${props =>
    props.outline ?
      props.disabled ? props.theme.color.gray5 :
      props.danger ? props.theme.color.negative3 :
      props.theme.color.classicBlue4 :
    lightWhite
  };
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  border-width: ${border.borderWidthThick};
  border-style: ${border.borderStyleDefault};
  border-color: ${props =>
    props.outline ?
      props.disabled ? props.theme.color.gray4 :
      props.danger ? props.theme.color.negative3 :
      props.theme.color.classicBlue3 :
    'transparent'
  };
  background-color: ${props =>
    props.outline ? 'transparent' :
    props.disabled ? props.theme.color.gray4 :
    props.danger ? props.theme.color.negative3 :
    props.theme.color.classicBlue3
  };
  border-radius: ${props =>
    props.small ? border.borderRadiusSm :
    props.large ? border.borderRadiusLg :
    border.borderRadiusMd
  };
  -webkit-appearance: none;
  outline: none;

  &:hover, &:focus {
    color: ${props =>
      props.outline ?
        props.disabled ? props.theme.color.gray5 :
        props.danger ? props.theme.color.negative4 :
        props.theme.color.classicBlue5 :
      props.disabled ? lightWhite :
      props.theme.color.white
    };
    border-color: ${props =>
      props.outline ?
        props.disabled ? props.theme.color.gray4 :
        props.danger ? props.theme.color.negative4 :
        props.theme.color.classicBlue4 :
      'transparent'
    };
    background-color: ${props =>
      props.outline ? 'transparent' :
      props.disabled ? props.theme.color.gray4 :
      props.danger ? props.theme.color.negative4 :
      props.theme.color.classicBlue4
    };
  }
`

const Button = ({ children, danger, full, large, outline, small, space, url, ...rest }) => {
  const RenderButton = url ?
    StyledButton.withComponent('a') :
    StyledButton.withComponent('button')

  return (
    <RenderButton
      {...{ danger, full, large, outline, small, space }}
      hasUrl={!!url}
      href={url}
      {...rest}
    >
      {url ?
        <span>{children}</span> :
        children
      }
    </RenderButton>
  )
}

Button.propTypes = {
  /** Paints button with a warning style no matter what type. */
  danger: PropTypes.bool,
  /** Changes the display property of the button to take up the full width of its container. */
  full: PropTypes.bool,
  /** Sets the button to large style */
  large: PropTypes.bool,
  /** Sets the button to outline style */
  outline: PropTypes.bool,
  /** Sets the button to small style */
  small: PropTypes.bool,
  /** If the url prop is present, the button renders with an `<a>` tag rather than a `<button>` tag. */
  url: PropTypes.string
}

Button.defaultProps = {
  danger: false,
  full: false,
  large: false,
  outline: false,
  small: false
}

export default Button
