import React from 'react'
import Box from '../../layout/Box'

import MainHeading from '../../style/text/MainHeading'
import SubHeading from "../../style/text/SubHeading";
import {ManagerComponent} from "./ManagerComponent";
import Parse from "parse";
import Hr from "../../style/elements/Hr";
import UIMessage from "../../style/text/UIMessage";
import {maxCharCount} from "../../../lib/constants";
import styled from "styled-components";
import {rem} from "../../../lib/tools";
import * as _ from "lodash";
import Label from "givapp-design/src/components/Label";
import {color} from "../../../designSystem";
import Button from "givapp-design/src/components/Button";
import Flex from "givapp-design/src/components/Flex";
import Text from "givapp-design/src/components/Text";
import Input from "givapp-design/src/components/Input";


export class Messages extends ManagerComponent {


  constructor() {
    super()

    this.state = _.extend({
      messageToFansOfOrganization: '',
      messageToDonorsOfOrganization: '',
      messageToBothDonorsFansOfOrganization: '',
      sentPushNotification_ToFansOfOrganization: false,
      sentPushNotification_ToDonorsOfOrganization: false,
      sentPushNotification_ToBothDonorsFansOfOrganization: false
    }, this.state);

    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    super.componentDidMount();

    // Set active page state
    const {setPageId} = this.props
    setPageId('notifications')

    window.setTimeout(() => {
      this.state.loading = false;
      this.setState(this.state);
    })
  }

  handleChange = (key, e) => (
    e => this.setState({[key]: e.target.value})
  )

  sendPushNotification_ToDonorsOfOrganization(event) {
    event.preventDefault()

    const {messageToDonorsOfOrganization} = this.state

    Parse.Cloud.run('sendPushNotification_ToDonorsOfOrganization', {
      message: messageToDonorsOfOrganization
    }).then((result) => {
      this.setState({
        sentPushNotification_ToDonorsOfOrganization: result.sentPushNotification,
        messageToDonorsOfOrganization: ''
      })
    })
  }

  sendPushNotification_ToFansOfOrganization(event) {
    event.preventDefault()

    const {messageToFansOfOrganization} = this.state

    Parse.Cloud.run('sendPushNotification_ToFansOfOrganization', {
      message: messageToFansOfOrganization
    }).then((result) => {
      this.setState({
        sentPushNotification_ToFansOfOrganization: result.sentPushNotification,
        messageToFansOfOrganization: ''
      })
    })
  }

  sendPushNotification_ToBothDonorsFansOfOrganization(event) {
    event.preventDefault()
    debugger;
    const {messageToBothDonorsFansOfOrganization} = this.state

    Parse.Cloud.run('sendPushNotification_ToBothDonorsFansOfOrganization', {
      message: messageToBothDonorsFansOfOrganization
    }).then((result) => {
      this.setState({
        sentPushNotification_ToBothDonorsFansOfOrganization: result.sentPushNotification,
        messageToBothDonorsFansOfOrganization: ''
      })
    })
  }


  render() {
    const pageTitle = 'Send Push Notifications'

    const {
      messageToFansOfOrganization,
      messageToDonorsOfOrganization,
      messageToBothDonorsFansOfOrganization,
      sentPushNotification_ToFansOfOrganization,
      sentPushNotification_ToDonorsOfOrganization,
      sentPushNotification_ToBothDonorsFansOfOrganization
    } = this.state

    const {organization, subaccounts, loading} = this.state;

    console.log('loading', loading);

    return (
      <Box m={4}>
        {!!loading && <Box>Loading...</Box>}
        {!!!loading &&
        <Box>
          <MainHeading>Messages</MainHeading>
          <SubHeading>org:{organization.id}</SubHeading>
          {!!subaccounts &&
          <SubHeading>subs:{subaccounts.length}</SubHeading>
          }


          <Box mt={3}>
            <Hr/>
          </Box>

          <Box mt={3}>
            <UIMessage type="warning">
              <strong>Note:</strong> This page will send push notifications directly to the user&#8217;s device. Please
              use responsibly!
            </UIMessage>
          </Box>


          <PushBox mt={5}>
            <form onSubmit={(e) => this.sendPushNotification_ToDonorsOfOrganization(e)} method="post">
              <Label>Send to <Standout textColor={color.pink}>donors</Standout></Label>

              <Flex mt={1} alignItems={'center'}>
                <Input
                  maxLength={maxCharCount}
                  placeholder="Type message here"
                  value={messageToDonorsOfOrganization}
                  onChange={this.handleChange('messageToDonorsOfOrganization')}
                />

                <Box ml={1}>
                  <Button htmlButton>Send</Button>
                </Box>
              </Flex>

              {!!sentPushNotification_ToDonorsOfOrganization &&
              <Box mt={1}>
                <UIMessage type="success">
                  Push notification sent!
                </UIMessage>
              </Box>
              }

              <Box mt={1}>
                <Text minor><em>This will send a push notification to anyone who has donated to your
                  organization.</em></Text>
              </Box>
            </form>
          </PushBox>

          <PushBox mt={5}>
            <form onSubmit={(e) => this.sendPushNotification_ToFansOfOrganization(e)} method="post">
              <Label>Send to <Standout textColor={color.pink}>fans</Standout></Label>

              <Flex mt={1} alignItems={'center'}>
                <Input
                  maxLength={maxCharCount}
                  placeholder="Type message here"
                  value={messageToFansOfOrganization}
                  onChange={this.handleChange('messageToFansOfOrganization')}
                />

                <Box ml={1}>
                  <Button htmlButton>Send</Button>
                </Box>
              </Flex>

              {!!sentPushNotification_ToFansOfOrganization &&
              <Box mt={1}>
                <UIMessage type="success">
                  Push notification sent!
                </UIMessage>
              </Box>
              }

              <Box mt={1}>
                <Text minor><em>This will send a push notification to anyone who follows your organization.</em></Text>
              </Box>
            </form>
          </PushBox>

          <PushBox mt={5}>
            <form onSubmit={(e) => this.sendPushNotification_ToBothDonorsFansOfOrganization(e)} method="post">
              <Label>Send to <Standout textColor={color.pink}>both donors & fans</Standout></Label>

              <Flex mt={1} alignItems={'center'}>
                <Input
                  maxLength={maxCharCount}
                  placeholder="Type message here"
                  value={messageToBothDonorsFansOfOrganization}
                  onChange={this.handleChange('messageToBothDonorsFansOfOrganization')}
                />

                <Box ml={1}>
                  <Button htmlButton>Send</Button>
                </Box>
              </Flex>

              {!!sentPushNotification_ToBothDonorsFansOfOrganization &&
              <Box mt={1}>
                <UIMessage type="success">
                  Push notification sent!
                </UIMessage>
              </Box>
              }

              <Box mt={1}>
                <Text minor><em>This will send a push notification to anyone who either has donated to or follows your
                  organization.</em></Text>
              </Box>
            </form>
          </PushBox>
        </Box>
        }
      </Box>
    )
  }


}


const PushBox = styled(Box)`
  max-width: ${rem(600)};
`

const Standout = styled.strong`
  color: ${props => !!props.textColor ? props.textColor : 'inherit'};
`

export default Messages
