import chroma from 'chroma-js'


/*
  Converts unitless (pixel) value to `rem`, assumes base is
  16 unless different value is given.
*/
export const rem = (px, base) => `${px / (base ? base : 16)}rem`


/*
  Add transparency to colors.
  Usage (e.g. 50% black): alpha(color.black)(0.5)
  Via http://jxnblk.com/writing/posts/patterns-for-style-composition-in-react/#npm-modules
*/
export const alpha = (color) => (a) => chroma(color).alpha(a).css()


/*
  Sort array of objects by `orderIndex` property of each object
  See: http://stackoverflow.com/a/7410400/1241287
*/
export const sortByIndex = function(arr) {
  const sortArray = []

  // copy items to array for sorting
  for (var key in arr) {
    arr[key].key = key
    sortArray.push(arr[key])
  }

  // sort items
  return sortArray.sort(function(a, b) {
    return a.orderIndex - b.orderIndex
  })
}


/*
  Compare two values
*/
export const checkMatch = (a, b) => (a === b) ? true : false


/*
  Parse JSON
*/
export const parseJSON = function(data) {
  let json = null
  try {
    json = JSON.parse(data)
  }
  catch (e) {
    json = data
  }
  return json
}


/*
  Export HTML table to CSV file
*/
export const exportTableToCSV = (id, filename) => {
  const csv = []
  const rows = document.querySelectorAll(`#${id} tr`)

  for (var i = 0; i < rows.length; i++) {
    var row = [], cols = rows[i].querySelectorAll("td, th")

    for (var j = 0; j < cols.length; j++) {
      row.push(cols[j].innerText)
    }

    csv.push(row.join(","));
  }

  // Download CSV
  downloadCSV(csv.join("\n"), filename)
}


/*
  Download CSV function
*/
export const downloadCSV = (csv, filename) => {
  let csvFile = ''
  let downloadLink = ''

  // CSV FILE
  csvFile = new Blob([csv], {type: "text/csv"})

  // Download link
  downloadLink = document.createElement("a")

  // File name
  downloadLink.download = filename

  // We have to create a link to the file
  downloadLink.href = window.URL.createObjectURL(csvFile)

  // Make sure that the link is not displayed
  downloadLink.style.display = "none"

  // Add the link to your DOM
  document.body.appendChild(downloadLink)

  // Initiate download
  downloadLink.click()
}

export default {
  rem,
  alpha,
  sortByIndex,
  checkMatch,
  parseJSON,
  exportTableToCSV,
  downloadCSV
}
