import React from 'react'
import styled from 'styled-components'

const StyledTable = styled.table`
  table-layout: ${props => !!props.layout ? props.layout : 'fixed'};
  width: 100%;
  font-size:14px;
`

const Table = ({id, className, children, ...rest}) => (
  <StyledTable {...{id, className, ...rest}}>{children}</StyledTable>
)

export default Table
