import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { useTheme } from 'emotion-theming'

import Box from '../Box'
import Text from '../Text'
import { IconInfo } from '../icons'

import { rem } from '../../lib/tools'
import * as border from '../../tokens/border'
import * as breakpoints from '../../tokens/breakpoints'
import * as typography from '../../tokens/typography'

const TooltipArea = styled.div`
  display: inline-block;
  position: relative;
  /* Modal z-index is 1000 & 1001 */
  z-index: 999;
`

const TooltipPanel = styled(Box)`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: ${border.borderRadiusLg};
  background-color: ${({ theme }) => theme.color.white};
  box-shadow:
    0 0 0 ${border.borderWidth} ${({ theme }) => theme.color.gray2Transparent}, /* border */
    0 ${rem(2)} ${rem(6)} 0 ${({ theme }) => theme.color.gray2Transparent} /* shadow */
  ;

  @media (min-width: ${rem(breakpoints.breakpointMd)}) {
    position: absolute;
    left: ${props => rem((props.iconSize * 1) + (4 * 1))};
    top: ${rem(-8)};
    transform: none;
  }

  ${props => !props.isTooltipActive && `
    /*
      Hide text visually but not from screen readers.
      https://a11yproject.com/posts/how-to-hide-content/
    */
    height: 1px;
    width: 1px;
    position: absolute;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap; /* added line */
  `}
`

const TooltipPanelMobileScrim = styled.div`
  /* mobile-only */
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: ${({ theme }) => theme.color.gray4Transparent};
  z-index: 998;

  @media (min-width: ${rem(breakpoints.breakpointMd)}) {
    display: none;
  }
`

const Tooltip = ({ content, iconColor, iconSize, isTooltipActive, onClick, ...rest }) => {
  const theme = useTheme()
  const thisIconColor = iconColor || theme.color.gray5
  const thisIconSize = iconSize || 18

  return (
    <React.Fragment>
      <TooltipArea {...{ onClick }}>
        <IconInfo size={thisIconSize} hoverColor={theme.color.black3} color={isTooltipActive ? theme.color.black3 : thisIconColor} />
        <TooltipPanel
          {...{ isTooltipActive }}
          iconSize={thisIconSize}
          width={rem(260)}
          py={rem(11)}
          px={rem(14)}
        >
          <Text fontSize={typography.fontSizeMeta} lineHeight={typography.lineHeightMultiplierTextSmall} textColor={theme.color.black2}>{content}</Text>
        </TooltipPanel>
      </TooltipArea>
      {!!isTooltipActive && <TooltipPanelMobileScrim  {...{ onClick }} />}
    </React.Fragment>
  )
}

Tooltip.propTypes = {
  /** Prop to turn the switch on or off. */
  isSwitchActive: PropTypes.bool,
  /** If present, places the label next to the toggle.
      The label style changes to match the active switch. */
  label: PropTypes.string,
  /** The onClick function is assigned to the TooltipSwitch entity */
  onClick: PropTypes.func
}

Tooltip.defaultProps = {
  isSwitchActive: false
}

export default Tooltip
