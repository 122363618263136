import React from 'react'
import { Link } from 'react-router-dom'

import Box from '../layout/Box'
import MainHeading from '../style/text/MainHeading'
import SubHeading from '../style/text/SubHeading'

const NotFound = () => (
  <Box>
    <MainHeading>Page Not Found</MainHeading>

    <Box mt={3}>
      <SubHeading>Try starting from the <Link to="/">main page</Link>.</SubHeading>
    </Box>
  </Box>
)

export default NotFound
