import React from 'react'
import { useTheme } from 'emotion-theming'

export const IconNote = ({ color, large, size }) => {
  const theme = useTheme()
  const iconColor = color || theme.color.black3

  return large ?
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M23.8333 13.8333L18.1667 8.16667H8.16666V23.8333H23.8333V13.8333ZM16.6667 6.66667H23.8333H25.3333V8.16667V15.3333V23.8333V25.3333H23.8333H8.16666H6.66666V23.8333V8.16667V6.66667H8.16666H16.6667Z" fill={iconColor} />
      <rect x="10.6667" y="19.3333" width="9.33333" height="2" fill={iconColor} />
      <rect x="10.6667" y="15.3333" width="10.6667" height="2" fill={iconColor} />
      <rect x="10.6667" y="11.3333" width="6.66667" height="2" fill={iconColor} />
    </svg> :
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M17.5 10L14 6.5H6.5V17.5H17.5V10ZM12.5 5H17.5H19V6.5V11.5V17.5V19H17.5H6.5H5V17.5V6.5V5H6.5H12.5Z" fill={iconColor} />
      <rect x="8" y="14.5" width="7" height="1.5" fill={iconColor} />
      <rect x="8" y="11.5" width="8" height="1.5" fill={iconColor} />
      <rect x="8" y="8.5" width="5" height="1.5" fill={iconColor} />
    </svg>
}

IconNote.defaultProps = {
  size: 24
}
