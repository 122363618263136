import React from 'react'
import { useTheme } from 'emotion-theming'

export const IconSearch = ({ color, large, size }) => {
  const theme = useTheme()
  const iconColor = color || theme.color.black3

  return large ?
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.7487 19.5666L21.9813 19.2348C22.9117 17.775 23.4434 16.0829 23.4434 14.3577C23.4102 9.38102 19.3562 5.33333 14.3718 5.33333C9.38733 5.33333 5.33333 9.38102 5.33333 14.3577C5.33333 19.3344 9.38733 23.3821 14.3718 23.3821C16.0997 23.3821 17.7944 22.8844 19.2565 21.9554L19.5888 21.7232L24.5732 26.6998L26.6667 24.5101L21.7487 19.5666ZM14.3718 21.3582C10.5171 21.3582 7.36033 18.2063 7.36033 14.3577C7.36033 10.5091 10.5171 7.35717 14.3718 7.35717C18.2264 7.35717 21.3832 10.5091 21.3832 14.3577C21.3832 18.2063 18.2264 21.3582 14.3718 21.3582Z" fill={iconColor} />
    </svg> :
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.3115 14.675L16.486 14.4261C17.1838 13.3313 17.5826 12.0622 17.5826 10.7683C17.5576 7.03577 14.5171 4 10.7788 4C7.0405 4 4 7.03577 4 10.7683C4 14.5008 7.0405 17.5365 10.7788 17.5365C12.0748 17.5365 13.3458 17.1633 14.4424 16.4666L14.6916 16.2924L18.4299 20.0249L20 18.3826L16.3115 14.675ZM10.7788 16.0187C7.88785 16.0187 5.52025 13.6547 5.52025 10.7683C5.52025 7.8818 7.88785 5.51788 10.7788 5.51788C13.6698 5.51788 16.0374 7.8818 16.0374 10.7683C16.0374 13.6547 13.6698 16.0187 10.7788 16.0187Z" fill={iconColor} />
  </svg>
}

IconSearch.defaultProps = {
  size: 24
}
