import React from 'react'
import styled from 'styled-components'

import { rem } from '../../../lib/tools'
import { type, color, border, dimension } from '../../../designSystem'

const StyledInput = styled.input`
  box-sizing: border-box;
  display: block;
  width: 100%;
  height: ${rem(46)};
  padding-right: ${rem(dimension.d0)};
  padding-left: ${rem(dimension.d0)};
  font-family: ${type.family.default};
  font-size: ${rem(type.size.s1)};
  font-weight: ${type.weight.regular};
  line-height: ${rem(46)};
  color: ${color.black};
  border: 0;
  background-color: ${color.lightestGray};
  box-shadow: none;
  border-radius: ${rem(border.radius.sm)};
  -webkit-appearance: none;

  &:active, &:focus {
    background-color: ${color.white};
    box-shadow: inset 0 0 ${rem(2)} 0 ${color.lightGray};
    outline: none;
  }

  &::-webkit-placeholder,
  &::placeholder {
    font-weight: ${type.weight.regular};
    color: ${color.lightGray};
  }

  &:disabled {
    cursor: not-allowed;
    color: ${color.lightGray};
    box-shadow: none;
    background-color: ${color.lightestGray};
  }
  &:disabled::-webkit-placeholder,
  &:disabled::placeholder {
    font-family: ${type.family.default};
    font-style: ${type.style.italic};
    color: ${color.lightGray};
  }
`

const Input = ({ placeholder, ...rest }) => (
  <StyledInput
    {...{placeholder}}
    {...rest}
  />
)

export default Input
