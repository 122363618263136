import Parse from './parse'

import {v1 as uuidv1} from 'uuid';

export const login = (email, password) => Parse.User.logIn(email, password)
export const signOut = () => {
  console.log('signOut');
  return Parse.User.logOut()
}

export const becomeUser = (token) => Parse.User.become(token)
export const getCurrentUser = () => {
  let promise = new Promise((resolve, reject) => {

    let user = Parse.User.current()
    console.log('auth.getCurrentUser:begin', user);
    let authData = {
      "authData": {
        "id": uuidv1()
      }
    };

    if (user === null) {
      user = new Parse.User();
      user.set('isAnonymous', true);
      user._linkWith("anonymous", authData).then(function (user) {
        console.log('auth.Created user is anonymous:', user.get('isAnonymous'));
        resolve(user);
      });
    } else {
      console.log('auth.Returning user is anonymous:', user.get('isAnonymous'));
      //user.set('isAnonymous', false);
      resolve(user);
    }

    //this.setState({user: user}) {}

    console.log("auth.getCurrentUser:end", user);
  });
  return promise;
}
