import React from 'react'
import Box from '../../../layout/Box'

import {ManagerComponent} from "../ManagerComponent";

import * as _ from 'lodash';

import Flex from "givapp-design/src/components/Flex";
import styled from "@emotion/styled";
import * as space from "givapp-design/src/tokens/space";
import * as typography from "givapp-design/src/tokens/typography";
import * as border from "givapp-design/src/tokens/border";
import LoadingCard from "../components/LoadingCard";
import {editDonorOrganization, fetchDonorOrganization} from "../ManagerApi";
import {Button, Label, Text, Textarea} from "givapp-design";
import SubHeading from "../../../style/text/SubHeading";
import Input from "givapp-design/src/components/Input";
import {rem} from "givapp-design/src/lib/tools";
import * as spaceTokens from "givapp-design/src/tokens/space";
import {order} from "styled-system";
import {useTheme} from "emotion-theming";
import {lineHeightMeta} from "givapp-design/src/tokens/typography";

const TableControls = styled(Flex)`
  background-color: #F3F5F5;
  border-top: 1px solid #DCE0E2;
`


const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};


const StyledTextArea = styled.textarea`
  width: calc(100% - ${rem(28)});
  height: 100px;
  padding: ${props =>
          props.isSearch ? spaceTokens.s6 :
                  props.small ? spaceTokens.s2 :
                          props.large ? spaceTokens.s4 :
                                  spaceTokens.s3
  };

  ${order}
  ${space}

  border-width: ${border.borderWidth};
  border-style: ${border.borderStyleDefault};
  border-color: ${props =>
          props.disabled ? props.theme.color.gray1 :
                  props.error ? props.theme.color.negative3 :
                          props.theme.color.gray3
  };
  background-color: ${props =>
          props.disabled ? props.theme.color.gray1 :
                  props.theme.color.white
  };
  border-radius: ${props => props.small ? border.borderRadiusSm : border.borderRadiusMd};
  box-shadow: none;
  outline: none;
  -webkit-appearance: none;

  &:active, &:focus {
    border-color: ${props =>
            props.disabled ? props.theme.color.gray1 :
                    props.error ? props.theme.color.negative4 :
                            props.theme.color.classicBlue3
    };
    background-color: ${props =>
            props.disabled ? props.theme.color.gray1 :
                    props.theme.color.white
    };

  }

  &::-webkit-placeholder,
  &::placeholder {
    font-family: ${typography.fontFamily};
    font-weight: ${typography.fontWeightRegular};
    color: ${props => props.error ? props.theme.color.negative3 : props.theme.color.gray5};
  }
`


const StyledLabel = ({children}) => {
  const theme = useTheme();
  return (
    <Label small={false} textColor={theme.color.black3}
           lineHeight={typography.lineHeightMeta}
           fontSize={typography.fontSizeSubtitle}
           children={children}></Label>)
}

const DateRangeElement = styled(Box)`
  /* Stripe Input Helper */

  .react-daterange-picker {
    width: 100%;
    height: ${space.s6};
    font-family: ${typography.fontFamily};
    background-color: ${({theme}) => theme.color.white};
    color: ${({theme}) => theme.color.black1};

    .react-daterange-picker__wrapper {
      border-width: ${border.borderWidth};
      border-style: ${border.borderStyleDefault};
      border-color: ${({theme}) => theme.color.gray3};
      border-radius: ${border.borderRadiusSm};

      .react-daterange-picker__inputGroup {
        text-align: center;
      }
    }


    .react-calendar {
      border-width: ${border.borderWidth};
      border-style: ${border.borderStyleDefault};
      border-color: ${({theme}) => theme.color.gray3};

      border-radius: ${border.borderRadiusSm};

      .react-calendar__tile--active {
        background-color: ${({theme}) => theme.color.classicBlue3};
      }

      .react-calendar__tile--now {
        background-color: ${({theme}) => theme.color.gray3};

      }
    }

    &.react-daterange-picker--open {
      .react-daterange-picker__wrapper {
        color: ${({theme}) => theme.color.darkGray5};
        border-color: ${({theme}) => theme.color.classicBlue3};
      }
    }
  }
`

export class Edit extends ManagerComponent {
  constructor() {

    super();
  }

  componentDidMount() {
    super.componentDidMount();
    console.log('Edit.componentDidMount', this.props.donor.id);
    // Set active page state
    //const {setPageId} = this.props
    //setPageId('editDonor');

    console.log('Edit.componentDidMount:loading', this.state.loading);
    this.setState(_.extend({
      loading: true,
      donorId: this.props.donor.id,
      donor: this.props.donor
    }, this.state), () => {
      this.setState({
        loading: false
      })
      this.refresh();
    });
  }

  refresh() {
    fetchDonorOrganization(this.state.organization.id, this.state.donorId, {}).then((result) => {

      if (result.get('secondaryPhone') == null) {
        result.set('secondaryPhone', this.state.donor.get('phoneNumber'))
      }
      if (result.get('secondaryEmail') == null) {
        result.set('secondaryEmail', this.state.donor.get('email'))
      }

      this.setState({
        donorOrganization: result
      });
    })
  }

  handleChange = key => {
    return e => {
      debugger;
      this.state.donorOrganization.set(key, e.target.value);
      this.setState({
        donorOrganization: this.state.donorOrganization
      })
    }
  }

  saveDonorOrganization() {
    editDonorOrganization(this.state.organization.id, this.state.donor.id,
      this.state.donorOrganization.get('secondaryPhone'),
      this.state.donorOrganization.get('secondaryEmail'),
      this.state.donorOrganization.get('secondaryAddress'),
      this.state.donorOrganization.get('spouse')
    ).then(r => {
      console.log('saved')
    });
  }

  render() {
    const {
      loading,
      donor,
      donorOrganization
    } = this.state;

    return (

      <Box mt={1}>
        {!!loading == true &&
          <LoadingCard>Loading...</LoadingCard>
        }
        {!loading == true && donorOrganization && donor &&
          <Flex>
            <Box w={[.5]}>
              <Box>
                <StyledLabel>Email</StyledLabel>
                <Text ml={2} italic>{donor.get('username')}</Text>
              </Box>
              <Box>
                <StyledLabel>Phone</StyledLabel>
                {!!donor.get('phoneNumber') &&
                  <Text ml={2}>{donor.get('phoneNumber')}</Text>
                }
                {!donor.get('phoneNumber') &&
                  <Text ml={2} italic>Not provided</Text>
                }
              </Box>
              <Box>
                <StyledLabel>Address</StyledLabel>
                {!!donor.get('primaryAddress') &&
                  <Text ml={2} italic>{donor.get('primaryAddress')}</Text>
                }
                {!donor.get('primaryAddress') &&
                  <Text ml={2} italic>Not provided</Text>
                }
              </Box>
            </Box>

            <Box w={[.5]}>
              <Box>
                <StyledLabel>Email</StyledLabel>
                <Input
                  required
                  id="secondaryEmail"
                  name="secondaryEmail"
                  type="email"
                  placeholder="Email"
                  value={donorOrganization.get('secondaryEmail')}
                  onChange={this.handleChange('secondaryEmail')}
                />
              </Box>
              <Box mt={2}>
                <StyledLabel>Phone</StyledLabel>
                <Input
                  required
                  id="secondaryPhone"
                  name="secondaryPhone"
                  type="phoneNumber"
                  placeholder="###-###-####"
                  value={donorOrganization.get('secondaryPhone')}
                  onChange={this.handleChange('secondaryPhone')}
                />
              </Box>
              <Box mt={2}>
                <StyledLabel>Spouse Name</StyledLabel>
                <Input
                  required
                  id="spouse"
                  name="spouse"
                  type="text"
                  placeholder=""
                  value={donorOrganization.get('spouse')}
                  onChange={this.handleChange('spouse')}
                />
              </Box>
              <Box mt={2}>
                <Button onClick={(e) => this.saveDonorOrganization(e)}>Save</Button>
              </Box>
            </Box>
          </Flex>
        }

      </Box>

    )
  }
}

export default Edit
