import React from 'react'
import styled from '@emotion/styled'
import {useTheme} from 'emotion-theming'

import {Box, Flex, Text, border} from 'givapp-design'
import {rem} from '../lib/tools'
import * as typography from "givapp-design/src/tokens/typography";

const StyledFooter = styled(Flex)`
  width: 100%;
  height: ${rem(110)};
  border-top: ${border.borderWidth} ${border.borderStyleDefault} ${({theme}) => theme.color.gray1};
`

const StyledA = styled.a`
  font-family: ${typography.fontFamily};
  text-decoration: none;

  margin: ${rem(0)} ${rem(4)};
  
  color: ${({theme}) => theme.color.gray5};

  &:visited {
    color: ${({theme}) => theme.color.gray5};
  }

  &:hover, &:focus, &:active {
    color: ${({theme}) => theme.color.gray4};
  }
  
  font-size: ${typography.fontSizeMeta};
  font-weight: ${typography.fontWeightNormal};
  line-height: ${typography.lineHeightMultiplierTextSmall};
`

const Footer = ({children}) => {
  const theme = useTheme()

  return (
    <StyledFooter mt={'auto'} pt={6} justifyContent={'space-between'}>
      <Box ml={216}>
        <Text meta textColor={theme.color.black2}>Copyright &copy; {(new Date().getFullYear())}, GivApp LLC.</Text>
        <Text meta textColor={theme.color.black2}>All rights reserved.</Text>
      </Box>
      <Flex flexDirection={'row'} flexWrap={'nowrap'} mr={10}>
        <StyledA href={'https://givapp.com/terms/'}>Terms & Conditions</StyledA>
        <StyledA href={'https://givapp.com/privacy/'}>Privacy Policy</StyledA>
        <StyledA href={'mailto:support@givapp.com'}>Support</StyledA>
      </Flex>
    </StyledFooter>
  )
}

export default Footer
