import React from 'react'
import { useTheme } from 'emotion-theming'

export const IconDown = ({ color, large, size }) => {
  const theme = useTheme()
  const iconColor = color || theme.color.black3

  return large ?
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.2745 12.4945C23.339 12.4085 23.3533 12.2867 23.3032 12.172C23.253 12.0645 23.1527 12 23.0308 12H8.96919C8.84735 12 8.74701 12.0645 8.69684 12.172C8.64667 12.2867 8.661 12.4085 8.72551 12.4945L15.7635 21.0519C15.8137 21.1379 15.914 21.1738 16 21.1738C16.086 21.1738 16.172 21.1379 16.2365 21.0519L23.2745 12.4945Z" fill={iconColor} />
    </svg> :
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.4559 9.37089C17.5043 9.30639 17.515 9.21501 17.4774 9.12901C17.4397 9.04838 17.3645 9 17.2731 9H6.72689C6.63551 9 6.56025 9.04838 6.52263 9.12901C6.485 9.21501 6.49575 9.30639 6.54413 9.37089L11.8226 15.7889C11.8602 15.8534 11.9355 15.8803 12 15.8803C12.0645 15.8803 12.129 15.8534 12.1774 15.7889L17.4559 9.37089Z" fill={iconColor} />
    </svg>
}

IconDown.defaultProps = {
  size: 24
}
