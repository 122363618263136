import React from 'react'
import { useTheme } from 'emotion-theming'

export const IconXThin = ({ color, large, size }) => {
  const theme = useTheme()
  const iconColor = color || theme.color.black3

  return large ?
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M14.7112 15.7071L7.66666 22.7516L9.08087 24.1658L16.1254 17.1213L23.2508 24.2467L24.665 22.8325L17.5396 15.7071L24.1658 9.08087L22.7516 7.66666L16.1254 14.2929L9.58006 7.74753L8.16585 9.16174L14.7112 15.7071Z" fill={iconColor} />
    </svg> :
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.0334 11.7803L5.75 17.0637L6.81066 18.1244L12.094 12.841L17.4381 18.185L18.4987 17.1244L13.1547 11.7803L18.1244 6.81066L17.0637 5.75L12.094 10.7197L7.18504 5.81066L6.12438 6.87132L11.0334 11.7803Z" fill={iconColor} />
    </svg>
}

IconXThin.defaultProps = {
  size: 24
}
