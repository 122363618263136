import React from 'react'
import { components } from 'react-select'
import { useTheme } from 'emotion-theming'
import { IconDown, IconSearch } from '../../icons'

const { DropdownIndicator } = components

const setIconColor = (active, error, theme) => {
  let iconColor = theme.color.gray4

  if (error) {
    iconColor = theme.color.negative3
    if (active) iconColor = theme.color.negative4
  }
  else {
    if (active) iconColor = theme.color.black1
  }

  return iconColor
}

const CustomDropdownIndicator = (props) => {
  const { error, search, small } = props.selectProps
  const theme = useTheme()

  const RenderIcon = search ? IconSearch : IconDown

  return (
    <DropdownIndicator {...props}>
      <RenderIcon
        size={small ? 20 : 24}
        color={setIconColor(!!(props.isActive || props.isFocused), error, theme)}
      />
    </DropdownIndicator>
  )
}

export default CustomDropdownIndicator
