import styled from 'styled-components'

import { rem } from '../../../lib/tools'
import { type, color } from '../../../designSystem'

const MainHeading = styled.h1`
  display: block;
  font-family: ${type.family.display};
  font-size: ${rem(type.size.s2)};
  font-weight: ${type.weight.bold};
  line-height: ${type.lineHeight.headline};
  color: ${props => !!props.textColor ? props.textColor : color.black};
`

export default MainHeading
