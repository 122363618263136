export const fontFamily = 'GivAppDMSans-Web, DM Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif'
export const fontSizeMeta = '0.875rem'
export const fontSizeTextSmall = '0.9375rem'
export const fontSizeText = '1rem'
export const fontSizeTextLarge = '1.125rem'
export const fontSizeTextLabelSmall = '0.9375rem'
export const fontSizeTextLabel = '1rem'
export const fontSizeButtonLabelSmall = '0.9375rem'
export const fontSizeButtonLabel = '1.0625rem'
export const fontSizeButtonLabelLarge = '1.125rem'
export const fontSizeTableHead = '0.8125rem'
export const fontSizeTableData = '1rem'
export const fontSizeSubtitle = '1.0625rem'
export const fontSizeTitle1 = '1.375rem'
export const fontSizeTitle2 = '1.625rem'
export const fontSizeMarketingTitle = '2.25rem'
export const fontWeightRegular = 400
export const fontWeightNormal = 400
export const fontWeightMedium = 500
export const fontWeightBold = 700
export const fontWeightBlack = 800
export const lineHeightMeta = '1.125rem'
export const lineHeightTextSmall = '1.3125rem'
export const lineHeightText = '1.4375rem'
export const lineHeightTextLarge = '1.625rem'
export const lineHeightTextLabelSmall = '1.125rem'
export const lineHeightTextLabel = '1.1875rem'
export const lineHeightButtonLabelSmall = '1.1875rem'
export const lineHeightButtonLabel = '1.1875rem'
export const lineHeightButtonLabelLarge = '1.25rem'
export const lineHeightTableHead = '1.0625rem'
export const lineHeightTableData = '1.1875rem'
export const lineHeightSubtitle = '1.3125rem'
export const lineHeightTitle1 = '1.625rem'
export const lineHeightTitle2 = '1.875rem'
export const lineHeightMarketingTitle = '2.5rem'
export const lineHeightButtonLabelSmallDisabled = '1.1875rem'
export const lineHeightButtonLabelDisabled = '1.1875rem'
export const lineHeightButtonLabelLargeDisabled = '1.25rem'
export const lineHeightMultiplierMeta = 1.2857
export const lineHeightMultiplierTextSmall = 1.4
export const lineHeightMultiplierText = 1.4375
export const lineHeightMultiplierTextLarge = 1.4444
export const lineHeightMultiplierTextLabelSmall = 1.2
export const lineHeightMultiplierTextLabel = 1.1875
export const lineHeightMultiplierButtonLabelSmall = 1.2667
export const lineHeightMultiplierButtonLabel = 1.1176
export const lineHeightMultiplierButtonLabelLarge = 1.1111
export const lineHeightMultiplierTableHead = 1.3077
export const lineHeightMultiplierTableData = 1.1875
export const lineHeightMultiplierSubtitle = 1.2353
export const lineHeightMultiplierTitle1 = 1.1818
export const lineHeightMultiplierTitle2 = 1.1538
export const lineHeightMultiplierMarketingTitle = 1.1111
export const lineHeightMultiplierButtonLabelSmallDisabled = 1.1875
export const lineHeightMultiplierButtonLabelDisabled = 1.1176
export const lineHeightMultiplierButtonLabelLargeDisabled = 1.1111
