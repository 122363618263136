import styled from 'styled-components'

import { rem } from '../../../lib/tools'
import { type, color, dimension } from '../../../designSystem'

const TableCaption = styled.caption`
  margin-bottom: ${rem(dimension.d1)};
  font-size: ${props => !!props.alt ? rem(type.size.s0) : rem(type.size.s2)};
  font-weight: ${type.weight.bold};
  text-align: left;
  letter-spacing: ${props => !!props.alt ? rem(1) : 0};
  text-transform: ${props => !!props.alt ? 'uppercase' : 'none'};
  color: ${props => !!props.alt ? color.gray : color.darkGray};
`

export default TableCaption
