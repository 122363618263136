import React from 'react'
import styled from '@emotion/styled'
import { useTheme } from 'emotion-theming'
import { components } from 'react-select'
import { IconXThin } from '../../icons'

const { ClearIndicator } = components

const StyleIcon = styled.span`
  cursor: pointer;
  &:hover, &:focus, &:active {
    path {
      fill: ${props => props.error ? props.theme.color.negative4 : props.theme.color.black3}
    }
  }
`

const CustomClearIndicator = (props) => {
  const { error, small } = props.selectProps
  const theme = useTheme()

  return (
    <ClearIndicator {...props}>
      <StyleIcon {...{ error }}>
        <IconXThin size={small ? 20 : 24} color={error ? theme.color.negative2 : theme.color.gray4} />
      </StyleIcon>
    </ClearIndicator>
  )
}

export default CustomClearIndicator
