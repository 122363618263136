import Parse from 'parse'

// Production
//Parse.initialize('A1808486-44D2-42C2-AC64-B7510F1DE398')
//Parse.serverURL = 'https://givapp-parse-server.herokuapp.com/parse'

// Development
// Parse.initialize('B12984FD-3DCD-4060-8C50-B7ECAC1AD945')
// Parse.serverURL = 'https://givapp-parse-server-dev.herokuapp.com/parse'

// Local
console.log(process.env.NODE_ENV);
console.log(process.env.REACT_APP_ENV);
console.log(process.env.REACT_APP_APP_ID);
console.log(process.env.REACT_APP_PARSE_SERVER_URL);

Parse.initialize(process.env.REACT_APP_APP_ID);
Parse.serverURL = process.env.REACT_APP_PARSE_SERVER_URL;
//Parse.CoreManager.set("REQUEST_ATTEMPT_LIMIT", 1);
//Parse.CoreManager.set("REQUEST_HEADERS", {"test": "test"});

export default Parse
