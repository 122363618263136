import React from 'react'
import { useTheme } from 'emotion-theming'

// TODO: Not sure about that thin... make separate icon?
export const IconX = ({ color, large, size }) => {
  const theme = useTheme()
  const iconColor = color || theme.color.black3

  return large ?
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.6391 21.1128C24.1203 21.594 24.1203 22.3158 23.6391 22.797L22.797 23.6391C22.3158 24.1203 21.594 24.1203 21.1128 23.6391L15.9398 18.5865L10.8872 23.6391C10.406 24.1203 9.68421 24.1203 9.20301 23.6391L8.3609 22.797C7.8797 22.3158 7.8797 21.594 8.3609 21.1128L13.4135 16.0602L8.3609 10.8872C7.8797 10.406 7.8797 9.68421 8.3609 9.20301L9.20301 8.3609C9.68421 7.8797 10.406 7.8797 10.8872 8.3609L15.9398 13.4135L20.9925 8.3609C21.4737 7.8797 22.1955 7.8797 22.6767 8.3609L23.6391 9.20301C24.1203 9.68421 24.1203 10.406 23.6391 10.8872L18.5865 15.9398L23.6391 21.1128Z" fill={iconColor} />
    </svg> :
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.7293 15.8346C18.0902 16.1955 18.0902 16.7368 17.7293 17.0977L17.0977 17.7293C16.7368 18.0902 16.1955 18.0902 15.8346 17.7293L11.9549 13.9398L8.16541 17.7293C7.80451 18.0902 7.26316 18.0902 6.90226 17.7293L6.27068 17.0977C5.90977 16.7368 5.90977 16.1955 6.27068 15.8346L10.0602 12.0451L6.27068 8.16541C5.90977 7.80451 5.90977 7.26316 6.27068 6.90226L6.90226 6.27068C7.26316 5.90977 7.80451 5.90977 8.16541 6.27068L11.9549 10.0602L15.7444 6.27068C16.1053 5.90977 16.6466 5.90977 17.0075 6.27068L17.7293 6.90226C18.0902 7.26316 18.0902 7.80451 17.7293 8.16541L13.9398 11.9549L17.7293 15.8346Z" fill={iconColor} />
    </svg>
}

IconX.defaultProps = {
  size: 24
}
