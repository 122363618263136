import styled from 'styled-components'

import { rem } from '../../../lib/tools'
import { type, color } from '../../../designSystem'

const DataLabel = styled.span`
  display: block;
  font-family: ${type.family.default};
  font-size: ${rem(type.size.s0)};
  font-style: ${type.style.normal};
  font-weight: ${type.weight.bold};
  line-height: ${type.lineHeight.body};
  letter-spacing: ${rem(2)};
  text-transform: uppercase;
  color: ${color.gray};
  }
`

export default DataLabel
