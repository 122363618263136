import React from 'react'
import PropTypes from 'prop-types'
import { keyframes } from '@emotion/core'
import styled from '@emotion/styled'

import { rem } from '../../lib/tools'
import Flex from '../Flex'

const softBlink = keyframes`
  0%, 80%, 100% {
    opacity: 0.1;
    transform: scale(0.75);
  }
  40% {
    opacity: 1;
    transform: scale(1);
  }
`

const Dot = styled.span`
  display: block;
  width: ${rem(6)};
  height: ${rem(6)};
  border-radius: ${rem(6)};
  background-color: ${props => props.dotColor || props.theme.color.gray5};

  animation-name: ${softBlink};
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-delay: ${props => props.delay || '0s'};
  animation-iteration-count: infinite;
  animation-fill-mode: both;
`

const Loading = ({ dotColor }) => (
  <Flex width={rem(26)} justifyContent="space-between">
    <Dot {...{ dotColor }} delay="0s" />
    <Dot {...{ dotColor }} delay="0.3s" />
    <Dot {...{ dotColor }} delay="0.6s" />
  </Flex>
)

Loading.propTypes = {
  /** Set custom text color. */
  dotColor: PropTypes.string
}

export default Loading
