import React from 'react'
import Parse from 'parse'
import styled from 'styled-components'
import shortid from 'shortid'

import Flex from '../../layout/Flex'
import Box from '../../layout/Box'

import Hr from '../../style/elements/Hr'
import Label from '../../style/elements/Label'
import Input from '../../style/elements/Input'
import Select from '../../style/elements/Select'
import Button from '../../style/elements/Button'

import MainHeading from '../../style/text/MainHeading'
import UIMessage from '../../style/text/UIMessage'
import Text from '../../style/text/Text'

import {maxCharCount} from '../../../lib/constants'
import {rem} from '../../../lib/tools'
import {color} from '../../../designSystem'

const PushBox = styled(Box)`
  max-width: ${rem(600)};
`

const Standout = styled.strong`
  color: ${props => !!props.textColor ? props.textColor : 'inherit'};
`

export class Notifications extends React.Component {
  constructor() {
    super()

    this.state = {
      organizations: [],
      sendDonorsOrganizationId: '',
      sendDonorsOrganizationName: '',
      sendBothDonorsFansOrganizationId: '',
      sendBothDonorsFansOrganizationName: '',
      messageToAllUsers: '',
      messageToDonorsOfOrganization: '',
      messageToBothDonorsFansOfOrganization: '',
      sentPushNotification_ToAllUsers: false,
      sentPushNotification_ToDonorsOfOrganization: false,
      sentPushNotification_ToBothDonorsFansOfOrganization: false,
      errorPushNotification_ToDonorsOfOrganization: {},
      errorPushNotification_ToBothDonorsFansOfOrganization: {}
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSelectChange = this.handleSelectChange.bind(this)
    this.getValues = this.getValues.bind(this)
  }

  handleChange = (key, e) => (
    e => this.setState({[key]: e.target.value})
  )

  handleSelectChange = (key, e) => (
    e => this.setState({
      [key[0]]: e.target.value,
      [key[1]]: e.target.options[e.target.selectedIndex].text
    })
  )

  /*
    Not thrilled with the this._isMounted setup
    See: https://github.com/jaredpalmer/formik/issues/772#issuecomment-406080224
  */
  getValues = () => {
    // Get Organizations for Viewing Organization Stats
    Parse.Cloud.run('fetchAllOrganizations', {}).then((result) => {
      if (this._isMounted === true) this.setState({organizations: result})
    })
  }

  sendPushNotification_ToAllUsers(event) {
    event.preventDefault()

    const {messageToAllUsers} = this.state

    Parse.Cloud.run('sendPushNotification_ToAllUsers', {
      message: messageToAllUsers
    }).then((result) => {
      this.setState({
        sentPushNotification_ToAllUsers: result.sentPushNotification,
        messageToAllUsers: ''
      })
    })
  }

  sendPushNotification_ToDonorsOfOrganization(event) {
    event.preventDefault()

    const {
      messageToDonorsOfOrganization,
      sendDonorsOrganizationId,
      sendDonorsOrganizationName
    } = this.state

    // First check to make sure an Organization is selected
    if (!!sendDonorsOrganizationId && (sendDonorsOrganizationId !== 'false')) {
      // We're good, send push notification
      Parse.Cloud.run('adminSendPushNotification_ToDonorsOfOrganization', {
        message: messageToDonorsOfOrganization,
        organizationId: sendDonorsOrganizationId,
        organizationName: sendDonorsOrganizationName
      }).then((result) => {
        this.setState({
          sentPushNotification_ToDonorsOfOrganization: result.sentPushNotification,
          messageToDonorsOfOrganization: ''
        })
      })
    } else {
      // return error message
      this.setState({
        errorPushNotification_ToDonorsOfOrganization: {
          type: "error",
          message: "Please select an Organization."
        }
      })
    }
  }

  sendPushNotification_ToBothDonorsFansOfOrganization(event) {
    event.preventDefault()

    const {
      messageToBothDonorsFansOfOrganization,
      sendBothDonorsFansOrganizationId,
      sendBothDonorsFansOrganizationName
    } = this.state

    // First check to make sure an Organization is selected
    if (!!sendBothDonorsFansOrganizationId && (sendBothDonorsFansOrganizationId !== 'false')) {
      // We're good, send push notification
      Parse.Cloud.run('adminSendPushNotification_ToBothDonorsFansOfOrganization', {
        message: messageToBothDonorsFansOfOrganization,
        organizationId: sendBothDonorsFansOrganizationId,
        organizationName: sendBothDonorsFansOrganizationName
      }).then((result) => {
        this.setState({
          sentPushNotification_ToBothDonorsFansOfOrganization: result.sentPushNotification,
          messageToBothDonorsFansOfOrganization: ''
        })
      })
    } else {
      // return error message
      this.setState({
        errorPushNotification_ToBothDonorsFansOfOrganization: {
          type: "error",
          message: "Please select an Organization."
        }
      })
    }
  }


  componentDidMount() {
    this._isMounted = true

    // Set active page state
    const {setPageId} = this.props
    setPageId('admin-notifications')

    // Get data for current Organization (based on User)
    this.getValues()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    // const { setPageId } = this.props
    // // setPageId('notifications')
    // console.log(setPageId)
    const pageTitle = 'Send Push Notifications'

    const {
      organizations,
      sendDonorsOrganizationId,
      sendBothDonorsFansOrganizationId,
      messageToAllUsers,
      messageToDonorsOfOrganization,
      messageToBothDonorsFansOfOrganization,
      sentPushNotification_ToAllUsers,
      sentPushNotification_ToDonorsOfOrganization,
      sentPushNotification_ToBothDonorsFansOfOrganization,
      errorPushNotification_ToDonorsOfOrganization,
      errorPushNotification_ToBothDonorsFansOfOrganization
    } = this.state

    return (
      <Box>
        <MainHeading>{pageTitle}</MainHeading>

        <Box mt={3}>
          <Hr/>
        </Box>

        {/* <Box mt={3}>
          <UIMessage type="warning">
            <strong>Note:</strong> This page will send push notifications directly to the user&#8217;s device. Please use responsibly!
          </UIMessage>
        </Box> */}

        <PushBox mt={5}>
          <form onSubmit={(e) => this.sendPushNotification_ToAllUsers(e)} method="post">
            <Label>Send to <Standout textColor={color.pink}>ALL APP USERS</Standout></Label>

            <Flex mt={1} alignItems={'center'}>
              <Input
                maxLength={maxCharCount}
                placeholder="Type message here"
                value={messageToAllUsers}
                onChange={this.handleChange('messageToAllUsers')}
              />

              <Box ml={1}>
                <Button htmlButton>Send</Button>
              </Box>
            </Flex>

            {!!sentPushNotification_ToAllUsers &&
            <Box mt={1}>
              <UIMessage type="success">
                Push notification sent!
              </UIMessage>
            </Box>
            }

            <Box mt={1}>
              <Text minor><em>This will send a push notification to ALL USERS OF THE APP.</em></Text>
            </Box>
          </form>
        </PushBox>

        <Box mt={6}>
          <Hr/>
        </Box>

        <PushBox mt={5}>
          <form onSubmit={(e) => this.sendPushNotification_ToDonorsOfOrganization(e)} method="post">
            <Label>Send to <Standout textColor={color.pink}>donors of ONE Organization</Standout></Label>

            <Box mt={1}>
              <Select
                value={sendDonorsOrganizationId}
                onChange={this.handleSelectChange(['sendDonorsOrganizationId', 'sendDonorsOrganizationName'])}
              >
                <option value={false} key={shortid.generate()}>Select Organization</option>
                {organizations.map((organization) => (
                  <option value={organization.id} key={shortid.generate()}>{organization.get('name')}</option>
                ))}
              </Select>
            </Box>

            <Flex mt={1} alignItems={'center'}>
              <Input
                maxLength={maxCharCount}
                placeholder="Type message here"
                value={messageToDonorsOfOrganization}
                onChange={this.handleChange('messageToDonorsOfOrganization')}
              />

              <Box ml={1}>
                <Button htmlButton>Send</Button>
              </Box>
            </Flex>

            {!!sentPushNotification_ToDonorsOfOrganization &&
            <Box mt={1}>
              <UIMessage type="success">
                Push notification sent!
              </UIMessage>
            </Box>
            }
            {!!Object.keys(errorPushNotification_ToDonorsOfOrganization).length &&
            <Box mt={1}>
              <UIMessage type={errorPushNotification_ToDonorsOfOrganization.type}>
                {errorPushNotification_ToDonorsOfOrganization.message}
              </UIMessage>
            </Box>
            }

            <Box mt={1}>
              <Text minor><em>Send a push notification to donors of the selected Organization.</em></Text>
            </Box>
          </form>
        </PushBox>

        <PushBox mt={5}>
          <form onSubmit={(e) => this.sendPushNotification_ToBothDonorsFansOfOrganization(e)} method="post">
            <Label>Send to <Standout textColor={color.pink}>both donors & fans of ONE Organization</Standout></Label>

            <Box mt={1}>
              <Select
                value={sendBothDonorsFansOrganizationId}
                onChange={this.handleSelectChange(['sendBothDonorsFansOrganizationId', 'sendBothDonorsFansOrganizationName'])}
              >
                <option value={false} key={shortid.generate()}>Select Organization</option>
                {organizations.map((organization) => (
                  <option value={organization.id} key={shortid.generate()}>{organization.get('name')}</option>
                ))}
              </Select>
            </Box>

            <Flex mt={1} alignItems={'center'}>
              <Input
                maxLength={maxCharCount}
                placeholder="Type message here"
                value={messageToBothDonorsFansOfOrganization}
                onChange={this.handleChange('messageToBothDonorsFansOfOrganization')}
              />

              <Box ml={1}>
                <Button htmlButton>Send</Button>
              </Box>
            </Flex>

            {!!sentPushNotification_ToBothDonorsFansOfOrganization &&
            <Box mt={1}>
              <UIMessage type="success">
                Push notification sent!
              </UIMessage>
            </Box>
            }
            {!!Object.keys(errorPushNotification_ToBothDonorsFansOfOrganization).length &&
            <Box mt={1}>
              <UIMessage type={errorPushNotification_ToBothDonorsFansOfOrganization.type}>
                {errorPushNotification_ToBothDonorsFansOfOrganization.message}
              </UIMessage>
            </Box>
            }

            <Box mt={1}>
              <Text minor><em>Send a push notification to those who have either donated to or follow the selected
                Organization.</em></Text>
            </Box>
          </form>
        </PushBox>
      </Box>
    )
  }
}

export default Notifications
