import styled from 'styled-components'

import { rem, alpha } from '../../../lib/tools'
import { type, color, border } from '../../../designSystem'

const Text = styled.div`
  display: block;

  p {
    font-family: ${type.family.default};
    font-size: ${props =>
      !!props.fontSize ? rem(props.fontSize) :
        !!props.minor ? rem(type.size.s0) : rem(type.size.s1)
    };
    font-style: ${type.style.normal};
    font-weight: ${type.weight.normal};
    line-height: ${props => !!props.minor ? type.lineHeight.summary : type.lineHeight.body};
    color: ${props =>
      !!props.textColor ? props.textColor :
        !!props.minor ? color.gray : color.black
    };
  }
  strong {
    font-weight: ${type.weight.bold};
    color: ${props =>
      !!props.textColor ? props.textColor :
        !!props.minor ? color.gray : color.trueBlack
    };
  }
  em {
    font-style: ${type.style.italic};
  }
  a {
    margin-bottom: ${rem(-2)};
    padding-bottom: ${rem(2)};
    text-decoration: none;
    color: ${props => !!props.minor ? color.gray : color.blue};
    border-bottom: ${rem(border.widthThick)} solid ${props => !!props.minor ? 'transparent' : color.lightestGray};

    &:visited {
      color: ${props => !!props.minor ? color.gray : color.blue};
    }
    &:hover, &:focus {
      color: ${color.navy};
      border-bottom-color: ${alpha(color.navy)(0.25)};
    }
  }
`

export default Text
