import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { useTheme } from 'emotion-theming'

import Flex from '../Flex'
import Text from '../Text'
import { rem } from '../../lib/tools'
import { lightGray2Transparent, lightWhite } from '../../tokens/color'
import * as space from '../../tokens/space'

const ToggleTrack = styled.ul`
  box-sizing: border-box;
  display: inline-flex;
  flex-shrink: 0;
  width: ${space.s8};
  height: ${`calc(${space.s6} - ${rem(2)})`};
  padding: ${rem(2)};
  cursor: pointer;
  background-color: ${props =>
    props.isSwitchActive ? props.theme.color.classicBlue3 : props.theme.color.gray2
  };
  border-radius: ${space.s4};
`

const ToggleSwitch = styled.li`
  display: block;
  width: ${`calc(${space.s6} - ${space.s1} - ${rem(2)})`};
  height: ${`calc(${space.s6} - ${space.s1} - ${rem(2)})`};
  border-radius: ${space.s6};
  background-color: ${lightWhite};
  box-shadow: 0 ${rem(1)} ${rem(2)} 0 ${lightGray2Transparent};
  transition: transform 120ms ease-out;

  ${props => !!props.isSwitchActive && `
    transform: translateX(${rem(18)});
  `}
`

/*
  Hide text visually but not from screen readers.
  https://a11yproject.com/posts/how-to-hide-content/
*/
const HideText = styled.span`
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap; /* added line */
`

const Toggle = ({ isSwitchActive, label, onClick, ...rest }) => {
  const theme = useTheme()
  return (
    <Flex alignItems="center">
      <ToggleTrack {...{ onClick, isSwitchActive }}>
        <ToggleSwitch {...{ isSwitchActive }}>
          <HideText>{isSwitchActive ? 'on' : 'off'}</HideText>
        </ToggleSwitch>
      </ToggleTrack>

      {!!label &&
        <Text ml={2} textColor={isSwitchActive ? theme.color.black4 : theme.color.black2}>
          {label}
        </Text>
      }
    </Flex>
  )
}

Toggle.propTypes = {
  /** Prop to turn the switch on or off. */
  isSwitchActive: PropTypes.bool,
  /** If present, places the label next to the toggle.
      The label style changes to match the active switch. */
  label: PropTypes.string,
  /** The onClick function is assigned to the ToggleSwitch entity */
  onClick: PropTypes.func
}

Toggle.defaultProps = {
  isSwitchActive: false
}

export default Toggle
