export const lightWhite = '#ffffff'
export const lightGray1 = '#f3f5f5'
export const lightGray2 = '#dce0e2'
export const lightGray3 = '#c6cccf'
export const lightGray4 = '#afb8bd'
export const lightGray5 = '#8f9ba2'
export const lightBlack1 = '#717a7f'
export const lightBlack2 = '#535a5e'
export const lightBlack3 = '#383d3f'
export const lightBlack4 = '#1f2123'
export const lightBlack5 = '#000000'
export const lightBerry = '#d30c84'
export const lightClassicBlue1 = '#ebf5fe'
export const lightClassicBlue2 = '#a1cdfc'
export const lightClassicBlue3 = '#2b8dff'
export const lightClassicBlue4 = '#0069db'
export const lightClassicBlue5 = '#044cb8'
export const lightPositive1 = '#dcfef2'
export const lightPositive2 = '#acfce0'
export const lightPositive3 = '#0bc584'
export const lightPositive4 = '#099b68'
export const lightPositive5 = '#027346'
export const lightNotification1 = '#fef8e2'
export const lightNotification2 = '#faeeb7'
export const lightNotification3 = '#f0d000'
export const lightNotification4 = '#c29b00'
export const lightNotification5 = '#856a00'
export const lightNegative1 = '#fef0eb'
export const lightNegative2 = '#fabba8'
export const lightNegative3 = '#e7440d'
export const lightNegative4 = '#c53a0b'
export const lightNegative5 = '#8c1c03'
export const lightGray1Transparent = 'rgba(31, 33, 35, 0.04)'
export const lightGray2Transparent = 'rgba(31, 33, 35, 0.14)'
export const lightGray3Transparent = 'rgba(31, 33, 35, 0.24)'
export const lightGray4Transparent = 'rgba(31, 33, 35, 0.33)'
export const lightGray5Transparent = 'rgba(31, 33, 35, 0.46)'
export const lightBlack1Transparent = 'rgba(31, 33, 35, 0.62)'
export const lightBlack2Transparent = 'rgba(31, 33, 35, 0.75)'
export const lightBlack3Transparent = 'rgba(31, 33, 35, 0.88)'
export const lightBrightGold = '#f1b336'
export const lightLikeRed = '#f82047'
export const darkWhite = '#000000'
export const darkGray1 = '#1f2123'
export const darkGray2 = '#383d3f'
export const darkGray3 = '#535a5e'
export const darkGray4 = '#717a7f'
export const darkGray5 = '#8f9ba2'
export const darkBlack1 = '#afb8bd'
export const darkBlack2 = '#c6cccf'
export const darkBlack3 = '#dce0e2'
export const darkBlack4 = '#ffffff'
export const darkBlack5 = '#ffffff'
export const darkClassicBlue1 = '#0644a1'
export const darkClassicBlue2 = '#0864cd'
export const darkClassicBlue3 = '#3898ff'
export const darkClassicBlue4 = '#a1cdfc'
export const darkClassicBlue5 = '#ddedfd'
export const darkBerry = '#de0c8a'
export const darkPositive1 = '#027344'
export const darkPositive2 = '#099b65'
export const darkPositive3 = '#0bc581'
export const darkPositive4 = '#acfcdf'
export const darkPositive5 = '#dcfef2'
export const darkNotification1 = '#856600'
export const darkNotification2 = '#c29500'
export const darkNotification3 = '#f0c800'
export const darkNotification4 = '#faecb7'
export const darkNotification5 = '#fef7e2'
export const darkNegative1 = '#8c1e03'
export const darkNegative2 = '#c53d0b'
export const darkNegative3 = '#e7470d'
export const darkNegative4 = '#fabca8'
export const darkNegative5 = '#fef0eb'
export const darkGray1Transparent = 'rgba(243, 245, 245, 0.04)'
export const darkGray2Transparent = 'rgba(243, 245, 245, 0.14)'
export const darkGray3Transparent = 'rgba(243, 245, 245, 0.24)'
export const darkGray4Transparent = 'rgba(243, 245, 245, 0.33)'
export const darkGray5Transparent = 'rgba(243, 245, 245, 0.46)'
export const darkBlack1Transparent = 'rgba(243, 245, 245, 0.62)'
export const darkBlack2Transparent = 'rgba(243, 245, 245, 0.75)'
export const darkBlack3Transparent = 'rgba(243, 245, 245, 0.88)'
export const darkBrightGold = '#f2b740'
export const darkLikeRed = '#f82a4f'
export const originalBlue = '#3cc5eb'
export const originalMagenta = '#c42a91'
export const originalNavy = '#24346a'
