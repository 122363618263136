import React from 'react'
import { useTheme } from 'emotion-theming'

export const IconRecurring = ({ color, large, size }) => {
  const theme = useTheme()
  const iconColor = color || theme.color.black3

  return large ?
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.8747 16.4447L5.33333 15.9365C5.33333 10.3455 9.90779 5.77102 15.4988 5.77102C17.4048 5.77102 19.1838 6.2793 20.5815 7.16877L22.2334 5.51689C22.6146 5.13568 23.2499 5.38982 23.2499 5.89809L24.3936 12.5056C24.5206 12.8868 24.1394 13.268 23.6311 13.268L17.1507 12.2515C16.6424 12.1244 16.5153 11.4891 16.7695 11.1079L18.6755 9.20186C17.786 8.69359 16.6424 8.43945 15.4988 8.43945C11.3055 8.43945 7.8747 11.8703 7.8747 16.0635C7.8747 16.1906 7.8747 16.3177 7.8747 16.4447ZM23.1229 15.6823C23.1229 15.8094 23.1229 15.9365 23.1229 16.0635C23.1229 20.2568 19.692 23.6876 15.4988 23.6876C14.3552 23.6876 13.2116 23.4335 12.195 22.9252L14.101 21.0192C14.4822 20.638 14.2281 20.0027 13.7198 20.0027L7.23935 18.9861C6.98522 18.859 6.60401 19.2402 6.60401 19.6215L7.62056 26.1019C7.74763 26.6102 8.38297 26.8643 8.6371 26.4831L10.289 24.8312C11.8138 25.7207 13.5928 26.229 15.4988 26.229C21.0898 26.229 25.6642 21.6545 25.6642 16.0635L23.1229 15.6823Z" fill={iconColor} />
    </svg> :
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.90602 12.3336L4 11.9523C4 7.7591 7.43084 4.32826 11.6241 4.32826C13.0536 4.32826 14.3878 4.70946 15.4361 5.37657L16.675 4.13766C16.961 3.85175 17.4375 4.04236 17.4375 4.42356L18.2952 9.37922C18.3905 9.66512 18.1046 9.95103 17.7234 9.95103L12.863 9.18862C12.4818 9.09332 12.3865 8.61681 12.5771 8.33091L14.0066 6.90139C13.3395 6.52019 12.4818 6.32958 11.6241 6.32958C8.47915 6.32958 5.90602 8.90271 5.90602 12.0477C5.90602 12.143 5.90602 12.2383 5.90602 12.3336ZM17.3422 11.7617C17.3422 11.857 17.3422 11.9523 17.3422 12.0477C17.3422 15.1926 14.769 17.7657 11.6241 17.7657C10.7664 17.7657 9.90867 17.5751 9.14626 17.1939L10.5758 15.7644C10.8617 15.4785 10.6711 15.002 10.2899 15.002L5.42952 14.2396C5.23891 14.1443 4.95301 14.4302 4.95301 14.7161L5.71542 19.5764C5.81072 19.9576 6.28723 20.1482 6.47783 19.8623L7.71674 18.6234C8.86036 19.2905 10.1946 19.6717 11.6241 19.6717C15.8173 19.6717 19.2482 16.2409 19.2482 12.0477L17.3422 11.7617Z" fill={iconColor} />
    </svg>
}

IconRecurring.defaultProps = {
  size: 24
}
