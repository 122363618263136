import React from 'react'
import styled from 'styled-components'
import shortid from 'shortid'
import NumberFormat from 'react-number-format'

import Box from '../layout/Box'
import Button from '../style/elements/Button'

import Table from '../style/elements/Table'
import TableCaption from '../style/elements/TableCaption'
import TableTh from '../style/elements/TableTh'
import TableTd from '../style/elements/TableTd'
import Hr from '../style/elements/Hr'

import LargeNumber from '../style/text/LargeNumber'

import {rem, exportTableToCSV} from '../../lib/tools'
import {color, dimension, type} from '../../designSystem'
import RegularNumber from "../style/text/RegularNumber";
import DonationType from "../style/icons/DonationType";

const PageHeader = styled.h3`
  margin-bottom: ${rem(dimension.d1)};
  font-size: ${rem(type.size.s2)};
  font-weight: ${type.weight.bold};
  text-align: left;
  color: ${color.darkGray};
`

const DonorUsername = styled.span`
  display: block;
  margin-top: ${rem(dimension.dm)};
  font-size: ${rem(type.size.s1)};
  font-weight: ${type.weight.regular};
  text-align: left;
  color: ${color.gray};
`

class GeneratedMonthlyDonorStatement extends React.Component {
  constructor() {
    super()

    this.state = {
      donorUsername: '',
      donorName: '',
      donorDonations: []
    }
  }

  componentWillMount() {
    if (!!this.props.location.state) {
      const donorUsername = this.props.location.state.donorUsername || ''
      const donorName = this.props.location.state.donorName || ''
      const donorDonations = this.props.location.state.donorDonations || []

      this.setState({
        donorUsername: donorUsername,
        donorName: donorName,
        donorDonations: donorDonations
      })
    }
  }

  prepareDownloadCSV(event) {
    event.preventDefault()

    const {donorName, donorUsername} = this.state

    exportTableToCSV('table-for-export', `${donorName}-${donorUsername}-FinancialStatement.csv`)
  }

  render() {
    const {
      donorUsername,
      donorName,
      donorDonations
    } = this.state

    // Get total donation amount
    var totalDonations = 0;
    for (var i = 0; i < donorDonations.length; ++i) {
      totalDonations += donorDonations[i].amount;
    }

    var netDonations = 0;
    for (i = 0; i < donorDonations.length; ++i) {
      if (donorDonations[i].netAmount) {
        netDonations += donorDonations[i].netAmount;
      }
    }

    // Sort by date for details view
    donorDonations.sort((a, b) => (
      new Date(a.donationDate) - new Date(b.donationDate)
    ))

    return (
      <Box>
        <Box width={rem(200)}>
          <Button onClick={(e) => this.prepareDownloadCSV(e)}>Download CSV</Button>
        </Box>

        <Box mt={4}>
          <PageHeader>{donorName} Total Donations <DonorUsername>{donorUsername}</DonorUsername></PageHeader>

          <Box mt={1}>
            <LargeNumber textColor={color.pink}>
              <NumberFormat
                value={totalDonations}
                decimalScale={2}
                fixedDecimalScale={true}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
              />
            </LargeNumber>
            <RegularNumber>
              [<NumberFormat
              value={netDonations}
              decimalScale={2}
              fixedDecimalScale={true}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />]
            </RegularNumber>
          </Box>

          <Box mt={7}>
            <Hr/>

            <Box mt={4}>
              <Table layout={'auto'}>
                <TableCaption alt="true">Donation Details</TableCaption>
                <thead>
                <tr>
                  <TableTh>Organization</TableTh>
                  <TableTh>Date</TableTh>
                  <TableTh>Amount</TableTh>
                  <TableTh>Cover Fees</TableTh>
                  <TableTh>Type</TableTh>
                  <TableTh>Gross</TableTh>
                  <TableTh>Stripe Fee</TableTh>
                  <TableTh>GivApp Fee</TableTh>
                  <TableTh>Net</TableTh>
                </tr>
                </thead>
                <tbody>
                {donorDonations.map(donation => (
                  <tr key={shortid.generate()}>
                    <TableTd>{donation.organization.name}</TableTd>
                    <TableTd>{donation.donationDate.toString('M/dd/yyyy')}</TableTd>
                    <TableTd>
                      <NumberFormat
                        value={donation.amount}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                      />
                    </TableTd>
                    <TableTd position={'center'}>
                      {!!donation.coverTransactionFees === true &&
                      <span>&#10003;</span>
                      }
                    </TableTd>
                    <TableTd position={'center'}><DonationType type={donation.type}/></TableTd>
                    <TableTd>
                      <NumberFormat
                        value={donation.grossAmount}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                      /></TableTd>
                    <TableTd>
                      <NumberFormat
                        value={donation.stripeFee}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                      /></TableTd>
                    <TableTd>
                      <NumberFormat
                        value={donation.givappFee}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                      /></TableTd>
                    <TableTd>
                      <NumberFormat
                        value={donation.netAmount}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                      /></TableTd>
                  </tr>
                ))}
                </tbody>
              </Table>
            </Box>
          </Box>
        </Box>


        {/* Hidden table for CSV Download */}
        <table id="table-for-export" style={{display: 'none'}}>
          <thead>
          <tr>
            <th>Organization</th>
            <th>Donation Date</th>
            <th>Amount</th>
            <th>Cover Fees</th>
            <th>Type</th>
            <th>Gross</th>
            <th>GivApp Fee</th>
            <th>Stripe Fee</th>
            <th>Net</th>
          </tr>
          </thead>

          <tbody>
          {donorDonations.map(donation => (
            <tr key={shortid.generate()}>
              <td>{donation.organization.name}</td>
              <td>{donation.donationDate.toString('M/dd/yyyy')}</td>
              <td><NumberFormat
                value={donation.amount}
                decimalScale={2}
                fixedDecimalScale={true}
                displayType={'text'}
              /></td>
              <td>{donation.coverTransactionFees === true ? '✓' : ''}</td>
              <td>{donation.type}</td>
              <td>
                <NumberFormat
                  value={donation.grossAmount}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  displayType={'text'}
                /></td>
              <td>
                <NumberFormat
                  value={donation.stripeFee}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  displayType={'text'}
                />
              </td>
              <td>
                <NumberFormat
                  value={donation.givappFee}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  displayType={'text'}
                />
              </td>
              <td>
                <NumberFormat
                  value={donation.netAmount}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  displayType={'text'}
                />
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      </Box>
    )
  }
}

export default GeneratedMonthlyDonorStatement
