import React from 'react'
import {Link} from 'react-router-dom'
import styled from 'styled-components'

import {checkMatch} from '../../../lib/tools'
import Text from "givapp-design/src/components/Text";

const MainNavItemLi = styled.li`

  position: relative;

  padding: 1rem 0rem 0rem 1rem;

  &:first-child {
    a {
      margin-left: 0;
    }
  }
`


const MainNavItem = ({
                       pageId,
                       id,
                       url,
                       name,
                       titleAttr
                     }) => (
  <MainNavItemLi>
    <Link to={url} style={{textDecoration: 'none'}}>
      <Text meta gray bold={checkMatch(id, pageId)} as={'span'} href={url}>
        {name}
      </Text>
    </Link>

  </MainNavItemLi>
)

export default MainNavItem
