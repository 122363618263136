import React from 'react'
import styled from '@emotion/styled'
import { useTheme } from 'emotion-theming'

const StyledSvg = styled.svg`
  &:hover, &:focus {
    cursor: pointer;
    path {
      fill: ${props => props.hoverColor} !important;
    }
  }
`

export const IconInfo = ({ color, hoverColor, large, size }) => {
  const theme = useTheme()
  const iconColor = color || theme.color.black3

  return large ?
    <StyledSvg hoverColor={hoverColor || iconColor} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M28 16C28 22.6274 22.6274 28 16 28C9.37258 28 4 22.6274 4 16C4 9.37258 9.37258 4 16 4C22.6274 4 28 9.37258 28 16ZM20.2826 19.4274V21.9988H12.5684V19.4274H15.1398V15.9988H12.5684V13.4274H17.7112V19.4274H20.2826ZM15.9962 11.7128C16.943 11.7128 17.7105 10.9452 17.7105 9.99847C17.7105 9.05169 16.943 8.28418 15.9962 8.28418C15.0494 8.28418 14.2819 9.05169 14.2819 9.99847C14.2819 10.9452 15.0494 11.7128 15.9962 11.7128Z" fill={iconColor} />
    </StyledSvg> :
    <StyledSvg hoverColor={hoverColor || iconColor} width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM15.2115 14.5703V16.4988H9.42578V14.5703H11.3544V11.9988H9.42578V10.0703H13.2829V14.5703L15.2115 14.5703ZM11.9968 8.78432C12.7068 8.78432 13.2825 8.20868 13.2825 7.4986C13.2825 6.78852 12.7068 6.21289 11.9968 6.21289C11.2867 6.21289 10.7111 6.78852 10.7111 7.4986C10.7111 8.20868 11.2867 8.78432 11.9968 8.78432Z" fill={iconColor} />
    </StyledSvg>
}

IconInfo.defaultProps = {
  size: 24
}
