import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import styled from '@emotion/styled'
import {Box, Flex, Text, theme} from "givapp-design"
import * as border from "givapp-design/src/tokens/border"
import { type, color } from "../../designSystem"


import GivAppLogo from "givapp-design/src/special/online-giving/components/GivAppLogo";

const BasicPage = ({children}) => (
  <Body justifyContent={'center'} alignContent={'center'} alignItems={'center'} height={'100vh'} flexDirection={'column'}>
    <Content flexDirection={'column'} justifyContent={'center'}>
      {children}
    </Content>
    <GivAppFooter mt={'auto'} width={1 / 1} justifyContent={'center'}>
      <Flex pt={'1.45rem'} pb={1} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} alignContent={'center'}>
        <Flex mb={'0.75rem'} justifyContent={'center'} alignContent={'center'} alignItems={'center'}>
          <Text gray mr={2} pb={1}>Powered by</Text>
          <Text lineHeight={0} text><a href="https://givapp.com"><GivAppLogo/></a></Text>
        </Flex>
                        <Text gray minor fontSize={11} textTransform="uppercase">
                        Copyright © 2021 GivApp LLC. All rights reserved. 
                        </Text>
                      </Flex>
    </GivAppFooter>
  </Body>
)

const Content = styled(Flex)`
  overflow-x: auto;
  height: 100%;
  width: 100%;
`
const BodyStyle = styled(Flex)`
  background-color: rgb(250, 252, 252);
`
const Body = ({props, children, ...rest}) => (<BodyStyle
  flexDirection={'column'}
  justifyContent={'center'}
  alignItems={'center'}
  alignContent={'center'}
  {...props}
  {...rest}
>
  {children}
</BodyStyle>)

const GivAppFooter = styled(Flex)`
  border-top-width: ${border.borderWidth};
  border-top-style: ${border.borderStyleDefault};
  border-top-color: ${({theme}) => theme.color.gray1};
  background-color: ${({theme}) => theme.color.white};
`
const FooterLink = styled(Flex)`
  border-left-width: 2px;
  border-left-style: ${border.borderStyleDefault};
  border-left-color: ${({theme}) => theme.color.gray2};
`

export default BasicPage
