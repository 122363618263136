import React from 'react'
import styled from 'styled-components'

import {rem} from '../../../lib/tools'
import {type, color, border, dimension} from '../../../designSystem'

const WrappedButton = styled.div`
  width:100%;
`
const StyledButton = styled.a`
  width:100%;
  display: inline-block;
  box-sizing: border-box;
  height: ${rem(44)};
  padding-top: 0;
  padding-right: ${rem(dimension.d1)};
  padding-bottom: 0;
  padding-left: ${rem(dimension.d1)};

  /*
  Going to use flex to manage button alignment
  Assumes <span>Button Label</span> as child.
  */
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: ${type.family.default};
  font-size: ${rem(type.size.s0)};
  font-weight: ${type.weight.bold};
  line-height: 1;
  letter-spacing: ${rem(1)};
  text-decoration: none;
  text-shadow: none;
  text-transform: uppercase;
  color: ${props => !!props.outline ? color.gray : color.white};
  cursor: pointer;
  border: ${props => !!props.outline ? `${rem(border.widthThick)} solid ${color.lightGray}` : 0};
  background-color: ${props => !!props.outline ? 'transparent' : color.blue};
  border-radius: ${rem(border.radius.sm)};
  -webkit-appearance: none;
  outline: none;

  &:hover, &:focus {
    color: ${props => !!props.outline ? color.navy : color.white};
    border-color: ${props => !!props.outline ? color.navy : 'transparent'};
    background-color: ${props => !!props.outline ? 'transparent' : color.pink};
  }

  &[disabled]{
    color: ${props => !!props.outline ? color.darkGray : color.white} !important;
    border-color: ${props => !!props.outline ? color.darkGray : 'transparent'} !important;
    background-color: ${props => !!props.outline ? 'transparent' : color.lightGray} !important;
  }

  span {
    display: block;
  }
  
  &.active{
  background-color: ${props => !!props.outline ? 'transparent' : color.purple};
  }
`

const StyledHTMLButton = StyledButton.withComponent('button')

const Button = ({url, htmlButton, children, ...rest}) => (
  <WrappedButton>
    {!!htmlButton ?
      <StyledHTMLButton {...rest}>
        <span>{children}</span>
      </StyledHTMLButton> :
      <StyledButton href={url} {...rest}>
        <span>{children}</span>
      </StyledButton>
    }
  </WrappedButton>
)
export default Button
