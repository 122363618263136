import { fontFamily } from '../tokens/typography'

export const basicLinkStyle = (theme) => `
  font-family: ${fontFamily};
  text-decoration: none;
  color: ${theme.color.classicBlue4};

  &:visited {
    color: ${theme.color.classicBlue4};
  }

  &:hover, &:focus, &:active {
    color: ${theme.color.classicBlue5};
  }
`
