import styled from 'styled-components'
import {width, height, space, flex, background} from 'styled-system'
import React from "react";

const styleBoxSizing = {
  boxSizing: 'border-box'
}


const StyledBox = styled.div`
  padding: ${props => !!props.padding ? props.padding: props.padding};
  ${styleBoxSizing}
  ${width}
  ${height}
  ${space}
  ${flex}
  ${background}
`

const Box = ({children, ...rest}) => (
  <StyledBox {...rest}>
    {children}
  </StyledBox>
)

export default Box
