import React from 'react'
import styled from 'styled-components'
import shortid from 'shortid'
import NumberFormat from 'react-number-format'

import Box from '../../layout/Box'
import Button from '../../style/elements/Button'

import Table from '../../style/elements/Table'
import TableCaption from '../../style/elements/TableCaption'
import TableTh from '../../style/elements/TableTh'
import TableTd from '../../style/elements/TableTd'
import Hr from '../../style/elements/Hr'

import LargeNumber from '../../style/text/LargeNumber'

import {rem, exportTableToCSV} from '../../../lib/tools'
import {color, dimension, type} from '../../../designSystem'
import RegularNumber from "../../style/text/RegularNumber";
import Parse from "parse";

import Modal from 'react-modal';
import SubHeading from "../../style/text/SubHeading";
import Flex from "../../layout/Flex";
import Text from "../../style/text/Text";

const PageHeader = styled.h3`
  margin-bottom: ${rem(dimension.d1)};
  font-size: ${rem(type.size.s2)};
  font-weight: ${type.weight.bold};
  text-align: left;
  color: ${color.darkGray};
`

const DonorUsername = styled.span`
  display: block;
  margin-top: ${rem(dimension.dm)};
  font-size: ${rem(type.size.s1)};
  font-weight: ${type.weight.regular};
  text-align: left;
  color: ${color.gray};
`

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

class GeneratedDonorStatementForAdmin extends React.Component {
  constructor() {
    super()

    this.state = {
      donorUsername: '',
      donorName: '',
      donorDonations: []
    }
  }

  componentWillMount() {
    if (!!this.props.location.state) {
      const donorUsername = this.props.location.state.donorUsername || ''
      const donorName = this.props.location.state.donorName || ''
      const donorDonations = this.props.location.state.donorDonations || []

      this.setState({
        donorUsername: donorUsername,
        donorName: donorName,
        donorDonations: donorDonations
      })
    }
  }

  prepareDownloadCSV(event) {
    event.preventDefault()

    const {donorName, donorUsername} = this.state

    exportTableToCSV('table-for-export', `${donorName}-${donorUsername}-FinancialStatement.csv`)
  }

  startRefund(event, donation) {
    this.setState({
      refundDonation: donation,
      modalIsOpen: true
    })
  }

  refund(event, donation) {
    debugger;
    Parse.Cloud.run('refund', {
      donationId: donation.id
    }).then((result) => {
      donation.refunded = true;
      this.setState({
        donorDonations: this.state.donorDonations,
        modalIsOpen: false
      });
    }, (error) => {
      this.setState({
        donorDonations: this.state.donorDonations,
        modalIsOpen: true,
        refundError: error
      });
    });
  }

  afterOpenModal() {

  }

  closeModal() {
    this.setState({
      refundDonation: null,
      modalIsOpen: false,
      refundError: null
    })
  }

  render() {
    const {
      donorUsername,
      donorName,
      donorDonations,
      modalIsOpen,
      refundDonation,
      refundError
    } = this.state

    const afterOpenModal = this.afterOpenModal;
    const closeModal = this.closeModal;

    // Get total donation amount
    var totalDonations = 0;
    for (var i = 0; i < donorDonations.length; ++i) {
      totalDonations += donorDonations[i].amount;
    }

    var netDonations = 0;
    for (i = 0; i < donorDonations.length; ++i) {
      if (donorDonations[i].netAmount) {
        netDonations += donorDonations[i].netAmount;
      }
    }
    // Sort by date for details view
    donorDonations.sort((a, b) => (
      new Date(a.donationDate) - new Date(b.donationDate)
    ))

    // Group donations by Organization
    const groupDonationsByOrganization = donorDonations.reduce((obj, item) => {
      obj[item.organization] = obj[item.organization] || []
      obj[item.organization].push(item.amount)

      return obj
    }, {})

    const groupDonationsNetByOrganization = donorDonations.reduce((obj, item) => {
      obj[item.organization] = obj[item.organization] || []
      if (item.netAmount) {
        obj[item.organization].push(item.netAmount)
      }
      return obj
    }, {})

    return (
      <Box>
        <Box width={rem(200)}>
          <Button onClick={(e) => this.prepareDownloadCSV(e)}>Download CSV</Button>
        </Box>

        <Box mt={4}>
          <PageHeader>{donorName} Total Donations <DonorUsername>{donorUsername}</DonorUsername></PageHeader>

          <Box mt={1}>
            <LargeNumber textColor={color.pink}>
              <NumberFormat
                value={totalDonations}
                decimalScale={2}
                fixedDecimalScale={true}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
              />
            </LargeNumber>
            <RegularNumber>
              [<NumberFormat
              value={netDonations}
              decimalScale={2}
              fixedDecimalScale={true}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />]
            </RegularNumber>
          </Box>

          <Box mt={6}>
            <Hr/>

            <Box mt={5}>
              <Table>
                <TableCaption alt="true">Organization Summary</TableCaption>
                <thead>
                <tr>
                  <TableTh>Organization</TableTh>
                  <TableTh>Amount</TableTh>
                  <TableTh>Net</TableTh>
                </tr>
                </thead>
                <tbody>
                {Object.keys(groupDonationsByOrganization).map(organization => {
                  const donationsSum = groupDonationsByOrganization[organization].reduce((a, b) => a + b, 0)
                  const netSum = groupDonationsNetByOrganization[organization].reduce((a, b) => a + b, 0)
                  return (
                    <tr key={shortid.generate()}>
                      <TableTd strong>{organization}</TableTd>
                      <TableTd strong>
                        <NumberFormat
                          value={donationsSum}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'$'}
                        />
                      </TableTd>
                      <TableTd strong>
                        <NumberFormat
                          value={netSum}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'$'}
                        />
                      </TableTd>
                    </tr>
                  )
                })}
                </tbody>
              </Table>
            </Box>

            <Box mt={5}>
              <Table>
                <TableCaption alt="true">Donation Details</TableCaption>
                <thead>
                <tr>
                  <TableTh>Date</TableTh>
                  <TableTh>Organization</TableTh>
                  <TableTh>Amount</TableTh>
                  <TableTh>Net</TableTh>
                  <TableTh>Refund</TableTh>
                </tr>
                </thead>
                <tbody>
                {donorDonations.map(donation => (
                  <tr key={shortid.generate()}>
                    <TableTd>{donation.donationDate.toString('M/dd/yyyy')}</TableTd>
                    <TableTd>{donation.organization}</TableTd>
                    <TableTd>
                      <NumberFormat
                        value={donation.amount}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                      />
                    </TableTd>
                    <TableTd>
                      <NumberFormat
                        value={donation.netAmount}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                      />
                    </TableTd>
                    <TableTd>
                      {!!donation.refunded &&
                      <span>Refunded</span>
                      }
                      {!donation.refunded &&
                      <Button onClick={(e) => this.startRefund(e, donation)}>Refund</Button>
                      }
                    </TableTd>
                  </tr>
                ))}
                </tbody>
              </Table>
            </Box>
          </Box>
        </Box>


        {/* Hidden table for CSV Download */}
        <table id="table-for-export" style={{display: 'none'}}>
          <thead>
          <tr>
            <th>Donation Date</th>
            <th>Organization</th>
            <th>Amount</th>
            <th>Cover Fees</th>
            <th>Type</th>
            <th>Gross</th>
            <th>Stripe Fee</th>
            <th>GivApp Fee</th>
            <th>Net</th>
          </tr>
          </thead>

          <tbody>
          {donorDonations.map(donation => (
            <tr key={shortid.generate()}>
              <td>{donation.donationDate.toString('M/dd/yyyy')}</td>
              <td>{donation.organization}</td>
              <td><NumberFormat
                value={donation.amount}
                decimalScale={2}
                fixedDecimalScale={true}
                displayType={'text'}
              /></td>
              <td>{donation.coverTransactionFees === true ? '✓' : ''}</td>
              <td>{donation.type}</td>
              <td>
                <NumberFormat
                  value={donation.grossAmount}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  displayType={'text'}
                /></td>
              <td>
                <NumberFormat
                  value={donation.stripeFee}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  displayType={'text'}
                />
              </td>
              <td>
                <NumberFormat
                  value={donation.givappFee}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  displayType={'text'}
                />
              </td>
              <td>
                <NumberFormat
                  value={donation.netAmount}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  displayType={'text'}
                />
              </td>
            </tr>
          ))}
          </tbody>
        </table>
        {!!refundDonation &&
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={modalStyles}
          contentLabel="Are you sure you want to refund?"
        >
          <Flex mt={4} flexDirection={['column']}>
            <Box width={[1 / 1]}>
              <SubHeading>Process Refund</SubHeading>
              <Box mt={4}>
                <Text>Refund ${refundDonation.amount} (${refundDonation.grossAmount} total)
                  to {refundDonation.organization}?</Text>
              </Box>
            </Box>
            <Flex mt={4} flexDirection={['row']} justifyContent="space-between" alignItems={'center'}>
              <Box width={[1, .49, .49]}>
                <Button onClick={(e) => this.refund(e, refundDonation)}>Yes, process refund</Button>
              </Box>
              <Box width={[1, .49, .49]}>
                <Button onClick={(e) => this.closeModal(e)}>Cancel</Button>
              </Box>
            </Flex>
          </Flex>
        </Modal>

        }
        {!!refundError &&
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={modalStyles}
          contentLabel="Error processing refund"
        >
          <Flex mt={4} flexDirection={['column']}>
            <Box width={[1 / 1]}>
              <SubHeading>Process Refund Failed!</SubHeading>
              <Box mt={4}>
                <Text>Refund ${refundDonation.amount} (${refundDonation.grossAmount} total)
                  to {refundDonation.organization} failed.</Text>
              </Box>
            </Box>
            <Box mt={4}>
              <Text>
                {refundError.error}
              </Text>
            </Box>
            <Box mt={4}>
              <Button onClick={(e) => this.closeModal(e)}>Close</Button>
            </Box>
          </Flex>
        </Modal>
        }
      </Box>

    )
  }
}

export default GeneratedDonorStatementForAdmin
