import React from 'react'
import AuthenticatedRoute from "../../routes/AuthenticatedRoute";
import Dashboard from "../organization/Dashboard";
import Campaigns from "../organization/Campaigns";
import Donors from "../organization/Donors";
import Donor from "../organization/Donor";
import Donations from "../organization/Donations";
import Invoicing from "../organization/Invoicing";
import Messages from "../organization/Messages";
import Households from "../organization/Households";
import PointOfSale from "../PointOfSale";
import GeneratedMonthlySummaryStatement from "../GeneratedMonthlySummaryStatement";
import GeneratedIndividualDonorStatement from "../GeneratedIndividualDonorStatement";
import AdminRoute from "../../routes/AdminRoute";
import AdminActivity from "../admin/Activity";
import AdminNotifications from "../admin/Notifications";
import AdminStatements from "../admin/Statements";
import GeneratedMonthlyDonationsToAllOrganizations from "../admin/GeneratedMonthlyDonationsToAllOrganizations";
import GeneratedOrganizationFinancialStatement from "../admin/GeneratedOrganizationFinancialStatement";
import GeneratedDonorStatementForAdmin from "../admin/GeneratedDonorStatementForAdmin";
import Terminal from "../admin/Terminal";
import AccountRoutes from "../../routes/AccountRoutes";
import {Route, Switch} from 'react-router-dom'
import PaymentDetails2 from "../donate2/PaymentDetails2";
import MobilePaymentDetails from "../donate/MobilePaymentDetails";
import SelectOrganization2 from "../donate2/SelectOrganization2";

const DonationRoutes = ({match, ...props}) => {
  console.log('DonationRoutes', match, `${match.url}`, props);
  const {user, organization, subaccounts, selectedTheme} = props;
  return (
    /*Header*/

    <Switch>

      <Route
        exact
        path={`${match.url}/select-organization`}
        render={(matchProps) =>
        <SelectOrganization2
          {...matchProps}
          {...props}
          user={user}
        />

      }/>

      <Route
        exact
        path={`${match.url}/payment-details`}
        render={(matchProps) =>
          <PaymentDetails2
            {...matchProps}
            {...props}
            user={user}
          />

        }/>

      <Route
        exact
        path={`${match.url}/mobile-payment-details`}
        render={(matchProps) =>
          <MobilePaymentDetails
            {...matchProps}
            {...props}
            user={user}
          />

        }/>

    </Switch>


  )

}

export default DonationRoutes
