import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { rem } from '../../../lib/tools'

import logoImageUrl from '../../../images/logo-givapp.svg'

const StyledLogo = styled.img`
  display: block;
  width: ${rem(140)};
`

const Logo = () => (
  <Link itemProp="url" itemScope="itemscope" itemType="https://schema.org/Brand" to="/">
    <StyledLogo itemProp="logo" src={logoImageUrl} alt="Hamlet" />
  </Link>
)

export default Logo
