import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { useTheme } from 'emotion-theming'

import Box from '../Box'
import Flex from '../Flex'
import Text from '../Text'
import { IconNegative, IconPositive, IconWarning } from '../icons'

import { rem } from '../../lib/tools'
import * as border from '../../tokens/border'
import * as space from '../../tokens/space'

const StyledFlex = styled(Flex)`
  position: relative;
  border-radius: ${border.borderRadiusLg};
  background-color ${props =>
    props.bgColor ? props.bgColor :
    (props.type === 'negative') ? props.theme.mode === 'dark' ? props.theme.color.negative2 : props.theme.color.negative1 :
    (props.type === 'positive') ? props.theme.color.positive1 :
    (props.type === 'warning') ? props.theme.color.notification1 :
    props.theme.color.classicBlue1
  }
`

const PlaceIcon = styled.div`
  position: absolute;
  left: ${space.s2};
  top: calc(${space.s2} - ${rem(1)});
`

const renderIcon = (type, theme) => (
  (type === 'negative') ? <IconNegative large color={theme.mode === 'dark' ? theme.color.negative4 : theme.color.negative3} /> :
  (type === 'positive') ? <IconPositive large color={theme.color.positive4} /> :
  (type === 'warning') ? <IconWarning large color={theme.color.notification4} /> :
  null
)

const getNotificationTextColor = (type, theme) => (
  (type === 'negative') ? theme.mode === 'dark' ? theme.color.negative5 : theme.color.negative4 :
  (type === 'positive') ? theme.color.positive5 :
  (type === 'warning') ? theme.color.notification5 :
  theme.mode === 'dark' ? theme.color.classicBlue5 : theme.color.classicBlue4
)

const UIMessage = ({ children, bgColor, icon, title, titleColor, type }) => {
  const theme = useTheme()

  return (
    <StyledFlex
      {...{ bgColor, type }}
      py={3}
      pr={4}
      pl={(icon || type) ? `calc(${space.s7} + ${space.s1})` : 4}
      flexWrap={'wrap'}
    >
      {!!(icon || type) &&
        <PlaceIcon>{icon || renderIcon(type, theme)}</PlaceIcon>
      }
      {!!title &&
        <Box noShrink>
          <Text mr={2} bold textColor={titleColor || getNotificationTextColor(type, theme)}>{title}</Text>
        </Box>
      }
      <Text as="div" textColor={theme.color.black4}>
        {children}
      </Text>
    </StyledFlex>
  )
}

UIMessage.propTypes = {
  /** The main message. */
  children: PropTypes.node,
  /** Set a custom background color. */
  bgColor: PropTypes.string,
  /** Set a custom icon by passing any Icon component. */
  icon: PropTypes.node,
  /** Title text. If left off no title will render. */
  title: PropTypes.string,
  /** Set a custom title color. */
  titleColor: PropTypes.string,
  /** Pre-defined UI message types. Setting a type will
  automatically set the appropriate icon and title and
  background color. */
  type: PropTypes.oneOf(['negative', 'positive', 'warning'])
}

export default UIMessage
