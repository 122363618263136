import React from 'react'
import Parse from 'parse'
import Box from "../layout/Box";
import MainHeading from "../style/text/MainHeading";
import Hr from "../style/elements/Hr";
import Button from "../style/elements/Button";
import SubHeading from "../style/text/SubHeading";
import Flex from "../layout/Flex";
import Input from "../style/elements/Input";
import Text from "../style/text/Text";
import UIMessage from "../style/text/UIMessage";
import {rem} from "../../lib/tools";
import Label from "../style/elements/Label";
import TableTd from "../style/elements/TableTd";
import Table from "../style/elements/Table";
import {color} from "../../designSystem";
import qs from 'qs';


export class PointOfSale extends React.Component {
  constructor() {
    super()

    this.state = {
      organization: null,
      token: null,
      terminal: null,
      connectionStatus: 'disconnected',
      error: false,
      amount: 20.00,
      completedPayments: [],
      simulated: false,
      selectedReader: null,
      coverTransactionFees: false,

      intent: null,
      donation: null,
      firstName: "",
      lastName: "",
      email: ""
    }

  }

  handleChange = (key, e) => (
    e => this.setState({[key]: e.target.value})
  )

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  getValues = () => {
    // Get all Organizations for select dropdown

    if (this._isMounted === true) {


      let user = this.props.user;

      let User = Parse.Object.extend("User");

      let query = new Parse.Query(User);

      query.include('organization').get(user.id).then((theUser) => {
        let organization = theUser.get('organization');

        let terminal = window['StripeTerminal'].create({
          onFetchConnectionToken: this.handleFetchConnectionToken,
          onUnexpectedReaderDisconnect: this.handleUnexpectedReaderDisconnect,
          onConnectionStatusChange: this.handleConnectionStatusChange,
          onPaymentStatusChange: this.handlePaymentStatusChange,
        });
        this.setState({
          terminal: terminal,
          organization: organization
        });
      });
    }

  }

  handleFetchConnectionToken = async () => {
    return Parse.Cloud.run('fetchConnectionToken', {}).then((result) => result.token['secret']);
  }

  handleUnexpectedReaderDisconnect = async () => {

    console.log('handleUnexpectedReaderDisconnect', this.state.terminal);
    this.setState({
      connectionStatus: 'disconnected',
      error: {
        message: 'Reader Disconnected!'
      },
      selectedReader: null
    })
  }

  handleConnectionStatusChange = async () => {
    console.log('handleConnectionStatusChange', this.state.terminal);
    this.setState({
      connectionStatus: this.state.terminal.connectionStatus
    });
  }

  handlePaymentStatusChange = async () => {
    console.log('handlePaymentStatusChange', this.state.terminal);
    this.setState({
      connectionStatus: this.state.terminal.connectionStatus
    });
  }

  clearError = () => {
    this.setState({
      error: null
    });
  }

// Handler for a "Connect Reader" button

  discoverReadersHandler = (e) => {
    const config = {simulated: this.state.simulated};
    this.state.terminal.discoverReaders(config).then((discoverResult) => {
      if (discoverResult.error) {
        console.log('Failed to discover: ', discoverResult.error);
        this.setState({
          error: discoverResult.error
        })
      } else if (discoverResult.discoveredReaders.length === 0) {
        console.log('No available readers.');
        this.setState({
          error: 'No available readers.'
        })
      } else {

        this.setState({
          readers: discoverResult.discoveredReaders
        });
      }
    });
  }

  connectReaderHandler = (e, reader) => {

    this.state.terminal.connectReader(reader).then((connectResult) => {
      if (connectResult.error) {
        console.log('Failed to connect: ', connectResult.error);
        this.setState({error: connectResult.error});
      } else {
        console.log('Connected to reader: ', connectResult.reader);
        this.setState({
          selectedReader: reader
        });
      }
    });
  }

  createPaymentIntent = () => {
    let {
      amount,
      email,
      firstName,
      lastName
    } = this.state;

    console.log(Math.round(amount * 1000.0) / 10, Math.floor(amount * 100.0));
    console.log(Math.round(amount * 1000.0) / 10 - Math.floor(amount * 100.0));


    if (amount && amount < 0.72) {
      this.setState({
        error: {'message': "Amount must be greater than $0.72"}
      });
      return;
    } else if (Math.round(amount * 1000.0) / 10 - Math.floor(amount * 100.0) !== 0) {
      this.setState({
        error: {'message': "Must be a valid dollar amount."}
      });
      return;
    } else if (amount < 0) {
      this.setState({
        error: {'message': "Amount must be greater than 0"}
      });
      return;

    }

    if (!email || email === '') {
      this.setState({
        error: {message: "Email is required"}
      });
      return;
    }

    if (!firstName || firstName === '') {
      this.setState({
        error: {message: "First name is required"}
      });
      return;
    }

    if (!lastName || lastName === '') {
      this.setState({
        error: {message: "Last name is required"}
      });
      return;
    }


    Parse.Cloud.run('createPaymentIntentWithDonation', {
      amount: amount,
      organizationId: this.state.organization.id,
      email: this.state.email,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      coverTransactonFees: this.state.coverTransactonFees
    }).then((result) => {
      this.setState({
        intent: result.intent,
        donation: result.donation
      });
    }, (error) => {
      this.setState({
        error: error
      });
    });

  }

  clearPaymentIntent = () => {
    this.setState({
      intent: null
    });
  }

  checkout = (e) => {
    console.log('Checkout');
    // clientSecret is the client_secret from the PaymentIntent you created in Step 1.
    this.state.terminal.collectPaymentMethod(this.state.intent.client_secret).then((result) => {
      if (result.error) {
        // Placeholder for handling result.error
        this.setState({
          error: result.error
        })
      } else {
        // Placeholder for processing result.paymentIntent
        console.log("Payment Intent Result", result.paymentIntent);
        this.setState({
          intent: result.paymentIntent
        })
      }
    });
  }

  process = (e) => {
    this.state.terminal.processPayment(this.state.intent).then((result) => {
      if (result.error) {
        // Placeholder for handling result.error
        console.log("Error processing card.");
        this.setState({
          error: result.error
        });
        //couldn't process payment, start over?
        this.checkout(null);
      } else if (result.paymentIntent) {
        // Placeholder for notifying your backend to capture result.paymentIntent.id
        //TBD Call collect?
        this.setState({
          intent: result.paymentIntent
        })
      }
    }, (error) => {
      this.setState({
        error: error
      });
    });
  }

  capture = (e) => {
    Parse.Cloud.run('capturePaymentIntentWithDonation', {
      paymentIntentId: this.state.intent.id,
      donationId: this.state.donation.id
    }).then((result) => {
      //done?
      this.state.completedPayments.push(result);
      this.setState({
        intent: null,
        completedPayments: this.state.completedPayments
      });
    });
  }

  componentDidMount() {
    this._isMounted = true

    // Set active page state
    const {setPageId} = this.props
    setPageId('pos')

    let params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const {simulated} = params;
    if (simulated === 'true') {
      this.setState({
        simulated: true
      });
    }

    // Prepopluate select boxes with Organizations and months
    this.getValues()
  }

  componentWillUnmount() {
    this._isMounted = false

  }


  render() {

    const pageTitle = 'Terminals';
    const {
      organization,
      connectionStatus,
      error,
      readers,
      intent,
      completedPayments,
      selectedReader
    } = this.state;

    return (
      <Box>
        <MainHeading>{pageTitle}</MainHeading>

        <Box mt={3}>
          <Hr/>
        </Box>

        {organization &&
        <Flex flexDirection={['row']} justifyContent="space-around">

          <Box width={[0.49]} mt={rem(5)}>
            {error &&
            <Box onClick={this.clearError}>
              <UIMessage type={'error'}>
                {error.message}
              </UIMessage>
            </Box>
            }

            <SubHeading>
              {organization.get('name')}
            </SubHeading>

            <UIMessage type={'neutral'}>{connectionStatus}</UIMessage>

            {connectionStatus === "disconnected" &&
            <Box>
              <Button outline={true} onClick={this.discoverReadersHandler}>Discover</Button>
            </Box>
            }

            {readers && readers.length > 0 &&
            <Box>
              <Box>
                <SubHeading>Readers</SubHeading>
                {readers.map((reader, i) => (
                  <Flex key={reader.id} mb={rem(1)} padding={'2px'} flexDirection={['row']}>
                    <Box m={1} background={i % 2 === 0 ? color.lightestGray : 'white'} padding={'2px'} width={[0.75]}>
                      <Text>{reader.label}</Text>
                      <Text>{reader.ip_address}</Text>
                      <Text>
                        Device: {reader.device_type}{' '}({reader.status})
                      </Text>
                      <Text>Device ID: {reader.id}</Text>
                      <Text>Serial#: {reader.serial_number}</Text>
                    </Box>

                    <Box m={1} width={[0.25]}>
                      {connectionStatus !== 'connected' &&
                      <Button onClick={(e) => this.connectReaderHandler(e, reader)}>Connect</Button>
                      }
                      {reader === selectedReader &&
                      <Text>
                        <strong>Connected</strong>
                      </Text>
                      }
                    </Box>

                  </Flex>
                ))}
              </Box>

            </Box>
            }


            {completedPayments && completedPayments.length > 0 &&
            <Box>
              <SubHeading>
                Payment History
              </SubHeading>
              {completedPayments.map(payment => (
                <Box key={payment.donation.id}>
                  <Table>
                    <tbody>
                    <tr>
                      <TableTd>
                        <Label>Donation</Label>
                      </TableTd>
                      <TableTd>
                        <a href={'/receipt/' + payment.donation.id} target="_blank">Receipt</a>
                      </TableTd>
                    </tr>
                    </tbody>
                  </Table>
                </Box>
              ))}
            </Box>
            }
          </Box>
          {connectionStatus === "connected" &&
          <Box mt={rem(5)}>

            <Hr/>
            <Box>
              <SubHeading>Payment Information</SubHeading>
              <Box mb={rem(2)}>
                <Input
                  required
                  id="firstName"
                  name="firstName"
                  type="text"
                  placeholder="Jane"
                  value={this.state.firstName}
                  onChange={this.handleChange('firstName')}
                />
              </Box>
              <Box mb={rem(2)}>
                <Input
                  required
                  id="lastName"
                  name="lastName"
                  type="text"
                  placeholder="McGivApp"
                  value={this.state.lastName}
                  onChange={this.handleChange('lastName')}
                />
              </Box>
              <Box mb={rem(2)}>
                <Input
                  required
                  id="email"
                  name="email"
                  type="text"
                  placeholder="J.McGivApp@domain.ext"
                  value={this.state.email}
                  onChange={this.handleChange('email')}
                />
              </Box>
              <Box mb={rem(2)}>
                <Input
                  required
                  id="amount"
                  name="amount"
                  type="amount"
                  placeholder="Amount"
                  value={this.state.amount}
                  onChange={this.handleChange('amount')}
                />
              </Box>
            </Box>
            <Box mt={rem(5)}>
              <Button onClick={(e) => this.createPaymentIntent(e)} disabled={this.state.amount < 0.50}>
                Create Payment
              </Button>
            </Box>
            {intent &&
            <Flex flexDirection={['column']} mt={rem(5)}>
              <Button onClick={(e) => this.clearPaymentIntent(e)}>
                Cancel Payment
              </Button>
              <Box mt={rem(5)}>
                <Text>
                  <strong>Intent Status: ({intent.status})</strong>
                </Text>
              </Box>
              {intent.status === 'requires_source' &&
              <Box>
                <Text>Payment Intent Created, click "Checkout" and follow instructions on the Reader.</Text>
                <Button onClick={(e) => this.checkout(e)}>Checkout</Button>
              </Box>
              }
              {intent.status === 'requires_confirmation' &&
              <Box>
                <Text>Payment information received, click "Process" to continue.</Text>
                <Button onClick={(e) => this.process(e)}>Process</Button>
              </Box>
              }
              {intent.status === 'requires_capture' &&
              <Box>
                <Text>Payment information submitted, click "Capture" to complete the payment.</Text>
                <Button onClick={(e) => this.capture(e)}>Capture</Button>
              </Box>
              }
            </Flex>
            }
          </Box>

          }

        </Flex>
        }
      </Box>
    )
  }
}

/*
<Box mt={rem(2)}>
              <Checkbox
                id="simulated"
                name="simulated"
                type="checkbox"
                placeholder="Simulated"
                checked={simulated}
                onChange={this.handleInputChange}
              />
            </Box>

 */

export default PointOfSale
