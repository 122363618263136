import React from "react";
import {CardCvcElement, CardExpiryElement, CardNumberElement} from "@stripe/react-stripe-js";
import Box from "../../layout/Box";
import {rem} from "../../../lib/tools";
import Flex from "../../layout/Flex";
import Button from "../../style/elements/Button";
import {color, type} from "../../../designSystem";

class MobileCheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      error: null,
      submitted: false
    }

    this.submit = this.submit.bind(this);

  }

  handleChange = (key, e) => (
    e => {
      console.log(key, e.target.value, e.target.value === true);
      this.setState({[key]: e.target.value})
    }
  )

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }


  async submit(ev) {
    ev.preventDefault();
    console.log('CheckoutForm:submit');
    this.setState({submitted: true});

    const {elements} = this.props;
    const cardElement = elements.getElement(CardNumberElement);

    this.props.onSubmit(this.state).then(async (result) => {
      //the rsult should be the user...
      const stripeResult = await this.props.stripe.createPaymentMethod({
        type: 'card',
        card: cardElement
      });

      if (stripeResult.error) {
        this.setState({submitted: false});
        this.props.onError(stripeResult.error);
      } else {
        this.setState({submitted: true});
        let {paymentMethod} = stripeResult;
        this.props.onPaymentMethodCreation(paymentMethod, this.state.amount);
      }
    }, (error) => {
      console.log('CheckoutForm', error);
    });


  }

  isValid() {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const valid =
      this.state.amount && this.state.amount > 0 &&
      this.state.email && re.test(String(this.state.email).toLowerCase());

    console.log(this.state.email, re.test(String(this.state.email).toLowerCase()), valid);
    return !this.state.submitted && valid;
  }

  render() {
    return (

      <form onSubmit={this.submit} disabled={!this.isValid() ? 'disabled' : false} method="post">
        <Box mt={rem(2)}>
          <CardNumberElement
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
            onReady={handleReady}
            {...createOptions(this.props.fontSize)}
          />
        </Box>
        <Flex flexDirection={['column', 'row']} justifyContent="space-between" alignItems={'center'}>
          <Box mt={rem(2)} width={[1, .49, .49]}>
            <CardExpiryElement
              onBlur={handleBlur}
              onChange={handleChange}
              onFocus={handleFocus}
              onReady={handleReady}
              {...createOptions(this.props.fontSize)}
            />
          </Box>
          <Box mt={rem(2)} width={[1, .49, .49]}>
            <CardCvcElement
              onBlur={handleBlur}
              onChange={handleChange}
              onFocus={handleFocus}
              onReady={handleReady}
              {...createOptions(this.props.fontSize)}
            />
          </Box>
        </Flex>
        <Flex mt={2} justifyContent="space-between">
          <Button htmlButton type="submit">Submit</Button>
        </Flex>
      </form>
    );
  }
}


const handleBlur = () => {
  console.log('[blur]');
};
const handleChange = (change) => {
  console.log('[change]', change);
};
const handleFocus = () => {
  console.log('[focus]');
};
const handleReady = () => {
  console.log('[ready]');
};


const createOptions = (fontSize, padding) => {
  return {
    style: {
      base: {
        fontSize,
        color: color.black,
        letterSpacing: '0.025em',
        fontFamily: type.family.default,
        '::placeholder': {
          'font-weight': type.weight.regular,
          'color': color.lightGray
        },
        ...(padding ? {padding} : {}),
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };
};

export default MobileCheckoutForm;
