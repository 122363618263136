import React from 'react'
import { useTheme } from 'emotion-theming'

export const IconNegative = ({ color, large, size }) => {
  const theme = useTheme()
  const iconColor = color || theme.color.black3

  return large ?
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="9.66667" stroke={iconColor} strokeWidth="2"/>
      <path d="M20.2439 18.8404C20.5113 19.1078 20.5113 19.5088 20.2439 19.7761L19.7761 20.2439C19.5088 20.5113 19.1078 20.5113 18.8404 20.2439L15.9666 17.4369L13.1596 20.2439C12.8922 20.5113 12.4912 20.5113 12.2239 20.2439L11.7561 19.7761C11.4887 19.5088 11.4887 19.1078 11.7561 18.8404L14.5631 16.0334L11.7561 13.1596C11.4887 12.8922 11.4887 12.4912 11.7561 12.2239L12.2239 11.7561C12.4912 11.4887 12.8922 11.4887 13.1596 11.7561L15.9666 14.5631L18.7736 11.7561C19.0409 11.4887 19.4419 11.4887 19.7093 11.7561L20.2439 12.2239C20.5113 12.4912 20.5113 12.8922 20.2439 13.1596L17.4369 15.9666L20.2439 18.8404Z" fill={iconColor} />
    </svg> :
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="8.25" stroke={iconColor} strokeWidth="1.5"/>
      <path d="M15.5815 14.3963C15.8071 14.6218 15.8071 14.9602 15.5815 15.1857L15.1868 15.5805C14.9612 15.806 14.6229 15.806 14.3973 15.5805L11.9725 13.2121L9.60407 15.5805C9.37851 15.806 9.04016 15.806 8.8146 15.5805L8.41986 15.1857C8.1943 14.9602 8.1943 14.6218 8.41986 14.3963L10.7883 12.0278L8.41986 9.60303C8.1943 9.37747 8.1943 9.03912 8.41986 8.81356L8.8146 8.41882C9.04016 8.19326 9.37851 8.19326 9.60407 8.41882L11.9725 10.7872L14.3409 8.41882C14.5665 8.19326 14.9048 8.19326 15.1304 8.41882L15.5815 8.81356C15.8071 9.03912 15.8071 9.37747 15.5815 9.60303L13.2131 11.9715L15.5815 14.3963Z" fill={iconColor} />
    </svg>
}

IconNegative.defaultProps = {
  size: 24
}
