import React from 'react'
import Box from '../../layout/Box'

import MainHeading from '../../style/text/MainHeading'
import SubHeading from "../../style/text/SubHeading";
import {ManagerComponent} from "./ManagerComponent";


export class Households extends ManagerComponent {
  componentWillMount() {
    super.componentDidMount();

    const { setPageId } = this.props
    setPageId('households')

  }
  render() {
    const {organization, subaccounts} = this.state;

    return (
      <Box>
        <MainHeading>Households</MainHeading>
        <SubHeading>org:{organization.id}</SubHeading>
        {!!subaccounts &&
        <SubHeading>subs:{subaccounts.length}</SubHeading>
        }
      </Box>
    )
  }
}

export default Households
