import React from 'react'
import styled from 'styled-components'
import shortid from 'shortid'
import {sortByIndex} from '../../lib/tools'

const StyledUl = styled.ul`
  display: flex;
  flex-direction: column;
  margin:0px;
  padding:0px;
`

// Accepts array of items
const LinkList = (props, {
  displayList = sortByIndex(props.items)
}) => (
  <StyledUl>
    {displayList.map(item =>
      <props.itemComponent key={shortid.generate()} pageId={props.pageId} {...item}
                           subNav={!!props.subNav ? true : false}/>
    )}
  </StyledUl>
)

export default LinkList
