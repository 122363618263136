import React from 'react'
import styled from 'styled-components'

import spare_change from '../../../images/spare_change-100.png'
import recurring from '../../../images/recurring-100.png'
import one_time from '../../../images/one_time-100.png'

const DonationTypeImage = styled.img`
  height:30px;
`
const types = {
  'Spare Change': spare_change,
  'One Time': one_time,
  'Recurring': recurring
}

const DonationType = ({type}) => (
  <DonationTypeImage src={types[type]} title={type}/>
)
export default DonationType
