import styled from 'styled-components'

import { rem } from '../../../lib/tools'
import { border } from '../../../designSystem'

const Hr = styled.hr`
  display: block;
  width: 100%;
  height: ${rem(border.widthThick)};
  border: 0;
  background-color: ${border.color};
`

export default Hr
