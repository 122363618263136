import styled from 'styled-components'
import { rem } from '../../../lib/tools'
import { type, color } from '../../../designSystem'

const LargeNumber = styled.h3`
  display: block;
  font-size: ${rem(type.size.s4)};
  font-weight: ${type.weight.bold};
  color: ${props => !!props.textColor ? props.textColor : color.blue};
`

export default LargeNumber
