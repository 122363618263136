import React from 'react'
import PropTypes from 'prop-types'
import Text from '../Text'
import * as typography from '../../tokens/typography'

const Label = ({ children, small, textColor, ...rest }) => (
  <Text
    {...{ small, textColor }}
    {...rest}
    as="label"
    medium
    fontSize={props => props.small ?
      typography.fontSizeTextLabelSmall :
      typography.fontSizeTextLabel
    }
    lineHeight={props => props.small ?
      typography.lineHeightMultiplierTextLabelSmall :
      typography.lineHeightMultiplierTextLabel
    }
  >
    {children}
  </Text>
)

Label.propTypes = {
  /** Set small style. */
  small: PropTypes.bool,
  /** Set custom text color. */
  textColor: PropTypes.string
}

Label.defaultProps = {
  small: false
}

export default Label
