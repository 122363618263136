import React from 'react'
import Box from '../../layout/Box'

import MainHeading from '../../style/text/MainHeading'
import SubHeading from "../../style/text/SubHeading";
import {ManagerComponent} from "./ManagerComponent";
import * as _ from "lodash";

export class Invoicing extends ManagerComponent {
  componentWillMount() {
    super.componentDidMount();

    const {setPageId} = this.props
    setPageId('invoicing')

    window.setTimeout(() => {
      this.setState(_.extend(this.state, {loading: false}))
    });
  }

  render() {
    const {organization, subaccounts, loading} = this.state;
    console.log('loading', loading);
    return (
      <Box m={4}>
        {!!loading &&
        <Box>Loading...</Box>
        }
        {!!!loading &&
        <Box>
          <MainHeading>Invoicing</MainHeading>
          <SubHeading>org:{organization.id}</SubHeading>
          {!!subaccounts &&
          <SubHeading>subs:{subaccounts.length}</SubHeading>
          }
        </Box>
        }
      </Box>
    )
  }
}

export default Invoicing
