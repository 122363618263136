import React from 'react'
import styled from 'styled-components'
import Box from './Box'

import { flexDirection, alignItems, alignContent, justifyContent, flexWrap } from 'styled-system'

const FlexBox = styled(Box)`
  display: flex;
  ${flexDirection}
  ${alignItems}
  ${alignContent}
  ${justifyContent}
  ${flexWrap}
`

const Flex = (props) => (
  <FlexBox
    {...props}
  />
)

export default Flex
